import { ReactFCC } from '../../../utils/ReactFCC';
import {
  IQuestion,
  ModQuizGetAttemptDataData
} from '../../../store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_data';
import { useParseQuizHtml } from './utils/useParseQuizHtml';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { EQuestType, IQuizAnswer } from './types';
import { useMoodleAPI } from '../../../store/moodleAPI/useMoodleAPI';
import {
  ModQuizProcessAttemptData,
  ModQuizProcessAttemptQuery
} from '../../../store/moodleAPI/moodleTypes/_functions/mod_quiz_process_attempt';
import { MultiChoiceQuestion, RadioQuestion, SelectQuestion, ShortAnswerQuestion } from './QuestionTypes';
import { Error } from '@hse-design/react';

export interface QuizQuestionProps {
  question?: IQuestion;
  attemptData: ModQuizGetAttemptDataData | null;
  finishAttempt: boolean;
  processAttempt: boolean;
  setProcessAttempt: Dispatch<SetStateAction<boolean>>;
  onFinishQuiz: () => void;
  goNextPage: () => void;
}

export const QuizQuestion: ReactFCC<QuizQuestionProps> = (props) => {
  const { question, attemptData, finishAttempt, processAttempt, setProcessAttempt, onFinishQuiz, goNextPage } = props;

  const { content, title, sequenceCheck } = useParseQuizHtml(question?.html ?? '');

  const [answers, setAnswers] = useState<IQuizAnswer[]>([]);

  const {
    data: processAttemptData,
    isLoading: processAttemptIsLoading,
    error: processAttemptError
  } = useMoodleAPI<ModQuizProcessAttemptQuery, ModQuizProcessAttemptData>(
    !!attemptData?.attempt.id &&
      answers.length > 0 &&
      sequenceCheck &&
      processAttempt && {
        wsfunction: 'mod_quiz_process_attempt',
        attemptid: attemptData?.attempt.id,
        data: [
          ...answers.map(({ name, value }) => ({
            name,
            value
          })),
          sequenceCheck
        ],
        finishattempt: finishAttempt ? 1 : 0
      }
  );

  useEffect(() => {
    if (processAttemptData) {
      setTimeout(() => setProcessAttempt(false), 0);
      if (processAttemptData.state === 'finished') {
        onFinishQuiz();
      } else {
        goNextPage();
      }
    }
  }, [processAttemptData, onFinishQuiz, setProcessAttempt, goNextPage]);

  const QuestionComponent = useMemo(() => {
    switch (question?.type) {
      case EQuestType.MULTI_CHOICE_SET:
        return MultiChoiceQuestion;
      case EQuestType.DDW_TO_SHARD:
      case EQuestType.MATCH:
        return SelectQuestion;
      case EQuestType.SHORT_ANSWER:
        return ShortAnswerQuestion;
      case EQuestType.TRUE_FALSE:
      case EQuestType.MULTI_CHOICE:
        return RadioQuestion;
      default:
        return null;
    }
  }, [question]);

  const isLoading = processAttemptIsLoading || processAttempt;

  const error = processAttemptError;

  if (error || (!isLoading && !sequenceCheck)) {
    return <Error title={error ?? 'Неподдерживаемый тип вопроса'} />;
  }

  return (
    <>
      {question && !isLoading && QuestionComponent && (
        <QuestionComponent
          content={content}
          title={title}
          index={question.number}
          questionType={question.type}
          answers={answers}
          setAnswers={setAnswers}
        />
      )}
    </>
  );
};
