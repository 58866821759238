import React from 'react';
import { Helmet } from 'react-helmet-async';
import { EMDASH } from '@proscom/ui-utils';
import { AppRoutes } from './routes';

export const siteTitle = 'LMS – Управление стратегическими коммуникациями';

export default function App() {
  return (
    <>
      <Helmet defaultTitle={siteTitle} titleTemplate={`%s ${EMDASH} LMS`} />
      <AppRoutes />
    </>
  );
}
