export interface AccessIsEnabledProps {
  accessrules?: string[];
  preventaccessreasons?: string[];
}

const QUIZ_ENDING_STRING = 'Тестирование закончено';

export const useAccessIsEnabled = (props: AccessIsEnabledProps) => {
  const { accessrules, preventaccessreasons } = props;

  if (!accessrules || !preventaccessreasons) {
    return {
      quizCloseDateReason: '',
      accessIsEnabled: false
    };
  }

  let quizCloseDateReason = '';
  accessrules.forEach((rule) => {
    if (rule.includes(QUIZ_ENDING_STRING)) {
      quizCloseDateReason = rule;
    }
  });

  return {
    quizCloseDateReason,
    accessIsEnabled: preventaccessreasons.length === 0 && !quizCloseDateReason
  };
};
