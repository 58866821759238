import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Space } from '@hse-design/react';
import { useAuth } from 'store/auth';
import { LOCAL_STORAGE_KEY_NEXT } from 'store/localStorageKeys';
import { LoginDecor } from './LoginDecor/LoginDecor';
import { LoginGreeting } from './LoginGreeting/LoginGreeting';

import s from './LoginPage.module.scss';

export function LoginPage() {
  const { isLoggedIn } = useAuth();
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  useEffect(() => {
    // устанавливает целевую страницу для возвращения после успешной авторизации
    localStorage.setItem(LOCAL_STORAGE_KEY_NEXT, next || '/');
  }, [next]);

  // редирект авторизованного пользователя на целевую страницу
  if (isLoggedIn()) return <Navigate to={next || '/'} replace />;

  return (
    <>
      <Helmet title={'Авторизация'} />
      <div className={s.LoginPage__container}>
        <LoginDecor isTop />
        <Space size={Space.Size.large} vertical />
        <LoginGreeting />
        <Space size={Space.Size.large} vertical />
        <LoginDecor />
      </div>
    </>
  );
}
