import clsx from 'clsx';
import s from './Tabs.module.scss';

export interface TabsProps {
  items: {
    label: string;
    value: string;
  }[];
  onChange: (value: string) => void;
  value: string;
}

export const Tabs = ({ items, onChange, value }: TabsProps) => {
  return (
    <div className={s.Tabs}>
      {items.map(({ label, value: v }) => (
        <button key={v} onClick={() => onChange(v)} className={clsx(s.Tab, v === value && s.Tab_active)}>
          {label}
        </button>
      ))}
    </div>
  );
};
