import {
  CoreEnrolGetEnrolledUsersQuery,
  CoreEnrolGetEnrolledUsersData
} from 'store/moodleAPI/moodleTypes/_functions/core_enrol_get_enrolled_users';
import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';

export const useCurators = (courseId: string | undefined) => {
  const enrolledUsers = useMoodleAPI<CoreEnrolGetEnrolledUsersQuery, CoreEnrolGetEnrolledUsersData>(
    courseId !== undefined
      ? {
          wsfunction: 'core_enrol_get_enrolled_users',
          courseid: +courseId,
          options: [
            {
              name: 'userfields',
              value: 'fullname,roles,email'
            }
          ]
        }
      : null
  );
  const curators = enrolledUsers.data?.filter((data) =>
    data.roles?.map((role) => role.shortname)?.includes('editingassistant')
  );

  return curators;
};
