import React from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import { Error } from '@hse-design/react';
import s from './QuizQuestionLayout.module.scss';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';

export interface IQuizQuestionLayoutProps {
  error?: boolean;
  title?: Element | null;
  index?: number;
}

export const QuizQuestionLayout: ReactFCC<IQuizQuestionLayoutProps> = (props) => {
  const { error, title, index, children } = props;

  return (
    <>
      {!error ? (
        <>
          {typeof index === 'number' && <p className={s.QuizQuestionLayout__heading}>Вопрос {index}</p>}

          {title && !Array.isArray(title) && (
            <MoodleContent className={s.QuizQuestionLayout__title}>{title.innerHTML}</MoodleContent>
          )}

          <div className={s.QuizQuestionLayout__answers}>{children}</div>
        </>
      ) : (
        <Error title="Не удалось загрузить информацию" />
      )}
    </>
  );
};
