import { BreadcrumbEntityType, BreadcrumbType, getBreadcrumbItem } from 'components/Breadcrumbs';
import { getDisciplinePageBreadcrumbs } from '../discipline';

export function getLessonPageBreadcrumbs({
  course,
  lesson
}: {
  course: BreadcrumbEntityType;
  lesson: BreadcrumbEntityType;
}): BreadcrumbType[] {
  return [
    ...getDisciplinePageBreadcrumbs({ course }),
    getBreadcrumbItem({
      entity: lesson,
      path: '/lesson',
      overflowLength: 50
    })
  ];
}
