import { BreadcrumbEntityType, BreadcrumbType, getBreadcrumbItem } from 'components/Breadcrumbs';
import { getLessonPageBreadcrumbs } from '../lesson';

export function getQuizPageBreadcrumbs({
  course,
  lesson,
  quiz
}: {
  course: BreadcrumbEntityType;
  lesson: BreadcrumbEntityType;
  quiz: BreadcrumbEntityType;
}): BreadcrumbType[] {
  return [
    ...getLessonPageBreadcrumbs({ course, lesson }),
    getBreadcrumbItem({
      entity: quiz,
      path: '/quiz',
      overflowLength: 50
    })
  ];
}
