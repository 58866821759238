import { useMemo } from 'react';
import { flatten } from 'lodash-es';
import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import {
  CoreCalendarGetCalendarMonthlyViewData,
  CoreCalendarGetCalendarMonthlyViewQuery,
  CoreCalendarGetCalendarMonthlyViewQueryArgs
} from 'store/moodleAPI/moodleTypes/_functions/core_calendar_get_calendar_monthly_view';
import { CalendarEventType } from 'components/calendar/calendar.types';

function createEventDate(year: number, month: number, day: number, startTimestamp: number = 0): Date {
  const startDate = new Date(startTimestamp);
  return new Date(year, month, day, startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
}

export const useMonthCalendarData = ({ year, month, ...vars }: CoreCalendarGetCalendarMonthlyViewQueryArgs) => {
  const { data, isLoading, error } = useMoodleAPI<
    CoreCalendarGetCalendarMonthlyViewQuery,
    CoreCalendarGetCalendarMonthlyViewData
  >({
    wsfunction: 'core_calendar_get_calendar_monthly_view',
    year,
    month: month + 1,
    ...vars
  });

  const events: CalendarEventType[] = useMemo(() => {
    if (!data) return [];
    return flatten(
      data.weeks.map((w) =>
        flatten(
          w.days
            .filter((d) => d.hasevents)
            .map((d) => {
              const day = d.mday;
              return d.events
                .filter((event) => !!event.visible)
                .map((event) => {
                  const date_start = createEventDate(year, month, day, event.timestart);
                  const date_end = !event.timeduration
                    ? undefined
                    : createEventDate(year, month, day, event.timestart + event.timeduration);
                  return {
                    id: event.id,
                    type: event.normalisedeventtypetext,
                    name: event.name,
                    description: event.description,
                    date_start,
                    date_end,
                    link: event.url
                  };
                });
            })
        )
      )
    );
  }, [data, month, year]);

  return {
    events,
    isLoading,
    error
  };
};
