import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import {
  CoreCourseGetCoursesByFieldData,
  CoreCourseGetCoursesByFieldQuery
} from 'store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';

const DESCRIPTION_SHORT_NAME = 'fullinfo';

export const useCourseData = (courseId?: string) => {
  const { data, isLoading, error } = useMoodleAPI<CoreCourseGetCoursesByFieldQuery, CoreCourseGetCoursesByFieldData>(
    !!courseId && {
      wsfunction: 'core_course_get_courses_by_field',
      field: 'id',
      value: courseId
    }
  );

  const course = data?.courses?.[0];
  let description = '';

  if (course) {
    course.customfields?.forEach(({ shortname, value }) => {
      if (shortname === DESCRIPTION_SHORT_NAME && value) {
        description += value;
      }
    });
  }

  return {
    course,
    description,
    isLoading,
    error
  };
};
