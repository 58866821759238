import React from 'react';
import { CalendarEventType } from '../calendar.types';
import { formatDate } from '../../../utils/date';
import { Anchor, EAnchorSize } from '../../Anchor/Anchor';
import { LinkVariant } from '@hse-design/react';
import { Badge } from '../../Badge/Badge';
import s from './DayCalendar.module.scss';

export interface DayCalendarEventProps extends CalendarEventType {}

export const DayCalendarEvent = ({ date_start, name, description, type, link }: DayCalendarEventProps) => {
  return (
    <div className={s.DayCalendarEvent}>
      <div className={s.DayCalendarEvent__name}>{name}</div>
      <div className={s.DayCalendarEvent__type}>
        <Badge>{type}</Badge>
      </div>
      {description && <div className={s.DayCalendarEvent__description}>{description}</div>}
      <div className={s.DayCalendarEvent__date}>{formatDate(date_start, 'EEEE, dd MMMM yyyy, HH:mm')}</div>
      {link && (
        <div className={s.DayCalendarEvent__actions}>
          <Anchor
            href={link}
            target={'_blank'}
            rel={'noopener noreferrer'}
            size={EAnchorSize.SMALL}
            variant={LinkVariant.secondary}>
            Перейти к событию
          </Anchor>
        </div>
      )}
    </div>
  );
};
