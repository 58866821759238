import { useMemo } from 'react';

export const useParseQuizHtml = (html: string) => {
  return useMemo(() => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(html, 'text/html');

    const content = htmlDocument.querySelector('.content');

    const { title } = getTitle(content);

    const sequenceCheck = getSequenceCheck(content);

    return {
      content,
      title,
      sequenceCheck
    };
  }, [html]);
};

function getTitle(content: Element | null) {
  const $title = content?.querySelector('.qtext');
  const $titleP = content?.querySelectorAll('.qtext p');

  Array.from($titleP ?? []).forEach(($item) => {
    if (!$item.childNodes[0].textContent) {
      return;
    }

    if ($item.querySelector('.drop')) {
      ($item as HTMLElement).style.display = 'none';
    }
  });

  if ($title) {
    let title_html = $title.innerHTML;
    title_html = title_html.replace(/font-size: 0.9375rem;/g, 'font-size: inherit;');
    $title.innerHTML = title_html;
  }

  return {
    title: $title
  };
}

function getSequenceCheck(content: Element | null) {
  const $sequenceCheck = content?.querySelector('.formulation > [type="hidden"]');

  return $sequenceCheck
    ? {
        name: $sequenceCheck.getAttribute('name') ?? '',
        value: ($sequenceCheck as HTMLInputElement).value
      }
    : null;
}
