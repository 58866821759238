import React from 'react';
import clsx from 'clsx';
import { Paragraph, Heading, HeadingSize, Space } from '@hse-design/react';
import { VectorIconMulticolor } from 'components/IconsFactory/IconsFactory';
import { EIconsMulticolor } from 'components/IconsFactory/Icons';
import { Anchor } from 'components/Anchor/Anchor';
import { ReactFCC } from 'utils/ReactFCC';
import { isKeyDownEnter } from 'utils/isKeyDownEnter';
import { externalAuthURL, useAuth } from 'store/auth';

import s from './LoginGreeting.module.scss';

type TLoginGreeting = {
  className?: string;
};

export const LoginGreeting: ReactFCC<TLoginGreeting> = (props) => {
  const { error } = useAuth();
  const { className } = props;

  const onLoginClick = () => {
    window.location.replace(externalAuthURL);
  };

  return (
    <div className={clsx(s.LoginGreeting__container, className)}>
      <div className={s.LoginGreeting__content_container}>
        <div className={s.LoginGreeting__logo_container}>
          <VectorIconMulticolor
            className={s.LoginGreeting__logo}
            height={136}
            icon={EIconsMulticolor.LOGO_HSE_FULL_ROUND_FILLED}
            width={136}
          />
        </div>

        <div className={s.LoginGreeting__greeting_container}>
          <Paragraph className={s.LoginGreeting__greeting_p}>
            Школа коммуникаций <br />
            Магистерская онлайн программа
          </Paragraph>

          <Space size={Space.Size.small_3x} vertical />

          <Heading className={s.LoginGreeting__greeting_h3} size={HeadingSize.H3}>
            Управление стратегическими коммуникациями
          </Heading>

          <Space size={Space.Size.small} vertical />

          {error && (
            <>
              <Paragraph className={s.LoginGreeting__error} small>
                {error}
              </Paragraph>
              <Space size={Space.Size.small_3x} vertical />
            </>
          )}

          <Anchor
            className={s.LoginGreeting__link}
            onClick={onLoginClick}
            onKeyDownCapture={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
              if (isKeyDownEnter(e)) {
                onLoginClick();
              }
            }}>
            Войти через ЕЛК
          </Anchor>
        </div>
      </div>
    </div>
  );
};
