import clsx from 'clsx';
import s from './QuizAttempts.module.scss';
import { ReactFCC } from '../../../utils/ReactFCC';
import { IAttempt } from '../../../store/moodleAPI/moodleTypes/Attempt';
import { QuizAttemptHeadRow, QuizAttemptRow } from './QuizAttemptRow';
import { QuizAttemptCard } from './QuizAttemptCard';
import { Button, ButtonSize, ButtonVariant, IconActionArrowKeyboardRight } from '@hse-design/react';
import React, { useState } from 'react';
import { mediaQuery, useMediaQueryResult } from '../../../hooks/useMediaQueryResult';

export interface QuizAttemptsProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Массов оценок за тестирование из API
   */
  attempts: IAttempt[];
}

export const QuizAttempts: ReactFCC<QuizAttemptsProps> = (props) => {
  const { className, attempts } = props;

  const [attemptsExpanded, setAttemptsExpanded] = useState(false);

  const isMobile = useMediaQueryResult(mediaQuery.tabletLargeDown);

  return (
    <div className={clsx(s.QuizAttempts, className)}>
      {!isMobile && <QuizAttemptHeadRow />}

      {attempts
        .slice(0, attemptsExpanded ? undefined : 5)
        .map((attempt, index) =>
          isMobile ? (
            <QuizAttemptCard attempt={attempt} key={index} />
          ) : (
            <QuizAttemptRow attempt={attempt} key={index} />
          )
        )}

      {attempts.length > 5 && (
        <Button
          className={s.QuizAttempts__button}
          classes={{
            icon: clsx(s.QuizAttempts__buttonIcon, attemptsExpanded && s.QuizAttempts__buttonIcon_expanded)
          }}
          variant={ButtonVariant.tertiary}
          size={ButtonSize.small}
          rightIcon={IconActionArrowKeyboardRight}
          onClick={() => setAttemptsExpanded((value) => !value)}>
          {attemptsExpanded ? 'Свернуть' : 'Показать все'}
        </Button>
      )}
    </div>
  );
};
