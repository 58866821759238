import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { InputSize, Space } from '@hse-design/react';
import { ReactFCC } from 'utils/ReactFCC';

import s from './HomePage.module.scss';
import { mediaQuery, useMediaQueryResult } from 'hooks/useMediaQueryResult';
import { TTabItem } from './DisciplineTabs/tabTypes';
import { DisciplineTabs } from './DisciplineTabs/DisciplineTabs';
import { CourseCompletionVariant, useEnrolUserCoursesData } from 'store/moodleAPI/useEnrolUserCoursesData';
import { DisciplineCards } from './DisciplineCards/DisciplineCards';
import { createCoursesList } from './createCoursesList';

const tabs: TTabItem[] = [
  {
    label: 'Все',
    name: '1',
    tab: CourseCompletionVariant.all
  },
  {
    label: 'Незавершенные',
    name: '2',
    tab: CourseCompletionVariant.incomplete
  }
];

export const HomePage: ReactFCC = () => {
  const [activeTab, setActiveTab] = useState<TTabItem>(tabs[1]);
  const [searchQuery, setSearchQuery] = useState('');

  const isMobile = useMediaQueryResult(mediaQuery.tabletLargeDown);

  const { coursesData, isLoading } = useEnrolUserCoursesData(activeTab.tab);

  const courses = useMemo(() => {
    let courses = createCoursesList(coursesData);

    if (searchQuery) {
      courses = courses.filter((course) => course.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    return courses;
  }, [coursesData, searchQuery]);

  const placeholderText = useMemo(() => {
    if (!isLoading && !courses.length) {
      switch (activeTab.tab) {
        case CourseCompletionVariant.all:
          return 'У вас нет дисциплин';

        case CourseCompletionVariant.incomplete:
          return 'У вас нет незавершенных дисциплин';

        default:
          return 'У вас нет незавершенных дисциплин';
      }
    }

    return '';
  }, [activeTab.tab, courses.length, isLoading]);

  return (
    <>
      <Helmet title={'Активные дисциплины'} />

      <div className={s.HomePage__container}>
        <div className={s.HomePage__contentContainer}>
          <PageHeader
            isMobile={isMobile}
            searchParams={{
              placeholder: 'Поиск дисциплин',
              size: InputSize.small,
              value: searchQuery,
              onChange: (e) => setSearchQuery(e.target.value)
            }}
            title={'Активные дисциплины'}
          />

          <Space size={isMobile ? Space.Size.medium : Space.Size.large} vertical />

          <DisciplineTabs activeTab={activeTab} isMobile={isMobile} setActiveTab={setActiveTab} tabs={tabs} />

          <Space size={isMobile ? Space.Size.small_x : Space.Size.medium_x} vertical />

          <DisciplineCards courses={courses} placeholderText={placeholderText} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};
