import { useEffect, useState } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import {
  CoreCourseGetCourseModuleData,
  CoreCourseGetCourseModuleQuery
} from 'store/moodleAPI/moodleTypes/_functions/core_course_get_course_module';
import {
  ModLessonGetPagesData,
  ModLessonGetPagesQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_lesson_get_pages';
import {
  ModLessonGetPageDataData,
  ModLessonGetPageDataQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_lesson_get_page_data';
import {
  ModLessonLaunchAttemptData,
  ModLessonLaunchAttemptQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_lesson_launch_attempt';
import {
  ModLessonGetUserTimersData,
  ModLessonGetUserTimersQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_lesson_get_user_timers';
import {
  CoreCourseGetCoursesByFieldData,
  CoreCourseGetCoursesByFieldQuery
} from '../../../store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';

export const useLessonData = (lessonId?: string) => {
  const [launchAttempt, setLaunchAttempt] = useState(false);

  const {
    data: moduleData,
    isLoading: moduleLoading,
    error: moduleError
  } = useMoodleAPI<CoreCourseGetCourseModuleQuery, CoreCourseGetCourseModuleData>(
    !!lessonId && {
      wsfunction: 'core_course_get_course_module',
      cmid: lessonId
    }
  );

  const { instance: lessonInstanceId, course: courseId, name: title } = moduleData?.cm ?? {};

  const {
    data: userTimersData,
    isLoading: userTimersIsLoading,
    error: userTimersError,
    clearResult
  } = useMoodleAPI<ModLessonGetUserTimersQuery, ModLessonGetUserTimersData>(
    !!lessonInstanceId && {
      wsfunction: 'mod_lesson_get_user_timers',
      lessonid: lessonInstanceId
    }
  );

  const {
    data: launchAttemptData,
    isLoading: launchAttemptIsLoading,
    error: launchAttemptError
  } = useMoodleAPI<ModLessonLaunchAttemptQuery, ModLessonLaunchAttemptData>(
    !!lessonInstanceId &&
      launchAttempt && {
        wsfunction: 'mod_lesson_launch_attempt',
        lessonid: lessonInstanceId
      }
  );

  useEffect(() => {
    if (userTimersData?.timers.length === 0) {
      setLaunchAttempt(true);
    }
  }, [userTimersData?.timers.length]);

  useEffect(() => {
    if (launchAttemptData) {
      clearResult();
    }
  }, [launchAttemptData, clearResult]);

  const prevCourseId = usePrevious(courseId, false);

  const { data: lessonPagesData, error: lessonPagesError } = useMoodleAPI<
    ModLessonGetPagesQuery,
    ModLessonGetPagesData
  >(
    !!lessonInstanceId && {
      wsfunction: 'mod_lesson_get_pages',
      lessonid: lessonInstanceId
    }
  );

  const pageId = lessonPagesData?.pages?.[0].page.id;

  const { data: lessonPageData, error: lessonPageError } = useMoodleAPI<
    ModLessonGetPageDataQuery,
    ModLessonGetPageDataData
  >(
    // Иногда у лекции нету "таймеров" и с такой проверкой она просто не загружается
    // userTimersData?.timers &&
    // userTimersData.timers.length > 0 &&
    !!lessonInstanceId &&
      !!pageId && {
        wsfunction: 'mod_lesson_get_page_data',
        lessonid: lessonInstanceId,
        pageid: pageId
      }
  );

  let { contents } = lessonPageData?.page ?? {};
  if (contents) {
    contents = contents.replace('iframe width="560"', 'iframe width="100%"');
  }

  // COURSE

  const {
    data,
    isLoading: courseLoading,
    error: courseError
  } = useMoodleAPI<CoreCourseGetCoursesByFieldQuery, CoreCourseGetCoursesByFieldData>(
    (!!courseId || !!prevCourseId) && {
      wsfunction: 'core_course_get_courses_by_field',
      field: 'id',
      value: courseId ?? prevCourseId
    }
  );

  const course = data?.courses?.[0];

  const error =
    moduleError || lessonPagesError || lessonPageError || userTimersError || launchAttemptError || courseError;

  const isLoading = moduleLoading || !lessonPageData || userTimersIsLoading || launchAttemptIsLoading || courseLoading;

  return {
    courseId: courseId ?? prevCourseId,
    courseName: course?.displayname,
    error,
    title,
    isLoading,
    lessonInstanceId,
    content: contents,
    setLaunchAttempt
  };
};
