import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Icon,
  IconActionArrowKeyboardRight,
  IconButton,
  IconButtonSize,
  IconLmsTask,
  IconSize
} from '@hse-design/react';
import { ReactFCC } from 'utils/ReactFCC';
import { EActivityState, IActivityItem } from 'store/moodleAPI/courseActivities/activityTypes';
import { ActivitiesVariant } from 'components/Activities/types';
import s from './ActivityCard.module.scss';

export interface IActivityCardProps extends Pick<IActivityItem, 'title' | 'state' | 'href'> {
  variant?: ActivitiesVariant;
}

export const ActivityCard: ReactFCC<IActivityCardProps> = (props) => {
  const { state, title, href, variant } = props;

  return (
    <Link
      to={href}
      className={clsx(
        s.ActivityCard,
        state === EActivityState.CLOSE && s.ActivityCard_inactive,
        s[`ActivityCard_variant_${variant}`]
      )}>
      <div className={clsx(s.ActivityCard__state, s[`ActivityCard__state_${state}`])}>
        {state === EActivityState.QUIZ && (
          <Icon className={s.ActivityCard__stateIcon} icon={IconLmsTask} size={IconSize.large} />
        )}
      </div>

      <div className={s.ActivityCard__content}>
        <p className={s.ActivityCard__title}>{title}</p>
      </div>

      {variant === ActivitiesVariant.DEFAULT && (
        <IconButton className={s.ActivityCard__iconButton} size={IconButtonSize.small}>
          <Icon className={s.ActivityCard__iconButtonIcon} icon={IconActionArrowKeyboardRight} size={IconSize.large} />
        </IconButton>
      )}
    </Link>
  );
};
