import React from 'react';

import clsx from 'clsx';

import { Spinner as SpinnerHse, SpinnerProps } from '@hse-design/react';
import { ReactFCC } from 'utils/ReactFCC';

import s from './Spinner.module.scss';

export type TSpinner = SpinnerProps;

export const Spinner: ReactFCC<TSpinner> = (props) => {
  const { className, ...rest } = props;

  return <SpinnerHse {...rest} className={clsx(s.Spinner, className)} />;
};
