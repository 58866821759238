import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { PageHeader } from 'components/PageHeader/PageHeader';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Error,
  FileButtonIcon,
  IconActionArrowKeyboardLeft,
  IconActionArrowKeyboardRight,
  IconActionCheck,
  IconButton,
  IconButtonSize,
  IconButtonVariant,
  SpinnerSize
} from '@hse-design/react';
import { formatFileSize } from '@proscom/ui-utils';
import { useCourseActivities } from 'store/moodleAPI/courseActivities/useCourseActivities';
import { CompletionState, IModule } from 'store/moodleAPI/moodleTypes/Module';
import { Activities } from 'components/Activities/Activities';
import { ActivitiesVariant } from 'components/Activities/types';
import { AsideLayout, IAsideLayoutProps } from 'routes/_layouts/AsideLayout/AsideLayout';
import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import { mediaQuery, useMediaQueryResult } from 'hooks/useMediaQueryResult';
import { Spinner } from 'components/Spinner/Spinner';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';
import { EActivityState, EFilterActivities, IActivityItem } from 'store/moodleAPI/courseActivities/activityTypes';
import { useLessonData } from './api/useLessonData';
import {
  ModLessonViewLessonData,
  ModLessonViewLessonQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_lesson_view_lesson';
import { useMoodleAPILazyQuery } from 'store/moodleAPI/useMoodleAPILazyQuery';
import { omit } from 'lodash-es';
import {
  ModLessonFinishAttemptData,
  ModLessonFinishAttemptQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_lesson_finish_attempt';
import { getLessonPageBreadcrumbs } from './lessonPage-breadcrumbs';
import { Breadcrumbs } from 'components/Breadcrumbs';
import s from './LessonPage.module.scss';

const noIsolatedTestsFilter = [EFilterActivities.ISOLATED_TEXT];

export function LessonPage() {
  const { lessonId } = useParams<{
    lessonId?: string;
  }>();

  const navigate = useNavigate();

  const requestMoodleAPI = useMoodleAPILazyQuery();

  const {
    courseId,
    courseName,
    error: lessonError,
    title,
    isLoading,
    content,
    lessonInstanceId
  } = useLessonData(lessonId);

  const { error: lessonViewError } = useMoodleAPI<ModLessonViewLessonQuery, ModLessonViewLessonData>(
    !!lessonInstanceId && {
      wsfunction: 'mod_lesson_view_lesson',
      lessonid: lessonInstanceId
    }
  );

  const {
    activities,
    isLoading: activitiesIsLoading,
    error: activitiesError,
    fetchActivities
  } = useCourseActivities(String(courseId ?? ''), noIsolatedTestsFilter);

  const isDesktop = useMediaQueryResult(mediaQuery.desktopSmallUp);
  const isTabletLargeDown = useMediaQueryResult(mediaQuery.tabletLargeDown);

  const [completionError, setCompletionError] = useState<string | null>(null);
  const [completionLoading, setCompletionLoading] = useState<boolean>(false);

  const error = lessonError || activitiesError || lessonViewError || completionError;

  const activitiesProps = {
    isLoading: activitiesIsLoading,
    activities,
    variant: ActivitiesVariant.ASIDE
  };

  const asideLayoutProps: IAsideLayoutProps = {
    asideChildren: <Activities {...activitiesProps} />,
    afterContainerChildren: !isDesktop && <Activities {...activitiesProps} />
  };

  const { nextActivity, prevActivity, quiz, resource, labels, needCompletion } = useMemo(() => {
    let nextActivity: IActivityItem | undefined;
    let prevActivity: IActivityItem | undefined;
    let quiz: IModule | undefined;
    let resource: IActivityItem['resource'] | undefined;
    let labels: IActivityItem['labels'] = [];
    let needCompletion = false;

    activities?.forEach(({ items }) => {
      if (prevActivity || nextActivity) {
        return;
      }

      items.forEach((item, idx) => {
        if (String(item.id) === lessonId) {
          if (item.quiz) {
            quiz = item.quiz;
          }

          if (item.resource) {
            resource = item.resource;
          }

          if (item.labels) {
            labels = item.labels;
          }

          if (items[idx - 1]) {
            prevActivity = items[idx - 1];
          }

          const nextItem = items[idx + 1];
          if (nextItem) {
            nextActivity = nextItem;
          }

          if (item.completiondata?.state === CompletionState.Incomplete) {
            needCompletion = true;
          }

          return;
        }
      });
    });

    return {
      nextActivity,
      prevActivity,
      quiz,
      resource,
      labels,
      needCompletion
    };
  }, [activities, lessonId]);

  const [nextActivityId, setNextActivityId] = useState<number | null>(null);

  const processNextActivity = async () => {
    if (!lessonInstanceId) {
      return;
    }

    if (nextActivity) {
      if (nextActivity.state !== EActivityState.CLOSE) {
        navigate(nextActivity.href);
        return;
      }

      setNextActivityId(nextActivity.id);
    }

    setCompletionLoading(true);

    const { error } = await requestMoodleAPI<ModLessonFinishAttemptQuery, ModLessonFinishAttemptData>({
      wsfunction: 'mod_lesson_finish_attempt',
      lessonid: lessonInstanceId
    });

    setCompletionLoading(false);

    if (error) {
      setCompletionError(error);
      return;
    }

    await fetchActivities();
  };

  useEffect(() => {
    if (nextActivityId && nextActivity && nextActivity?.state !== EActivityState.CLOSE) {
      setNextActivityId(null);
      navigate(nextActivity.href);
    }
  }, [navigate, nextActivity, nextActivityId, setNextActivityId]);

  const { nextButtonText, nextButtonIcon } = useMemo(() => {
    let nextButtonText: string | undefined;
    let nextButtonIcon: React.ElementType | undefined;

    if (nextActivity && nextActivity.state !== EActivityState.CLOSE) {
      nextButtonText = 'Следующая часть';
      nextButtonIcon = IconActionArrowKeyboardRight;
    }

    if (needCompletion) {
      nextButtonText = 'Завершить просмотр';
      nextButtonIcon = IconActionCheck;
    }

    return {
      nextButtonText,
      nextButtonIcon
    };
  }, [nextActivity, needCompletion]);

  const breadcrumbs = useMemo(() => {
    if (!courseId || !lessonId) return [];
    return getLessonPageBreadcrumbs({
      course: {
        id: courseId,
        name: `Дисциплина ${courseName}`
      },
      lesson: {
        id: lessonId,
        name: title
      }
    });
  }, [courseId, lessonId, title, courseName]);

  if (error) {
    return (
      <AsideLayout {...asideLayoutProps}>
        <Error title="Не удалось загрузить информацию" description={error} />
      </AsideLayout>
    );
  }

  const buttonsClassName = clsx(
    !prevActivity && (nextActivity || needCompletion) && s.LessonPage__controlsButton_right
  );

  const buttonProps = {
    component: Link,
    size: ButtonSize.small,
    variant: ButtonVariant.tertiary,
    className: buttonsClassName
  };

  const iconButtonProps = {
    component: Link,
    size: IconButtonSize.small,
    variant: IconButtonVariant.ghost,
    className: buttonsClassName
  };

  return (
    <AsideLayout {...asideLayoutProps}>
      <Helmet title={title} />

      <main className={clsx(s.LessonPage__container, isLoading && s.LessonPage__container_centered)}>
        {!isLoading ? (
          <div className={s.LessonPage__content}>
            <PageHeader className={s.LessonPage__header} title={title} />

            <Breadcrumbs className={s.LessonPage__bc} items={breadcrumbs} />

            {labels &&
              labels.map(({ description }, idx) => (
                <MoodleContent key={idx} className={s.LessonPage__literature}>
                  {description}
                </MoodleContent>
              ))}

            {content && <MoodleContent className={s.LessonPage__lessonContent}>{content}</MoodleContent>}

            <div className={s.LessonPage__controls}>
              {isTabletLargeDown ? (
                <>
                  {prevActivity && (
                    <IconButton {...iconButtonProps} to={prevActivity.href} icon={IconActionArrowKeyboardLeft} />
                  )}

                  {nextButtonText && (
                    <IconButton
                      {...omit(iconButtonProps, 'component')}
                      onClick={processNextActivity}
                      icon={nextButtonIcon}
                      spinner={completionLoading}
                    />
                  )}
                </>
              ) : (
                <>
                  {prevActivity && (
                    <Button {...buttonProps} to={prevActivity.href} leftIcon={IconActionArrowKeyboardLeft}>
                      Предыдущая часть
                    </Button>
                  )}

                  {nextButtonText && (
                    <Button
                      {...omit(buttonProps, 'component')}
                      onClick={processNextActivity}
                      rightIcon={nextButtonIcon}
                      spinner={completionLoading}>
                      {nextButtonText}
                    </Button>
                  )}
                </>
              )}
            </div>

            <div className={s.LessonPage__buttons}>
              {resource && (
                <FileButtonIcon
                  component={'a'}
                  href={resource.url}
                  download={resource.name}
                  fileName={resource.name}
                  fileSize={formatFileSize(resource.size)}
                />
              )}

              {quiz && (
                <Button component={Link} to={`/quiz/${quiz.id}`}>
                  Перейти к тесту
                </Button>
              )}
            </div>
          </div>
        ) : (
          <Spinner loading size={SpinnerSize.XL} />
        )}
      </main>
    </AsideLayout>
  );
}
