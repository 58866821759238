import {
  IconLmsBook,
  IconStatusExpert,
  IconSectionSed,
  IconSectionMain,
  IconSectionProfile,
  IconLms,
  IconActionLinkText,
  IconGlobalCalendar
} from '@hse-design/react';

import { DOCUMENTS_PAGE_ROUTE, HOME_PAGE_ROUTE, GRADES_PAGE_ROUTE, CALENDAR_PAGE_ROUTE } from 'routes/routes';

import { TMenuItem } from './SidebarItem/SidebarItem';

const MY_HSE_EXTERNAL_LINK = 'https://my.hse.ru';
const HSE_LIBRARY_EXTERNAL_LINK = 'https://library.hse.ru';
const RATE_TEACHERS_EXTERNAL_LINK = 'https://www.hse.ru/evaluation/forst';
const NEWS_EXTERNAL_LINK = 'https://www.hse.ru/ma/scm/news/';

export const menu: TMenuItem[] = [
  {
    text: 'Главная',
    path: HOME_PAGE_ROUTE,
    icon: IconSectionMain
  },
  {
    text: 'Оценки и достижения',
    path: GRADES_PAGE_ROUTE,
    icon: IconLms
  },
  {
    text: 'Материалы',
    path: DOCUMENTS_PAGE_ROUTE,
    icon: IconActionLinkText
  },
  {
    text: 'Личный кабинет',
    path: MY_HSE_EXTERNAL_LINK,
    icon: IconSectionProfile,
    isExternal: true
  },
  {
    text: 'Новости',
    path: NEWS_EXTERNAL_LINK,
    icon: IconSectionSed,
    isExternal: true
  },
  {
    text: 'Библиотека ВШЭ',
    path: HSE_LIBRARY_EXTERNAL_LINK,
    icon: IconLmsBook,
    isExternal: true
  },
  {
    text: 'Оценить преподавателей',
    path: RATE_TEACHERS_EXTERNAL_LINK,
    icon: IconStatusExpert,
    isExternal: true
  },

  // TODO: проставить иконку как в макете
  {
    text: 'Календарь',
    path: CALENDAR_PAGE_ROUTE,
    icon: IconGlobalCalendar
  }
];
