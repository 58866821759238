import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { daysNames, formatDate } from 'utils/date';
import {
  addDays,
  addMonths,
  endOfMonth,
  getDaysInMonth,
  isSameDay,
  isToday,
  isWeekend,
  isWithinInterval,
  startOfMonth
} from 'date-fns';
import { MonthCalendarDate } from './MonthCalendarDate';
import { CalendarBaseProps, MonthCalendarDateType } from '../calendar.types';
import { CalendarNav, CalendarNavDirection } from '../CalendarNav/CalendarNav';
import { Spinner } from '../../Spinner/Spinner';
import { SpinnerSize } from '@hse-design/react';
import { EventModal } from './EventModal';
import { mediaQuery, useMediaQueryResult } from 'hooks/useMediaQueryResult';
import s from './MonthCalendar.module.scss';

const today = new Date();

export interface MonthCalendarProps extends CalendarBaseProps {
  onDayCellClick?: (date: Date) => void;
  loading: boolean;
}

export const MonthCalendar = ({
  className,
  selectedDate = today,
  events,
  loading,
  onDateChange,
  onDayCellClick
}: MonthCalendarProps) => {
  const isMobile = useMediaQueryResult(mediaQuery.tabletSmallDown);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);

  const selectedEvent = selectedEventId === null ? undefined : events?.find((event) => event.id === selectedEventId);

  const handleNavButtonClick = (direction: CalendarNavDirection) => {
    onDateChange?.(addMonths(selectedDate, direction === CalendarNavDirection.Prev ? -1 : 1));
  };

  const nav = useMemo(() => {
    return {
      prev: formatDate(addMonths(selectedDate, -1), 'LLLL'),
      current: formatDate(selectedDate, 'LLLL yyyy'),
      next: formatDate(addMonths(selectedDate, 1), 'LLLL')
    };
  }, [selectedDate]);

  const dates = useMemo(() => {
    const monthStartDate = startOfMonth(selectedDate);
    const monthEndDate = endOfMonth(selectedDate);

    const monthDaysAmount = getDaysInMonth(monthStartDate);
    const dates: MonthCalendarDateType[] = new Array(monthDaysAmount).fill(null).map((_, i) => {
      const date = addDays(monthStartDate, i);
      return {
        date,
        weekend: isWeekend(date),
        otherMonth: false,
        selected: isToday(date)
      };
    });

    const daysToUnshift = +formatDate(monthStartDate, 'i') - 1;
    if (daysToUnshift > 0) {
      dates.unshift(
        ...new Array(daysToUnshift).fill(null).map((_, i) => {
          const date = addDays(monthStartDate, (daysToUnshift - i) * -1);
          return {
            date,
            weekend: isWeekend(date),
            otherMonth: true
          };
        })
      );
    }

    const daysToPush = 6 - (+formatDate(monthEndDate, 'i') - 1);
    if (daysToPush > 0) {
      dates.push(
        ...new Array(daysToPush).fill(null).map((_, i) => {
          const date = addDays(monthEndDate, i + 1);
          return {
            date,
            weekend: isWeekend(date),
            otherMonth: true
          };
        })
      );
    }

    return dates;
  }, [selectedDate]);

  return (
    <div className={clsx(s.MonthCalendar, className)}>
      <CalendarNav
        className={s.Nav}
        title={nav.current}
        buttonPrevContent={nav.prev}
        buttonNextContent={nav.next}
        onNavigate={handleNavButtonClick}
      />

      <div className={s.Grid}>
        {daysNames.map((day, iDay) => (
          <div key={iDay} className={clsx(s.Grid__col, s.Grid__day)}>
            {day}
          </div>
        ))}

        {dates.map((date, iDate) => {
          const dateEvents = events?.filter((event) => {
            return !event.date_end
              ? isSameDay(new Date(event.date_start), date.date)
              : isWithinInterval(date.date, {
                  start: new Date(event.date_start),
                  end: new Date(event.date_end)
                });
          });

          return (
            <MonthCalendarDate
              key={iDate}
              className={clsx(s.Grid__col, s.Grid__date)}
              {...date}
              events={dateEvents}
              isMobile={isMobile}
              onClick={() => onDayCellClick?.(date.date)}
              onEventClick={setSelectedEventId}
            />
          );
        })}

        {loading && (
          <div className={s.Loader}>
            <Spinner loading size={SpinnerSize.large} />
          </div>
        )}
      </div>

      <EventModal event={selectedEvent} isOpen={!!selectedEvent} onCloseClick={() => setSelectedEventId(null)} />
    </div>
  );
};
