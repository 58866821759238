import React, { useLayoutEffect, useMemo, useState } from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import { CheckboxSize, RadioGroup } from '@hse-design/react';
import { IQuizQuestionProps } from 'routes/quiz/QuizQuestions/types';
import { QuizQuestionLayout } from 'routes/quiz/QuizQuestions/QuizQuestionLayout/QuizQuestionLayout';
import { useIsMounted } from 'hooks/useIsMounted';

export const RadioQuestion: ReactFCC<IQuizQuestionProps> = (props) => {
  const { content, title, index, answers, setAnswers } = props;

  const [parseError, setParseError] = useState(false);

  const isMounted = useIsMounted();

  const { options, questionName } = useMemo(() => {
    const $answers = Array.from(content?.querySelectorAll('.answer > div') ?? []);
    let qName: undefined | string | null;

    const options = $answers.map((answer) => {
      const id = answer.querySelector('[type="radio"]')?.getAttribute('id');
      qName = answer.querySelector('[type="radio"]')?.getAttribute('name');
      const label =
        answer.querySelector('label')?.textContent ?? answer.querySelector('[data-region="answer-label"]')?.textContent;
      const value = answer.querySelector('[type="radio"]')?.getAttribute('value');

      if (!label || !id || !value) {
        setParseError(true);

        return {
          id: '',
          label: '',
          value: ''
        };
      }

      return {
        id,
        label,
        value
      };
    });

    return {
      options,
      questionName: qName
    };
  }, [content]);

  useLayoutEffect(() => {
    if (!questionName || isMounted()) {
      return;
    }

    setAnswers([
      {
        name: questionName,
        value: ''
      }
    ]);
  }, [questionName, setAnswers, isMounted]);

  let error = parseError || !questionName;

  const radioChangeHandler = (value: string | null) => {
    if (!questionName || !value) {
      return;
    }

    setAnswers([
      {
        name: questionName,
        value
      }
    ]);
  };

  const value = answers[0]?.value;

  return (
    <QuizQuestionLayout error={error} title={title} index={index}>
      <RadioGroup size={CheckboxSize.medium} onChange={radioChangeHandler} options={options} value={value} />
    </QuizQuestionLayout>
  );
};
