import React, { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import s from './DisciplineCard.module.scss';
import { ReactFCC } from 'utils/ReactFCC';
import { Avatar } from 'components/Avatar/Avatar';
import { Anchor, EAnchorSize } from 'components/Anchor/Anchor';
import { IconActionArrowForward, LinkVariant } from '@hse-design/react';
import { DISCIPLINE_PAGE_PARAM, DISCIPLINE_PAGE_ROUTE } from 'routes/routes';
import { generatePath, Link } from 'react-router-dom';

export type TDisciplineCardTeacher = {
  image?: string;
  jobTitle?: string;
  name?: string;
};

export type TDisciplineCard = {
  className?: string;
  id: number;
  href?: string;
  name: string;
  progress?: number;
  teacher?: TDisciplineCardTeacher;
};

export const DisciplineCard: ReactFCC<TDisciplineCard> = (props) => {
  const { className, id, href, name, progress, teacher } = props;

  const { url, isInternal } = useMemo(() => {
    let isInternal = false;
    let url = '';

    if (href) {
      if (href) {
        url = href;
        isInternal = true;
      } else {
        url = href;
      }
    } else {
      isInternal = true;
      url = generatePath(DISCIPLINE_PAGE_ROUTE, {
        [DISCIPLINE_PAGE_PARAM]: id
      });
    }

    return {
      isInternal,
      url
    };
  }, [href, id]);

  return (
    <LinkOrA href={url} isInternal={isInternal} className={className}>
      <div className={s.DisciplineCard__cover}>
        <span className={s.DisciplineCard__coverText}>{name}</span>
      </div>

      {teacher && (
        <div className={s.DisciplineCard__teacher}>
          <span className={s.DisciplineCard__teacherName}>
            {teacher.jobTitle && `${teacher.jobTitle}: `} {teacher.name}
          </span>

          <Avatar
            alt={name || 'Фото преподавателя'}
            className={s.DisciplineCard__teacherImage}
            isRound
            size={24}
            src={teacher.image}
          />
        </div>
      )}

      {typeof progress === 'number' && (
        <progress className={s.DisciplineCard__progress} max={100} value={progress || 0} />
      )}

      <div className={s.DisciplineCard__bottom}>
        {typeof progress === 'number' && <div className={s.DisciplineCard__percent}>{Math.floor(progress)}%</div>}

        <Anchor
          className={s.DisciplineCard__link}
          component="div"
          rightIcon={() => <IconActionArrowForward size="small" />}
          size={EAnchorSize.SMALL}
          variant={LinkVariant.secondary}>
          Продолжить
        </Anchor>
      </div>
    </LinkOrA>
  );
};

interface LinkOrAProps {
  href: string;
  isInternal: boolean;
  children: ReactNode;
  className?: string;
}

const LinkOrA = ({ href, isInternal, children, className }: LinkOrAProps) => {
  if (isInternal) {
    return (
      <Link className={clsx(s.DisciplineCard, className)} to={href}>
        {children}
      </Link>
    );
  }

  return (
    <a className={clsx(s.DisciplineCard, className)} href={href}>
      {children}
    </a>
  );
};
