export const rating = [
  { name: '	Корнилов Евгений Владимирович	', id: 6715598971, rating: 180.0 },
  { name: '	Сосновский Даниил Алексеевич	', id: 6706644904, rating: 180.0 },
  { name: '	Александрова Юлия Андреевна	', id: 6697804168, rating: 174.0 },
  { name: '	Карцивадзе Алина Руслановна	', id: 6673121549, rating: 174.0 },
  { name: '	Медведев Дмитрий Валерьевич	', id: 6709924647, rating: 174.0 },
  { name: '	Шварц Анна Игоревна	', id: 6673121307, rating: 168.0 },
  { name: '	Денисова Анастасия Юрьевна	', id: 6697804021, rating: 164.0 },
  { name: '	Кирдяшкин Павел Валерьевич	', id: 6709924678, rating: 164.0 },
  { name: '	Ясная Алиса Алексеевна	', id: 6697804264, rating: 164.0 },
  { name: '	Дитякина Анна Алексеевна	', id: 6709924616, rating: 162.0 },
  { name: '	Риверская Мария Александровна	', id: 6673121150, rating: 162.0 },
  { name: '	Загоркова Дарья Андреевна	', id: 6697804222, rating: 158.0 },
  { name: '	Логвиненко Ирина Николаевна	', id: 6673121448, rating: 158.0 },
  { name: '	Хмелева Ольга Олеговна	', id: 6706644839, rating: 158.0 },
  { name: '	Байрамова Альбина Фирудуновна	', id: 6706644698, rating: 156.0 },
  { name: '	Гриценко Богдан Витальевич	', id: 6706644546, rating: 156.0 },
  { name: '	Фахрутдинова Полина Александровна	', id: 6702707114, rating: 156.0 },
  { name: '	Борисова Елена Александровна	', id: 6673121188, rating: 154.0 },
  { name: '	Киршина Елена Алексеевна	', id: 6706644806, rating: 154.0 },
  { name: '	Пидлужная Ирина Владимировна	', id: 6697804343, rating: 152.0 },
  { name: '	Староверов Иван Андреевич	', id: 6673121115, rating: 152.0 },
  { name: '	Лозовикова Дарья Романовна	', id: 6673333820, rating: 150.0 },
  { name: '	Рогонова Анна Андреевна	', id: 6673333790, rating: 150.0 },
  { name: '	Иванова Ульяна Андреевна	', id: 6706644579, rating: 146.0 },
  { name: '	Тиво Никита Александрович	', id: 6699913777, rating: 146.0 },
  { name: '	Тихонов Павел Андреевич	', id: 6702707243, rating: 146.0 },
  { name: '	Шурунгова Джиргала Алексеевна	', id: 6673121480, rating: 146.0 },
  { name: '	Белов Никита Андреевич	', id: 6706644513, rating: 144.0 },
  { name: '	Кришнарадж Илья Даттатрея	', id: 6697804305, rating: 144.0 },
  { name: '	Шатайло Юлия Игоревна	', id: 6673121379, rating: 144.0 },
  { name: '	Шмаков Василий Алексеевич	', id: 6706644736, rating: 142.0 },
  { name: '	Ахмедова Жанона Тулкиновна	', id: 6662542869, rating: 140.0 },
  { name: '	Лазобко Марина Сергеевна	', id: 6673121268, rating: 140.0 },
  { name: '	Тамбовцева Марина Андреевна	', id: 6709924710, rating: 140.0 },
  { name: '	Афоняшин Никита Александрович	', id: 6673121342, rating: 136.0 },
  { name: '	Лосюк Юлия Константиновна	', id: 6697804131, rating: 136.0 },
  { name: '	Гергет Луиза Андреевна	', id: 6702707198, rating: 134.0 },
  { name: '	Иорданский Даниил Леонидович	', id: 6673333761, rating: 134.0 },
  { name: '	Мальцева Александра Леонидовна	', id: 6673121511, rating: 134.0 },
  { name: '	Притула Вероника Сергеевна	', id: 6695112387, rating: 130.0 },
  { name: '	Прокопенко Юлия Андреевна	', id: 6674994918, rating: 128.0 },
  { name: '	Мамонтов Дмитрий Олегович	', id: 6706644661, rating: 118.0 },
  { name: '	Климова Татьяна Викторовна	', id: 6706644937, rating: 112.0 },
  { name: '	Ксенофонтов Кирилл Александрович	', id: 6706644621, rating: 112.0 },
  { name: '	Кузнецова Екатерина Андреевна	', id: 6697804059, rating: 108.0 },
  { name: '	Величко Андрей Алексеевич	', id: 6706410975, rating: 102.0 },
  { name: '	Казьмин Игорь Александрович	', id: 6709924773, rating: 100.0 },
  { name: '	Парамонова Дарья Валерьевна	', id: 6697804094, rating: 96.0 },
  { name: '	Савин Виктор Витальевич	', id: 6706644970, rating: 90.0 },
  { name: '	Квашонкин Александр Александрович	', id: 6706411844, rating: 60.0 },
  { name: '	Антонов Павел Юрьевич	', id: 6706644871, rating: 44.0 },
  { name: '	Березина Карина Ивановна	', id: 6702707156, rating: 28.0 },
  { name: '	Исмаилова Айсел Валяддин кызы	', id: 6705555565, rating: 22.0 }
].map((row) => ({ ...row, rating: Math.round(row.rating), name: row.name.trim() }));
