import React from 'react';

export interface IQuizAnswer {
  name: string;
  value: string;
  label?: string;
}

export interface IQuizQuestionProps {
  content: Element | null;
  title?: Element | null;
  index?: number;
  questionType: string;
  answers: IQuizAnswer[];
  setAnswers: React.Dispatch<React.SetStateAction<IQuizAnswer[]>>;
  // onSendAnswers: () => void;
}

export interface IQuizPageState {
  current: number | null;
  all: number | null;
  next: number | null;
  submitted: number | null;
  finishAttempt: boolean;
}

export enum EQuestType {
  MULTI_CHOICE_SET = 'multichoiceset',
  MULTI_CHOICE = 'multichoice',
  DDW_TO_SHARD = 'ddwtoshard',
  MATCH = 'match',
  SHORT_ANSWER = 'shortanswer',
  TRUE_FALSE = 'truefalse'
}
