import React, { CSSProperties } from 'react';

import clsx from 'clsx';

import { PersonProps } from '@hse-design/react';

import s from './Avatar.module.scss';
import { ReactFCC } from 'utils/ReactFCC';

export enum EAvatarSize {
  S = 16,
  M = 24,
  L = 32,
  XL = 48,
  XXL = 64
}

export enum EAvatarBorderRadius {
  S = 2,
  M = 4,
  L = 8,
  XL = 12,
  XXL = 16
}

export type TAvatar = Omit<PersonProps, 'alt'> & {
  alt: string;
  borderRadius?: EAvatarBorderRadius;
  isRound?: boolean;
  size?: EAvatarSize | number;
};

/**
 * Avatar component
 *
 * @param {TAvatar} props
 * @component {ReactFCC<TAvatar>} UserAvatar
 *
 * TODO: add default image.
 */
export const Avatar: ReactFCC<TAvatar> = (props) => {
  const { className, borderRadius, alt, isRound, src, size = EAvatarSize.M, ...rest } = props;

  const style: CSSProperties = { borderRadius: typeof borderRadius === 'number' ? `${borderRadius}px` : undefined };

  return (
    <div className={clsx(s.Avatar__container, className)}>
      {src && (
        <img
          alt={alt}
          className={clsx(s.Avatar__img, isRound && s.Avatar__img_round)}
          height={size}
          src={src}
          style={{
            ...style,
            ...rest?.style
          }}
          width={size}
          {...rest}
        />
      )}
    </div>
  );
};
