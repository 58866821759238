import React, { ChangeEvent, useLayoutEffect, useState } from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import { Checkbox, CheckboxSize } from '@hse-design/react';
import { IQuizQuestionProps } from 'routes/quiz/QuizQuestions/types';
import { QuizQuestionLayout } from 'routes/quiz/QuizQuestions/QuizQuestionLayout/QuizQuestionLayout';
import { useIsMounted } from 'hooks/useIsMounted';
import s from '../../QuizQuestionLayout/QuizQuestionLayout.module.scss';

export const MultiChoiceQuestion: ReactFCC<IQuizQuestionProps> = (props) => {
  const { content, title, index, answers, setAnswers } = props;

  const [error, setError] = useState(false);

  const isMounted = useIsMounted();

  useLayoutEffect(() => {
    if (isMounted()) {
      return;
    }

    const $answers = Array.from(content?.querySelectorAll('.answer > div') ?? []);

    const initialAnswers = $answers.map((answer) => {
      const label = answer.querySelector('[data-region="answer-label"]')?.textContent;
      const name = answer.querySelector('[type="hidden"]')?.getAttribute('name');

      if (!label || !name) {
        setError(true);

        return {
          name: '',
          label: '',
          value: ''
        };
      }

      return {
        name,
        label,
        value: '0'
      };
    });

    setAnswers(initialAnswers);
  }, [content, setAnswers, isMounted]);

  const checkboxChangeHandler = (checked: boolean, e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.getAttribute('id');

    if (!name) {
      return;
    }

    setAnswers((prev) =>
      prev.map((answer) => ({
        ...answer,
        value: answer.name === name ? (checked ? '1' : '0') : answer.value
      }))
    );
  };

  return (
    <QuizQuestionLayout error={error} title={title} index={index}>
      {answers.map(({ name, label, value }) => (
        <div key={name} className={s.QuizQuestionLayout__answer}>
          <Checkbox
            id={name}
            label={label}
            size={CheckboxSize.medium}
            checked={value === '1'}
            onChange={checkboxChangeHandler}
          />
        </div>
      ))}
    </QuizQuestionLayout>
  );
};
