import React from 'react';
import clsx from 'clsx';
import { LinkProps as LinkRouterProps, useHref, useLinkClickHandler } from 'react-router-dom';
import s from './Link.module.scss';
import { LinkPropsSelf, LinkDefaultComponentType } from '@hse-design/react';
import { Anchor } from '../Anchor/Anchor';
import { ReactFCC } from 'utils/ReactFCC';

export type TLink = LinkPropsSelf<LinkDefaultComponentType> & LinkRouterProps;

export const Link: ReactFCC<TLink> = (props) => {
  const { className, children, onClick, replace = false, state, target, to, relative, ...rest } = props;

  let href = useHref(to, { relative });
  let handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
    relative
  });

  return (
    <Anchor
      {...rest}
      className={clsx(s.Link, className)}
      component={'a'}
      href={href}
      showVisited={true}
      onClick={(event) => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
        }
      }}>
      {children}
    </Anchor>
  );
};
