export const useParseDropElements = (content: Element | null) => {
  const $title = content?.querySelectorAll('.qtext p');
  const dropElements: string[] = [];

  Array.from($title ?? []).forEach(($item) => {
    if (!$item.textContent) {
      return;
    }

    if ($item.querySelector('.drop')) {
      const content = Array.from($item.childNodes)
        .filter((i) => {
          if (i.nodeType === Node.ELEMENT_NODE) {
            return !(i as HTMLElement).classList.contains('drop');
          }

          return true;
        })
        .map((i) => i.textContent ?? '')
        .join('');

      dropElements.push(content);
    }
  });

  return dropElements;
};
