import clsx from 'clsx';
import React, { ElementType } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonSize, ButtonVariant } from '@hse-design/react';

import { ReactFCC } from 'utils/ReactFCC';
import { isKeyDownEnter } from 'utils/isKeyDownEnter';

import s from './SidebarItem.module.scss';

export type TMenuItem = {
  text: string;
  path?: string;
  icon?: unknown;
  isDisabled?: boolean;
  isExternal?: boolean;
};

export type TSidebarItem = TMenuItem & {
  className?: string;
  isActive?: boolean;
  tabIndex?: number;
  onClick?: () => void;
};

export const SidebarItem: ReactFCC<TSidebarItem> = (props) => {
  const { className, isActive, isDisabled, isExternal, text, path, icon, tabIndex, onClick } = props;

  if ((!!path && !!onClick) || (!path && !onClick)) {
    throw new Error('SidebarItem: either path or onClick must be defined');
  }

  const commonProps = {
    className: clsx(s.SidebarItem__button, isActive && s.SidebarItem__button_active, className),
    classes: {
      content: s.SidebarItem__button_content
    },
    disabled: isDisabled,
    leftIcon: icon as ElementType<JSX.Element> | undefined,
    selected: isActive,
    size: ButtonSize.large,
    tabIndex: tabIndex,
    variant: ButtonVariant.primary
  };

  if (path !== undefined && !isExternal) {
    return (
      <Button component={Link} to={path} {...commonProps}>
        {text}
      </Button>
    );
  }

  if (path !== undefined && isExternal) {
    return (
      <Button component="a" href={path} target="_blank" {...commonProps}>
        {text}
      </Button>
    );
  }

  return (
    <Button
      component="button"
      onClick={() => onClick!()}
      onKeyDownCapture={(e: React.KeyboardEvent) => {
        if (isKeyDownEnter(e)) {
          onClick!();
        }
      }}
      {...commonProps}>
      {text}
    </Button>
  );
};
