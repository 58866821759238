import React, { useMemo } from 'react';
import clsx from 'clsx';
import { formatDate } from 'utils/date';
import { addDays } from 'date-fns';
import { CalendarBaseProps } from '../calendar.types';
import { CalendarNav, CalendarNavDirection } from '../CalendarNav/CalendarNav';
import { Spinner } from '../../Spinner/Spinner';
import { SpinnerSize } from '@hse-design/react';
import { DayCalendarEvent } from './DayCalendarEvent';
import s from './DayCalendar.module.scss';

const today = new Date();

export interface DayCalendarProps extends CalendarBaseProps {
  loading: boolean;
}

export const DayCalendar = ({ className, events, loading, selectedDate = today, onDateChange }: DayCalendarProps) => {
  const handleNavButtonClick = (direction: CalendarNavDirection) => {
    onDateChange?.(addDays(selectedDate, direction === CalendarNavDirection.Prev ? -1 : 1));
  };

  const nav = useMemo(() => {
    return {
      prev: formatDate(addDays(selectedDate, -1), 'EEEEEE'),
      current: formatDate(selectedDate, 'EEEEEE, dd MMMM, yyyy'),
      next: formatDate(addDays(selectedDate, 1), 'EEEEEE')
    };
  }, [selectedDate]);

  return (
    <div className={clsx(s.DayCalendar, className)}>
      <CalendarNav
        className={s.Nav}
        title={nav.current}
        buttonPrevContent={nav.prev}
        buttonNextContent={nav.next}
        onNavigate={handleNavButtonClick}
      />

      <div className={s.List}>
        {loading ? (
          <div className={s.Loader}>
            <Spinner loading size={SpinnerSize.large} />
          </div>
        ) : !events?.length ? (
          <div className={s.List__dataPlaceholder}>Нет событий на выбранную дату</div>
        ) : (
          events.map((item, iItem) => (
            <div key={iItem} className={s.List__event}>
              <DayCalendarEvent {...item} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};
