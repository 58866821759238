import { BreadcrumbEntityType, BreadcrumbType, getBreadcrumbItem } from 'components/Breadcrumbs';

export function getDisciplinePageBreadcrumbs({ course }: { course: BreadcrumbEntityType }): BreadcrumbType[] {
  return [
    getBreadcrumbItem({
      entity: course,
      path: '/courses',
      overflowLength: 50
    })
  ];
}
