import clsx from 'clsx';
import { ReactFCC } from '../../../../../../utils/ReactFCC';
import { IconActionArrowKeyboardRight, SpinnerSize } from '@hse-design/react';
import React, { useState } from 'react';
import s from './QuizTableCard.module.scss';
import { GradeTableCellType, GradeTableData } from '../../../GradeTable';
import { QuizTableCardQuiz } from './QuizTableCardQuiz';
import { QuizTableBaseProps } from '../types';
import { Spinner } from '../../../../../../components/Spinner/Spinner';

export interface QuizTableCardProps extends QuizTableBaseProps {}

export const QuizTableCard: ReactFCC<QuizTableCardProps> = (props) => {
  const { grade, loadCourseGradeItems, isLoading } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr className={s.QuizTableCard_disciplineRow}>
        <td colSpan={3}>
          {grade.courseName}{' '}
          <Spinner className={s.QuizTableCard__spinner} size={SpinnerSize.small} loading={isLoading} />
        </td>

        <GradeTableData type={GradeTableCellType.right}>
          <IconActionArrowKeyboardRight
            className={clsx(s.QuizTableCard__chevron, isOpen && s.QuizTableCard__chevron_open)}
            size={'small'}
            onClick={() => {
              setIsOpen((value) => !value);
              loadCourseGradeItems(grade.courseId);
            }}
          />
        </GradeTableData>
      </tr>

      {isOpen && grade.quizzes.map((quiz, index) => <QuizTableCardQuiz quiz={quiz} key={index} />)}
    </>
  );
};
