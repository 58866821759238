import React, { forwardRef } from 'react';

import clsx from 'clsx';

import s from './Input.module.scss';

import { Input as InputHse, InputProps } from '@hse-design/react';

export const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const { className, ...rest } = props;
  return <InputHse {...rest} ref={ref} className={clsx(s.Input, className)} />;
});
