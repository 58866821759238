export function insertAccessKeyIntoUrls(stringWithProtectedUrls: string, userPrivateAccessKey?: string) {
  // userprivateaccesskey is marked as optional in Moodle API documentation
  if (!userPrivateAccessKey) {
    console.error('No userprivateaccesskey available.');
    return stringWithProtectedUrls;
  }

  return stringWithProtectedUrls.replace(
    /\/webservice\/pluginfile.php|\/pluginfile.php/g,
    `/tokenpluginfile.php/${userPrivateAccessKey}`
  );
}
