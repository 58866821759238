import React, { useLayoutEffect } from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import { IQuizQuestionProps } from 'routes/quiz/QuizQuestions/types';
import { QuizQuestionLayout } from 'routes/quiz/QuizQuestions/QuizQuestionLayout/QuizQuestionLayout';
import { Input } from '@hse-design/react';
import { useIsMounted } from 'hooks/useIsMounted';

export const ShortAnswerQuestion: ReactFCC<IQuizQuestionProps> = (props) => {
  const { content, title, index, answers, setAnswers } = props;

  const isMounted = useIsMounted();

  useLayoutEffect(() => {
    if (isMounted()) {
      return;
    }

    const $answer = content?.querySelector('.answer input');

    setAnswers([
      {
        name: $answer?.getAttribute('name') ?? '',
        value: ''
      }
    ]);
  }, [content, setAnswers, isMounted]);

  const answerName = answers[0]?.name;
  const value = answers[0]?.value;

  let error = !answerName;

  return (
    <QuizQuestionLayout error={error} title={title} index={index}>
      <Input
        fullWidth
        value={value}
        onChange={(e) =>
          setAnswers([
            {
              name: answerName ?? '',
              value: e.target.value
            }
          ])
        }
      />
    </QuizQuestionLayout>
  );
};
