import React, { useEffect, useState } from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import {
  Button,
  Error,
  IconActionArrowKeyboardLeft,
  IconActionArrowKeyboardRight,
  IconButtonSize,
  IconButtonVariant,
  Paragraph,
  SpinnerSize
} from '@hse-design/react';
import { IconButton, IconButtonSurface } from 'components/IconButton/IconButton';
import { QuizCard } from 'routes/quiz/QuizCard/QuizCard';
import { useQuizAttempt } from 'routes/quiz/api/useQuizAttempt';
import { Spinner } from 'components/Spinner/Spinner';
import { IAttempt } from 'store/moodleAPI/moodleTypes/Attempt';
import { EQuestType, IQuizPageState } from 'routes/quiz/QuizQuestions/types';
import s from './QuizQuestions.module.scss';
import { QuizQuestion } from './QuizQuestion';

export interface IQuizQuestionsProps {
  instanceId: string;
  inProgressAttempt: IAttempt;
  onFinishQuiz: () => void;
}

export const QuizQuestions: ReactFCC<IQuizQuestionsProps> = React.memo((props) => {
  const { instanceId, inProgressAttempt, onFinishQuiz } = props;

  const [processAttempt, setProcessAttempt] = useState(false);

  const [{ current: currentPage, all: allPages, submitted: submittedPage, finishAttempt }, setPageState] =
    useState<IQuizPageState>({
      current: null,
      all: null,
      next: null,
      submitted: null,
      finishAttempt: false
    });

  const {
    questions,
    attemptData,
    attemptPage,
    attemptAllPages,
    isLoading: attemptIsLoading,
    error: attemptError
  } = useQuizAttempt(instanceId, currentPage, inProgressAttempt);

  useEffect(() => {
    if (attemptPage === undefined || attemptAllPages === undefined) {
      return;
    }

    setPageState((p) => ({
      ...p,
      current: attemptPage,
      all: attemptAllPages
    }));
  }, [attemptPage, attemptAllPages]);

  const sendAnswersHandler = () => {
    const submitted = !submittedPage || (currentPage ?? 0) > submittedPage ? currentPage ?? 0 : submittedPage;

    setPageState((p) => ({
      ...p,
      submitted,
      next: (p.current ?? 0) + 1,
      finishAttempt: submitted + 1 === (p.all ?? 0)
    }));

    setProcessAttempt(true);
  };

  const changePageHandler = (page: number) => {
    setPageState((p) => ({
      ...p,
      next: page
    }));

    setProcessAttempt(true);
  };

  const goNextQuestion = () => {
    if (currentPage === null || currentPage + 1 === allPages) {
      return;
    }

    changePageHandler(currentPage + 1);
  };

  const goPrevQuestion = () => {
    if (currentPage === null || currentPage === 0) {
      return;
    }

    changePageHandler(currentPage - 1);
  };

  const isLoading = questions.length === 0 || attemptIsLoading || processAttempt;

  const error = attemptError;

  const invalidTypeError = questions.some(
    (question) =>
      !(
        [
          EQuestType.MULTI_CHOICE_SET,
          EQuestType.DDW_TO_SHARD,
          EQuestType.MATCH,
          EQuestType.SHORT_ANSWER,
          EQuestType.TRUE_FALSE,
          EQuestType.MULTI_CHOICE
        ] as string[]
      ).includes(question.type)
  );

  if (error || invalidTypeError) {
    return (
      <QuizCard>
        <Error title={error ?? 'Неподдерживаемый тип вопроса'} buttonText={'Продолжить'} onButtonClick={onFinishQuiz} />
      </QuizCard>
    );
  }

  return (
    <QuizCard
      title={
        <div className={s.QuizQuestions__header}>
          {currentPage !== null && allPages !== null && (
            <>
              <IconButton
                disabled={!currentPage}
                style={{ display: 'none' }}
                variant={IconButtonVariant.ghost}
                size={IconButtonSize.small}
                surface={IconButtonSurface.dark}
                icon={IconActionArrowKeyboardLeft}
                onClick={goPrevQuestion}
              />
              <Paragraph className={s.QuizQuestions__headerPages}>
                Страница {currentPage + 1} из {allPages}
              </Paragraph>
              <IconButton
                disabled={Boolean(!submittedPage || (currentPage ?? 0 < submittedPage ?? 0))}
                style={{ display: 'none' }}
                variant={IconButtonVariant.ghost}
                size={IconButtonSize.small}
                surface={IconButtonSurface.dark}
                icon={IconActionArrowKeyboardRight}
                onClick={goNextQuestion}
              />
            </>
          )}
        </div>
      }>
      {isLoading && <Spinner loading size={SpinnerSize.XL} />}

      <div>
        {questions.map((question, index) => (
          <QuizQuestion
            question={question}
            attemptData={attemptData}
            finishAttempt={finishAttempt}
            processAttempt={processAttempt}
            setProcessAttempt={setProcessAttempt}
            onFinishQuiz={onFinishQuiz}
            goNextPage={() => setPageState((p) => ({ ...p, current: p.next }))}
            key={index}
          />
        ))}
      </div>

      {!isLoading && <Button onClick={sendAnswersHandler}>Отправить</Button>}
    </QuizCard>
  );
});
