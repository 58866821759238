import { useSiteInfoData } from '../../store/siteInfo';
import { useAuth } from '../../store/auth';
import { moodleDomain } from '../../store/config';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import s from './MoodleMessages.module.scss';
import qs from 'qs';
import { useSingleTimeout } from '../../hooks/useSingleTimeout';
import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as CrossOutlined } from '../IconsFactory/plain/cross-outlined.svg';
import { IconButtonSize, IconButtonVariant } from '@hse-design/react';

export interface MoodleMessagesProps {
  domain?: string;
  className?: string;
  classes?: {
    moodle?: string;
  };
  isMessagesLoading: boolean;
  setMessagesLoading: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  toggleOpen: () => void;
}

const url = '/my/';
const getMessagesSrc = (domain: string, token?: string) => {
  return `https://${domain}${url}?${qs.stringify({ token })}`;
};

export function MoodleMessages(props: MoodleMessagesProps) {
  const { className, classes, isOpen, toggleOpen, isMessagesLoading, setMessagesLoading } = props;

  const siteInfo = useSiteInfoData();
  const { getToken } = useAuth();

  const user = `${siteInfo.firstname} ${siteInfo.lastname}`;
  const domain = props.domain || moodleDomain;

  const state = useRef<{ location: string; user?: string; height?: number; proxy?: WindowProxy }>({
    location: url
  });

  const timeout = useSingleTimeout();

  const src = useMemo(() => {
    return getMessagesSrc(domain);
    // eslint-disable-next-line
  }, []);

  const handleMessage = async (e: MessageEvent) => {
    if (e.data.type === 'STATE') {
      state.current = { ...e.data.payload, proxy: e.source };

      if (state.current.location.includes('login')) {
        const token = await getToken();
        // выход из Moodle сбрасывает язык на русский
        // @ts-ignore
        e.source.postMessage({ type: 'REPLACE', payload: getMessagesSrc(domain, token) }, '*');
      } else if (e.data.payload.user && e.data.payload.user !== user) {
        // автоматический выход при несовпадении пользователей
        // @ts-ignore
        e.source.postMessage({ type: 'LOG_OUT' }, '*');
      } else {
        // @ts-ignore
        e.source.postMessage({ type: 'MESSAGES' }, '*');
      }
    } else if (e.data.type === 'HEIGHT') {
      state.current.height = e.data.payload;
    } else if (e.data.type === 'DONE') {
      // Событие DONE почему-то не гарантирует загрузку страницы, сайдбар с сообщениями может работать некорректно
      // На всякий случай увеличим задержку
      // todo Разобраться
      setTimeout(() => {
        setMessagesLoading(false);
      }, 2000);
    } else if (e.data.type === 'UNLOAD') {
      setMessagesLoading(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  useEffect(() => {
    if (state.current.proxy) {
      if (isOpen) {
        state.current.proxy.postMessage({ type: 'MESSAGES_OPEN' }, '*');
        timeout.clear();
      } else {
        // Анимация закрытия
        timeout.set(() => {
          state.current.proxy?.postMessage({ type: 'MESSAGES_CLOSE' }, '*');
        }, 200);
      }
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <div
      className={clsx(s.MoodleMessages, className, {
        [s.MoodleMessages_open]: isOpen
      })}>
      <div className={s.MoodleMessages__header}>
        <span className={s.MoodleMessages__heading}>Сообщения</span>

        <IconButton
          icon={CrossOutlined}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          onClick={toggleOpen}
        />
      </div>

      <div className={s.MoodleMessages__moodleContainer}>
        <iframe
          allow={'fullscreen; camera; microphone; autoplay;'}
          src={src}
          title={'Сообщения'}
          className={clsx(
            s.MoodleMessages__moodle,
            {
              [s.MoodleMessages__moodle_loading]: isMessagesLoading
            },
            classes?.moodle
          )}
        />
      </div>
    </div>
  );
}
