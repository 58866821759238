export type BreadcrumbType = {
  title?: string;
  label?: string;
  path: string;
};

export type BreadcrumbEntityType = {
  id: string | number;
  name?: string;
};

export interface GetBreadcrumbItemProps {
  entity: BreadcrumbEntityType;
  path: string;
  overflowLength?: number;
}

export function getBreadcrumbItem({ entity, path, overflowLength }: GetBreadcrumbItemProps): BreadcrumbType {
  const name = entity.name;

  const shortText = (() => {
    if (!name) return '';
    if (!overflowLength) return name;
    return name.length <= overflowLength + 3 ? name : `${name.substring(0, overflowLength)}...`;
  })();

  return {
    title: name || shortText || '',
    label: shortText || '...',
    path: path + '/' + entity.id
  };
}
