import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Space } from '@hse-design/react';
import { LoginDecor } from './LoginDecor/LoginDecor';
import s from './NoAccessPage.module.scss';
import { VectorIconMulticolor } from '../../components/IconsFactory/IconsFactory';
import { EIconsMulticolor } from '../../components/IconsFactory/Icons';

export function NoAccessPage() {
  return (
    <React.Fragment>
      <Helmet title={'Нет доступа'} />
      <div className={s.NoAccessPage}>
        <LoginDecor isTop />
        <Space size={Space.Size.large} vertical />

        <main className={s.NoAccessPage__content}>
          <VectorIconMulticolor
            icon={EIconsMulticolor.LOGO_HSE_FULL_ROUND_FILLED}
            className={s.LoginGreeting__logo}
            width={136}
            height={136}
          />

          <p className={s.NoAccessPage__message}>
            У вас нет доступа к этой версии сервиса. <br />
            Пожалуйста, перейдите по <a href={'https://edu.hse.ru'}>ссылке</a>
          </p>
        </main>

        <Space size={Space.Size.large} vertical />
        <LoginDecor />
      </div>
    </React.Fragment>
  );
}
