import { rating } from '../../api/rating.mock';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { GradeTableData, GradeTableCellType, GradeTable } from '../GradeTable';

type Rating = typeof rating[number];

export interface RatingTableProps {
  rating: Rating[];
}

export const RatingTable = ({ rating }: RatingTableProps) => {
  const uniqueRating = rating.map((r) => r.rating).filter((v, i, a) => a.indexOf(v) === i);
  uniqueRating.sort((a, b) => b - a);

  return (
    <GradeTable>
      <caption>Текущий рейтинг 2022/2023 учебного года</caption>
      <Header />
      <tbody>
        {rating?.map((row) => (
          <Row key={row.id} rating={row} position={uniqueRating.indexOf(row.rating) + 1} />
        ))}
      </tbody>
    </GradeTable>
  );
};

const Header = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  return (
    <thead>
      <tr>
        <th>Студент</th>
        <th>Позиция в рейтинге</th>
        <th>Кредитно-рейтинговая оценка</th>
      </tr>
    </thead>
  );
};

const Row = ({ rating, position }: { rating: Rating; position: number }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <>
        <tr>
          <th colSpan={2}>{rating.name}</th>
        </tr>
        <tr>
          <GradeTableData type={GradeTableCellType.header}>Позиция в рейтинге</GradeTableData>
          <td>{position}</td>
        </tr>
        <tr>
          <GradeTableData type={GradeTableCellType.header}>Кредитно-рейтинговая оценка</GradeTableData>
          <td>{rating.rating}</td>
        </tr>
      </>
    );
  }

  return (
    <tr key={rating.id}>
      <td>{rating.name}</td>
      <GradeTableData type={GradeTableCellType.right}>{position}</GradeTableData>
      <GradeTableData type={GradeTableCellType.right}>{rating.rating}</GradeTableData>
    </tr>
  );
};
