import clsx from 'clsx';
import s from './ActivitiesSection.module.scss';
import { ReactFCC } from '../../../utils/ReactFCC';
import { IActivitiesSection } from '../../../store/moodleAPI/courseActivities/activityTypes';
import { Spinner } from '../../Spinner/Spinner';
import { ClickableIcon, IconActionArrowKeyboardBottom, IconSize, SpinnerSize } from '@hse-design/react';
import React, { useState } from 'react';
import { Activities } from '../Activities';

export interface ActivitiesSectionProps {
  className?: string;
  isLoading?: boolean;
  section: IActivitiesSection | null;
  isOpenDefault?: boolean;
}

export const ActivitiesSection: ReactFCC<ActivitiesSectionProps> = (props) => {
  const { className, section, isLoading, isOpenDefault } = props;

  const [isOpen, setIsOpen] = useState(isOpenDefault || false);

  if (isLoading) {
    return <Spinner loading size={SpinnerSize.large} />;
  }

  if (!section || !section.activities.length) {
    return null;
  }

  return (
    <div className={clsx(s.ActivitiesSection, className)}>
      <div className={s.ActivitiesSection__head} onClick={() => setIsOpen((isOpen) => !isOpen)}>
        <div className={s.ActivitiesSection__title}>{section.title}</div>

        <ClickableIcon
          className={clsx(isOpen && s.ActivitiesSection__icon_opened)}
          iconProps={{ icon: IconActionArrowKeyboardBottom, size: IconSize.medium }}
        />
      </div>

      {isOpen && <Activities activities={section.activities} />}
    </div>
  );
};
