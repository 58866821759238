import clsx from 'clsx';
import s from './QuizTableRow.module.scss';
import { ReactFCC } from '../../../../../../utils/ReactFCC';
import React, { useState } from 'react';
import { IconActionArrowKeyboardRight } from '@hse-design/react';
import { formatQuizDate, formatQuizMinMax, formatQuizTotalGrade } from '../../utils/quizTableFormat';
import { QuizTableRowAttempt } from './QuizTableRowAttempt';
import { GradeTableCellType, GradeTableData } from '../../../GradeTable';
import { QuizTableQuizBaseProps } from '../types';

export interface QuizTableRowQuizProps extends QuizTableQuizBaseProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const QuizTableRowQuiz: ReactFCC<QuizTableRowQuizProps> = (props) => {
  const { className, quiz } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr className={clsx(s.QuizTableRow_quizRow, className)}>
        <td className={s.QuizTableRow__expandCell} onClick={() => setIsOpen((value) => !value)}>
          <IconActionArrowKeyboardRight
            className={clsx(s.QuizTableRow__chevron, isOpen && s.QuizTableRow__chevron_open)}
            size={'small'}
          />
        </td>
        <td>{quiz.quizName}</td>
        <td>{quiz.attempts.length}</td>
        <td>{formatQuizDate(quiz.lastAttemptDate)}</td>
        <td>{formatQuizTotalGrade(quiz.grade)}</td>
        <td>{formatQuizMinMax(quiz.gradeTotalMin, quiz.gradeTotalMax)}</td>
      </tr>

      {isOpen && (
        <>
          {quiz.attempts.map((attempt, index) => (
            <QuizTableRowAttempt
              attempt={attempt}
              index={index + 1}
              gradeAttemptMin={quiz.gradeAttemptMin}
              gradeAttemptMax={quiz.gradeAttemptMax}
              key={index}
            />
          ))}

          <tr className={clsx(s.QuizTableRow_attemptRow, s.QuizTableRow_total, className)}>
            <td></td>
            <GradeTableData type={GradeTableCellType.bold}>Итоговая оценка</GradeTableData>
            <td></td>
            <td>{formatQuizDate(quiz.lastAttemptDate)}</td>
            <GradeTableData type={GradeTableCellType.bold}>{formatQuizTotalGrade(quiz.grade)}</GradeTableData>
            <GradeTableData type={GradeTableCellType.bold}>
              {formatQuizMinMax(quiz.gradeTotalMin, quiz.gradeTotalMax)}
            </GradeTableData>
          </tr>
        </>
      )}
    </>
  );
};
