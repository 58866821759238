import React from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import { Size, Space } from '@hse-design/react';
import { AsideLayout } from 'routes/_layouts/AsideLayout/AsideLayout';
import { UserGreeting } from './UserGreeting/UserGreeting';
import { mediaQuery, useMediaQueryResult } from 'hooks/useMediaQueryResult';
import { UpcomingDisciplines } from './UpcomingDisciplines/UpcomingDisciplines';
import s from './GreetingLayout.module.scss';

export const GreetingLayout: ReactFCC = (props) => {
  const { children } = props;
  const isDesktop = useMediaQueryResult(mediaQuery.desktopSmallUp);

  return (
    <AsideLayout
      beforeContainerChildren={!isDesktop && <UserGreeting />}
      afterContainerChildren={!isDesktop && <UpcomingDisciplines />}
      asideChildren={
        <>
          <UserGreeting />
          <Space vertical size={Size.large_2x} />
          <UpcomingDisciplines />
        </>
      }
      asideContentClassName={s.GreetingLayout__asideContent}>
      {children}
    </AsideLayout>
  );
};
