import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ReactFCC } from 'utils/ReactFCC';
import { useSiteInfo } from 'store/siteInfo';
import { Sidebar } from './Sidebar';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Button, Error, IconActionLogout, SpinnerSize } from '@hse-design/react';
import s from './UserLayout.module.scss';
import { useAuth } from 'store/auth';
import { Spinner } from 'components/Spinner/Spinner';
import { userList } from 'store/userList';
import { NoAccessPage } from 'routes/login';
import { MoodleMessages } from '../../../components/MoodleMessages';

export const UserLayout: ReactFCC = (props) => {
  const { children } = props;
  const [isNavigationMenuActive, setNavigationMenuActive] = useState(false);
  const { data, isLoading, error } = useSiteInfo();
  const { logOut } = useAuth();

  const toggleNavigationMenu = useCallback(
    (value?: boolean) => {
      setNavigationMenuActive((active) => (value !== undefined ? value : !active));
    },
    [setNavigationMenuActive]
  );

  const { pathname } = useLocation();

  useEffect(() => {
    setNavigationMenuActive(false);
  }, [pathname]);

  const [isMessagesLoading, setMessagesLoading] = useState(true);
  const [isMessagesOpen, setMessagesOpen] = useState(false);

  const toggleMessages = () => {
    if (!isMessagesLoading) {
      setMessagesOpen((value) => !value);
    }
  };

  if (!data) {
    if (isLoading) {
      return (
        <div className={s.UserLayoutState}>
          <Spinner indeterminate size={SpinnerSize.XL} />
        </div>
      );
    } else if (error) {
      return (
        <div className={s.UserLayoutState}>
          <Error
            title="Не удалось загрузить информацию о пользователе"
            description={error}
            action={
              <Button onClick={() => logOut()} leftIcon={IconActionLogout}>
                Выйти
              </Button>
            }
          />
        </div>
      );
    } else {
      return null;
    }
  }

  // TODO: сделать заглушку чище (с полноценным роутингом)
  if (!userList.includes(data.username)) {
    return <NoAccessPage />;
  }

  return (
    <div className={s.UserLayout}>
      <Header
        className={s.UserLayout__header}
        isNavigationMenuActive={isNavigationMenuActive}
        toggleNavigationMenu={toggleNavigationMenu}
        toggleMessages={toggleMessages}
      />

      <Sidebar
        className={s.UserLayout__sidebar}
        isNavigationMenuActive={isNavigationMenuActive}
        toggleNavigationMenu={toggleNavigationMenu}
      />

      <MoodleMessages
        isOpen={isMessagesOpen}
        toggleOpen={toggleMessages}
        isMessagesLoading={isMessagesLoading}
        setMessagesLoading={setMessagesLoading}
      />

      <div className={s.UserLayout__content}>
        <div className={s.UserLayout__outlet}>{children || <Outlet />}</div>

        <Footer className={s.UserLayout__footer} />
      </div>
    </div>
  );
};
