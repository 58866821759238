// @hse-design – https://design-system.hse.ru/v1.5.0/installation
// Библиотека использует .focus-visible, для которого необходим полифилл
import 'focus-visible';
// Стили для ресета дефолтных
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
// Подключение шрифтов, которые использует библиотека
import '@hse-design/core/src/fonts.scss';
// [Опционально] Подключение глобальных стилей
import '@hse-design/core/lib/global.css';
// Подключение стилей компонентов
import '@hse-design/core/lib/index.css';
// Подключение стилей, специфичных для React
import '@hse-design/core/lib/react/index.css';

// Стили проекта
import './styles/index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// замена для react-helmet с (почти) тем же API, которая не использует внутри устаревшие / не-безопасные библиотеки
import { HelmetProvider } from 'react-helmet-async';

import { isTokenRefreshFrame } from './utils/isTokenRefreshFrame';

// context providers
import { AuthProvider } from './store/auth';
import { AxiosClientProvider } from './store/axiosClient';
import { SiteInfoProvider } from './store/siteInfo';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (!isTokenRefreshFrame()) {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider>
          <AuthProvider>
            <AxiosClientProvider>
              <SiteInfoProvider>
                <App />
              </SiteInfoProvider>
            </AxiosClientProvider>
          </AuthProvider>
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
