import React from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import clsx from 'clsx';
import { Divider } from '@hse-design/react';
import { ActivityCard, IActivityCardProps } from '../ActivityCard/ActivityCard';
import { ActivitiesVariant } from 'components/Activities/types';
import s from './Activity.module.scss';

export interface IActivityProps {
  title: string;
  items: IActivityCardProps[];
  variant?: ActivitiesVariant;
}

export const Activity: ReactFCC<IActivityProps> = (props) => {
  const { title, items, variant } = props;

  return (
    <div className={clsx(s.Activity, s[`Activity_variant_${variant}`])}>
      <header className={s.Activity__header}>
        {title}
        {variant === ActivitiesVariant.DEFAULT && <Divider className={s.Activity__headerDivider} />}
      </header>

      {items.length > 0 && (
        <div className={s.Activity__cards}>
          {items.map((item, idx) => (
            <ActivityCard key={idx} {...item} variant={variant} />
          ))}
        </div>
      )}
    </div>
  );
};
