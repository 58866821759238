import React from 'react';
import { LinkVariant } from '@hse-design/react';
import { CalendarEventType } from '../calendar.types';
import { formatDate } from '../../../utils/date';
import { Anchor, EAnchorSize } from '../../Anchor/Anchor';
import { Modal, ModalProps } from '../../Modal/Modal';
import { Badge } from '../../Badge/Badge';
import s from './MonthCalendar.module.scss';

export interface EventModalProps extends Pick<ModalProps, 'isOpen' | 'onCloseClick'> {
  event?: CalendarEventType;
}

export const EventModal = ({ event, ...modalProps }: EventModalProps) => {
  const { name, description, type, date_start, link } = event || {};

  return (
    <Modal className={s.EventModal} {...modalProps}>
      {event && (
        <div className={s.EventModalEvent}>
          <div className={s.EventModalEvent__name}>{name}</div>
          <div className={s.EventModalEvent__type}>
            <Badge>{type}</Badge>
          </div>
          {description && <div className={s.EventModalEvent__description}>{description}</div>}
          <div className={s.EventModalEvent__date}>{formatDate(date_start, 'EEEE, dd MMMM yyyy, HH:mm')}</div>
          {link && (
            <div className={s.EventModalEvent__actions}>
              <Anchor
                href={link}
                target={'_blank'}
                rel={'noopener noreferrer'}
                size={EAnchorSize.SMALL}
                variant={LinkVariant.secondary}>
                Перейти к событию
              </Anchor>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
