import React from 'react';
import clsx from 'clsx';
import { useSiteInfoData } from '../../../../store/siteInfo';
import s from './UserGreeting.module.scss';

export function UserGreeting({ className }: { className?: string }) {
  const siteInfoData = useSiteInfoData();
  return (
    <div className={clsx(s.UserGreeting, className)}>
      <img alt={siteInfoData.fullname} src={siteInfoData.userpictureurl} className={s.UserGreeting__avatar} />
      <div>
        <div className={s.UserGreeting__greeting}>Добро пожаловать,</div>
        <div className={s.UserGreeting__name}>{`${siteInfoData.fullname}!`}</div>
      </div>
    </div>
  );
}
