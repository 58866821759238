import React from 'react';
import clsx from 'clsx';
import { Link, LinkComponentProps, LinkDefaultComponentType, LinkProps, LinkVariant } from '@hse-design/react';
import s from './Anchor.module.scss';

export enum EAnchorSize {
  LARGE,
  MEDIUM,
  SMALL
}

export type TAnchorExtended = {
  size?: EAnchorSize;
};

export function Anchor<
  ComponentType extends React.ElementType<LinkComponentProps & TAnchorExtended> = LinkDefaultComponentType
>(props: LinkProps<ComponentType> & TAnchorExtended) {
  const { className, children, variant = LinkVariant.primary, size, ...rest } = props;

  return (
    <Link
      {...rest}
      className={clsx(s.Anchor, className, {
        [s.Anchor_primary]: variant === LinkVariant.primary,
        [s.Anchor_secondary]: variant === LinkVariant.secondary,
        [s.Anchor_large]: size === EAnchorSize.LARGE,
        [s.Anchor_medium]: size === EAnchorSize.MEDIUM,
        [s.Anchor_small]: size === EAnchorSize.SMALL
      })}
      tabIndex={0}>
      {children}
    </Link>
  );
}
