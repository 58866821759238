import React, { useMemo } from 'react';

import clsx from 'clsx';

import { EIconsPatterns, ICONS_PATTERNS_MAP } from 'components/IconsFactory/Icons';
import { VectorIconMulticolor } from 'components/IconsFactory/IconsFactory';
import s from './DecorMain.module.scss';
import { random, uniqueId } from 'lodash-es';
import { mediaQuery, useMediaQueryResult } from '../../hooks/useMediaQueryResult';
import { ReactFCC } from 'utils/ReactFCC';

type TDecorMainClassNames = {
  classNameCell?: string;
  classNameRow?: string;
};

export type TRowPattern = {
  patterns: EIconsPatterns[];
  row: number /* home starts from 0 */;
};

type TDecorMainCommon = {
  className?: string;
  classNames?: TDecorMainClassNames;
  rows?: number;
  rowsPatterns?: TRowPattern[];
};

type TDecorMainVertical = TDecorMainCommon & {
  isVertical: true;
};

type TDecorMainHorizontal = TDecorMainCommon & {
  isVertical?: never;
};

export type TDecorMain = TDecorMainVertical | TDecorMainHorizontal;

const DecorMainCmp: ReactFCC<TDecorMain> = (props) => {
  const { className, classNames, isVertical, rows = 1, rowsPatterns } = props;

  const { classNameCell, classNameRow } = classNames || {};

  const isDesktopMedium = useMediaQueryResult(mediaQuery.desktopMediumDown);
  const isDesktopSmall = useMediaQueryResult(mediaQuery.desktopSmallDown);
  const isTabletLarge = useMediaQueryResult(mediaQuery.tabletLargeDown);
  const isTabletSmall = useMediaQueryResult(mediaQuery.tabletSmallDown);
  const isMobileLarge = useMediaQueryResult(mediaQuery.mobileLargeDown);

  const cellsCount = useMemo(() => {
    if (isVertical) {
      let count = 0;
      rowsPatterns?.forEach((row) => {
        if (row.patterns.length > count) {
          count = row.patterns.length;
        }
      });

      return count;
    }

    let count = 15;

    if (isDesktopMedium) {
      count = 11;
    }

    if (isDesktopSmall) {
      count = 10;
    }

    if (isTabletLarge) {
      count = 9;
    }

    if (isTabletSmall) {
      count = 6;
    }

    if (isMobileLarge) {
      count = 5;
    }

    return count;
  }, [isDesktopMedium, isDesktopSmall, isTabletLarge, isTabletSmall, isMobileLarge, isVertical, rowsPatterns]);

  return (
    <div className={clsx(s.DecorMain__container, isVertical && s.DecorMain__container_vertical, className)}>
      {Array(rows)
        .fill(null)
        .map((_, indexRow) => (
          <div
            className={clsx(s.DecorMain__row, isVertical && s.DecorMain__row_vertical, classNameRow)}
            key={uniqueId(`DecorMain-row-${indexRow}`)}>
            {Array(cellsCount)
              .fill(null)
              .map((__, indexCell) => {
                let patternId: EIconsPatterns;

                const pattern = rowsPatterns?.find((item) => item.row === indexRow)?.patterns?.[indexCell];

                if (pattern) {
                  patternId = pattern;
                } else {
                  patternId =
                    EIconsPatterns[
                      `PATTERN_SQUARE${random(1, Object.keys(ICONS_PATTERNS_MAP).length)}` as EIconsPatterns
                    ];
                }

                return (
                  <div
                    className={clsx(s.DecorMain__cell, classNameCell)}
                    key={uniqueId(`DecorMain-cell-${indexRow}-${indexCell})`)}>
                    <VectorIconMulticolor icon={patternId} />
                  </div>
                );
              })}
          </div>
        ))}
    </div>
  );
};

export const DecorMain = React.memo(DecorMainCmp);
