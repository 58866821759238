import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Error, SpinnerSize } from '@hse-design/react';
import { Spinner } from 'components/Spinner/Spinner';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { useGradesData } from 'routes/grades/api/useGradesData';
import s from './GradesPage.module.scss';
import { Tabs } from 'components/Tabs/Tabs';
import { Grade, DisciplineTable } from './tables/DisciplineTable';
import { RatingTable } from './tables/RatingTable';
import { rating } from './api/rating.mock';
import { QuizTable } from './tables/QuizTable';

export function GradesPage() {
  const { grades, isLoading, error } = useGradesData();

  const title = 'Оценки и достижения';

  return (
    <main>
      <Helmet title={title} />
      <PageHeader className={s.GradesPage__header} title={title} />

      <GradesPageContent error={error} isLoading={isLoading} grades={grades} />
    </main>
  );
}

const GradesPageContent = ({
  error,
  isLoading,
  grades
}: {
  error: string | undefined | null;
  isLoading: boolean;
  grades: Grade[] | undefined;
}) => {
  const [selectedTable, setSelectedTable] = useState('grades');

  if (error) {
    return <Error title="Не удалось загрузить информацию об оценках" description={error} />;
  }

  if (isLoading) {
    return <Spinner className={s.GradesPage__spinner} loading size={SpinnerSize.large} />;
  }

  return (
    <div className={s.GradesPage__content}>
      <Tabs
        items={[
          { label: 'Оценки', value: 'grades' },
          { label: 'Рейтинг', value: 'rank' },
          { label: 'Тесты', value: 'quiz' }
        ]}
        value={selectedTable}
        onChange={setSelectedTable}
      />
      {selectedTable === 'grades' ? (
        <DisciplineTable grades={grades} />
      ) : selectedTable === 'rank' ? (
        <RatingTable rating={rating} />
      ) : (
        <QuizTable />
      )}
    </div>
  );
};
