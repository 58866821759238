import { ICourse as ICourseEnrol } from 'store/moodleAPI/moodleTypes/_functions/core_enrol_get_users_courses';
import { ICourse as ICourseByField } from 'store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';
import { TDisciplineCard } from './DisciplineCards/DisciplineCard/DisciplineCard';
import { ICourseByTimelineClassification } from '../../store/moodleAPI/moodleTypes/_functions/core_course_get_enrolled_courses_by_timeline_classification';

/*

Пример объекта курса из ответа moodle
-------------------------------------

category: 80425
completed: false
completionhascriteria: false
completionusertracked: true
displayname: "Историческая наука XXI века (2022/2023 модули: 2,3)"
enablecompletion: true
enddate: 1680382800
format: "topics"
fullname: "Историческая наука XXI века (2022/2023 модули: 2,3)"
hidden: false
id: 484601
idnumber: "6525263645_1"
isfavourite: false
lang: ""
lastaccess: 1660135417
marker: 0
overviewfiles: Array []
progress: null
shortname: "6525263645_1"
showgrades: true
startdate: 1667163600
summary: ""
summaryformat: 1
visible: 1

 */

export const createCoursesList = (
  courses: ICourseEnrol[] | ICourseByField[] | ICourseByTimelineClassification[]
): TDisciplineCard[] =>
  courses.map((course) => ({
    name: course.fullname,
    id: course.id
  }));
