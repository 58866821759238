import { useMoodleAPILazyQuery } from '../../../../../store/moodleAPI/useMoodleAPILazyQuery';
import { useEnrolUserCoursesData } from '../../../../../store/moodleAPI/useEnrolUserCoursesData';
import { useCallback, useMemo, useState } from 'react';
import { useMoodleAPI } from '../../../../../store/moodleAPI/useMoodleAPI';
import {
  ModQuizGetQuizzesByCoursesData,
  ModQuizGetQuizzesByCoursesQuery
} from '../../../../../store/moodleAPI/moodleTypes/_functions/mod_quiz_get_quizzes_by_courses';
import {
  ModQuizGetUserAttemptsData,
  ModQuizGetUserAttemptsQuery
} from '../../../../../store/moodleAPI/moodleTypes/_functions/mod_quiz_get_user_attempts';
import { IAttempt } from '../../../../../store/moodleAPI/moodleTypes/Attempt';
import { last } from 'lodash-es';
import { EMDASH } from '@proscom/ui-utils';
import {
  GradereportUserGetGradeItemsData,
  GradereportUserGetGradeItemsQuery,
  IGradeItem
} from '../../../../../store/moodleAPI/moodleTypes/_functions/gradereport_user_get_grade_items';
import { useSiteInfoData } from '../../../../../store/siteInfo';

export type QuizGradeAttempt = {
  attemptId: number;
  date?: Date;
  grade: number | null;
};

export type QuizGradeQuiz = {
  quizId: number;
  quizName: string;
  attemptsCount: number;
  lastAttemptDate?: Date;
  grade: number | null;
  gradeAttemptMin: number | null;
  gradeAttemptMax: number | null;
  gradeTotalMin: number | null;
  gradeTotalMax: number | null;
  attempts: QuizGradeAttempt[];
};

export type QuizGrade = {
  courseId: number;
  courseName: string;
  quizzes: QuizGradeQuiz[];
};

export const useQuizGrades = () => {
  const requestMoodleApi = useMoodleAPILazyQuery();

  const [courseGradeItems, setCourseGradeItems] = useState<{ [courseId: number]: IGradeItem[] | undefined }>({});
  const [courseGradeItemsLoadingId, setCourseGradeItemsLoadingId] = useState<number | null>(null);

  const [quizAttempts, setQuizAttempts] = useState<{ [quizId: number]: IAttempt[] }>({});

  const { coursesData, isLoading: coursesIsLoading } = useEnrolUserCoursesData();

  const { data: quizzesData, isLoading: isQuizzesLoading } = useMoodleAPI<
    ModQuizGetQuizzesByCoursesQuery,
    ModQuizGetQuizzesByCoursesData
  >(
    coursesData.length !== 0 && {
      courseids: coursesData.map((course) => course.id),
      wsfunction: 'mod_quiz_get_quizzes_by_courses'
    }
  );

  const quizzes = useMemo(() => quizzesData?.quizzes || [], [quizzesData]);

  const coursesWithQuizzes = useMemo(
    () => coursesData.filter((course) => quizzes.some((quiz) => quiz.course === course.id)),
    [coursesData, quizzes]
  );

  const grades: QuizGrade[] = useMemo(() => {
    if (!coursesWithQuizzes) {
      return [];
    }

    return coursesWithQuizzes.map((course) => ({
      courseId: course.id,
      courseName: course.displayname || EMDASH,
      quizzes: quizzes
        .filter((quiz) => quiz.course === course.id && quizAttempts[quiz.id])
        .map((quiz) => {
          const quizGradeItem = courseGradeItems[course.id]?.find((item) => item.iteminstance === quiz.id);
          const quizGradeCategory = courseGradeItems[course.id]?.find(
            (item) => item.itemtype === 'category' && item.iteminstance === quizGradeItem?.categoryid
          );

          const attempts = (quizAttempts[quiz.id] || []).filter((attempt) => typeof attempt.sumgrades === 'number');

          const lastAttempt = last(attempts);

          return {
            quizId: quiz.id,
            quizName: quiz.name,
            attemptsCount: attempts.length,
            lastAttemptDate:
              lastAttempt && lastAttempt.timefinish ? new Date(lastAttempt.timefinish * 1000) : undefined,
            grade: quizGradeCategory?.graderaw ?? null,
            gradeAttemptMin: quizGradeItem?.grademin ?? null,
            gradeAttemptMax: quizGradeItem?.grademax ?? null,
            gradeTotalMin: quizGradeCategory?.grademin ?? null,
            gradeTotalMax: quizGradeCategory?.grademax ?? null,
            attempts: attempts.map((attempt) => ({
              attemptId: attempt.id!,
              date: attempt.timefinish ? new Date(attempt.timefinish * 1000) : undefined,
              grade: attempt.sumgrades ?? null
            }))
          };
        })
    }));
  }, [coursesWithQuizzes, quizzes, courseGradeItems, quizAttempts]);

  const { userid } = useSiteInfoData();

  const loadCourseGradeItems = useCallback(
    async (courseId: number) => {
      if (courseGradeItems[courseId]) {
        return;
      }

      setCourseGradeItemsLoadingId(courseId);

      try {
        const gradeItemsDataPromise = requestMoodleApi<
          GradereportUserGetGradeItemsQuery,
          GradereportUserGetGradeItemsData
        >({
          wsfunction: 'gradereport_user_get_grade_items',
          courseid: courseId,
          userid: userid
        }).then(({ data: gradeItemsData }) => {
          if (!gradeItemsData) {
            return;
          }

          return gradeItemsData.usergrades[0]?.gradeitems || [];
        });

        const course = coursesWithQuizzes.find((course) => course.id === courseId);
        const quizzesForAttemptsLoad = quizzes.filter((quiz) => quiz.course === course?.id);

        const attemptsPromises = quizzesForAttemptsLoad.map(async (quiz) => {
          if (quizAttempts[quiz.id]) {
            return { quizId: quiz.id, attempts: quizAttempts[quiz.id] };
          }

          const { data } = await requestMoodleApi<ModQuizGetUserAttemptsQuery, ModQuizGetUserAttemptsData>({
            wsfunction: 'mod_quiz_get_user_attempts',
            quizid: quiz.id,
            status: 'all'
          });

          const attempts = data?.attempts || [];

          return { quizId: quiz.id, attempts };
        });

        const attemptsDataPromise = Promise.all(attemptsPromises).then((values) => {
          const attemptsData: { [key: number]: IAttempt[] } = {};

          values.forEach((value) => {
            attemptsData[value.quizId] = value.attempts;
          });

          return attemptsData;
        });

        const [gradeItemsData, attemptsData] = await Promise.all([gradeItemsDataPromise, attemptsDataPromise]);
        setCourseGradeItems((items) => ({ ...items, [courseId]: gradeItemsData }));
        setQuizAttempts((attempts) => ({ ...attempts, ...attemptsData }));
      } finally {
        setCourseGradeItemsLoadingId(null);
      }
    },
    [courseGradeItems, coursesWithQuizzes, quizAttempts, quizzes, requestMoodleApi, userid]
  );

  const isLoading = coursesIsLoading || isQuizzesLoading || !quizzesData;

  return {
    grades,
    isLoading,
    courseGradeItemsLoadingId,
    loadCourseGradeItems
  };
};
