import React, { ReactElement } from 'react';

import {
  EIconsMulticolor,
  EIconsPlain,
  ICONS_PLAIN_MAP,
  EIconsPatterns,
  ICONS_PATTERNS_MAP,
  ICONS_MULTICOLOR_MAP
} from './Icons';
import { ReactFCC } from 'utils/ReactFCC';

type TVectorIconPropsCommon = {
  containerClassName?: string;
};

export type TVectorIconPlainProps = TVectorIconPropsCommon & {
  icon: EIconsPlain;
};

export const VectorIconPlain: ReactFCC<TVectorIconPlainProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { containerClassName, icon, ...rest } = props;

  return <div className={containerClassName}>{React.cloneElement(ICONS_PLAIN_MAP[icon], { ...rest })}</div>;
};

const isPattern = (icon: EIconsMulticolor | EIconsPatterns): icon is EIconsPatterns => icon in EIconsPatterns;

export type TVectorIconMulticolorProps = TVectorIconPropsCommon & {
  icon: EIconsMulticolor | EIconsPatterns;
};

export const VectorIconMulticolor: ReactFCC<TVectorIconMulticolorProps & React.SVGProps<SVGSVGElement>> = (props) => {
  const { containerClassName, icon, ...rest } = props;

  let iconCmp: ReactElement | null = null;

  if (isPattern(icon)) {
    iconCmp = ICONS_PATTERNS_MAP[icon];
  } else {
    iconCmp = ICONS_MULTICOLOR_MAP[icon];
  }

  if (!iconCmp) {
    return null;
  }

  return <div className={containerClassName}>{React.cloneElement(iconCmp, { ...rest })}</div>;
};
