import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import s from './Badge.module.scss';

export enum BadgeVariant {
  Primary = 'primary'
}

export interface BadgeProps extends PropsWithChildren {
  className?: string;
  variant?: BadgeVariant;
}

export const Badge = ({ className, variant = BadgeVariant.Primary, children }: BadgeProps) => {
  return <div className={clsx(s.Badge, s[`Badge_${variant}`], className)}>{children}</div>;
};
