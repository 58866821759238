import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Error, Paragraph, SpinnerSize } from '@hse-design/react';
import clsx from 'clsx';
import { ReactFCC } from 'utils/ReactFCC';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { Spinner } from 'components/Spinner/Spinner';
import { useQuizData } from 'routes/quiz/api/useQuizData';
import { QuizCompletionState } from 'routes/quiz/QuizCompletionState/QuizCompletionState';
import { CompletionState } from 'store/moodleAPI/moodleTypes/Module';
import { QuizQuestions } from 'routes/quiz/QuizQuestions/QuizQuestions';
import { useStartQuiz } from 'routes/quiz/api/useStartQuiz';
import { QuizAttempts } from './QuizAttempts/QuizAttempts';
import s from './QuizPage.module.scss';
import { getQuizPageBreadcrumbs } from './quizPage-breadcrumbs';
import { Breadcrumbs } from '../../components/Breadcrumbs';

export const QuizPage: ReactFCC = () => {
  const { quizId } = useParams<{
    quizId?: string;
  }>();
  const {
    quiz,
    attempts,
    lastAttemptId,
    accessAttemptReview,
    lesson,
    course,
    nextActivity,
    isLoadingModule,
    isLoadingCompletionState,
    error,
    clearData: clearQuizData
  } = useQuizData(quizId);

  const {
    startQuiz,
    clearData,
    startAttemptData,
    isLoading: startQuizIsLoading,
    error: startQuizError
  } = useStartQuiz(quiz);

  const startQuizHandler = useCallback(() => {
    startQuiz();
  }, [startQuiz]);

  const finishQuizHandler = useCallback(() => {
    clearQuizData();
    clearData();
  }, [clearData, clearQuizData]);

  const breadcrumbs = useMemo(() => {
    if (!quiz || !lesson || !course) return [];
    return getQuizPageBreadcrumbs({
      course: {
        id: course.id,
        name: `Дисциплина ${course.displayname}`
      },
      lesson: {
        id: lesson.id,
        name: lesson.title
      },
      quiz: {
        id: quiz.id,
        name: quiz.name
      }
    });
  }, [quiz, lesson, course]);

  if (error) {
    return <Error title="Не удалось загрузить информацию" description={error} />;
  }

  const quizTitle = quiz?.name;

  const isLoading = isLoadingModule || isLoadingCompletionState;

  const quizIsInProgress = !!startAttemptData;

  return (
    <main className={clsx(s.QuizPage__container, isLoading && s.QuizPage__container_centered)}>
      {!isLoading ? (
        <>
          <Helmet title={quizTitle} />

          <div className={s.DisciplinePage__headerContainer}>
            {lesson?.name && <PageHeader className={s.QuizPage__header} title={lesson.name} />}

            {quizTitle && <Paragraph className={s.QuizPage__quizTitle}>{quizTitle}</Paragraph>}

            <Breadcrumbs className={s.QuizPage__bc} items={breadcrumbs} />
          </div>

          {attempts.length !== 0 && <QuizAttempts className={s.QuizPage__attempts} attempts={attempts} />}

          {!quizIsInProgress && quiz && (
            <QuizCompletionState
              isLoading={isLoadingCompletionState}
              buttonSpinner={startQuizIsLoading}
              error={startQuizError}
              nextActivityHref={
                quiz?.completiondata?.state === CompletionState.CompletePass ? nextActivity?.href : null
              }
              quiz={quiz}
              lesson={lesson}
              lastAttemptId={lastAttemptId}
              accessAttemptReview={accessAttemptReview}
              onStartQuiz={startQuizHandler}
            />
          )}

          {quizIsInProgress && quiz && startAttemptData && (
            <QuizQuestions
              instanceId={String(quiz.instance)}
              inProgressAttempt={startAttemptData}
              onFinishQuiz={finishQuizHandler}
            />
          )}
        </>
      ) : (
        <Spinner loading size={SpinnerSize.XL} />
      )}
    </main>
  );
};
