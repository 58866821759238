import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import {
  ModQuizGetAttemptDataData,
  ModQuizGetAttemptDataQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_data';
import { IAttempt } from 'store/moodleAPI/moodleTypes/Attempt';
import {
  ModQuizViewAttemptData,
  ModQuizViewAttemptQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_view_attempt';

export const useQuizAttempt = (instanceId: string, currentQuestionPage: number | null, attempt: IAttempt) => {
  const currentPage = currentQuestionPage ?? attempt?.currentpage ?? 0;

  const {
    data: attemptData,
    isLoading: attemptDataIsLoading,
    error: attemptDataError
  } = useMoodleAPI<ModQuizGetAttemptDataQuery, ModQuizGetAttemptDataData>({
    wsfunction: 'mod_quiz_get_attempt_data',
    attemptid: String(attempt.id),
    page: currentPage
  });

  const { error: viewAttemptError } = useMoodleAPI<ModQuizViewAttemptQuery, ModQuizViewAttemptData>(
    attemptData &&
      !!attempt?.id && {
        wsfunction: 'mod_quiz_view_attempt',
        attemptid: attempt.id,
        page: currentPage
      }
  );

  const questions = attemptData?.questions || [];

  const isLoading = !attemptData || attemptDataIsLoading;

  const error = attemptDataError || viewAttemptError;

  const attemptPage = questions[currentPage]?.page !== undefined ? questions[currentPage]?.page : undefined;
  const layoutArray = attemptData?.attempt?.layout?.split(',0').filter(Boolean);

  const attemptAllPages = layoutArray?.length || 0;

  return {
    isLoading,
    error,
    attemptData,
    questions,
    attemptPage,
    attemptAllPages
  };
};
