/**
 * Дублирует содержимое в переданном массиве
 * @typeParam T - Type of array items
 * @param {a: <T>[]} a - array
 * @param {n: number} n - replicates number
 */
export const replicateArr = <T extends unknown>(a: T[], n: number): T[] => {
  const newArr: T[] = [];

  Array(n)
    .fill(null)
    .forEach(() => newArr.push(...a));

  return newArr;
};
