import clsx from 'clsx';
import s from './QuizTable.module.scss';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { SpinnerSize } from '@hse-design/react';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { useQuizGrades } from './hooks/useQuizGrades';
import { QuizTableHeader } from './components';
import { QuizTableRow } from './components';
import { GradeTable } from '../GradeTable';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { QuizTableCard } from './components/QuizTableCard';

export interface QuizTableProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const QuizTable: ReactFCC<QuizTableProps> = (props) => {
  const { className } = props;

  const isMobile = useIsMobile();

  const { grades, isLoading, loadCourseGradeItems, courseGradeItemsLoadingId } = useQuizGrades();

  return (
    <>
      <GradeTable className={clsx(s.QuizTable, className)}>
        <caption>Оценки за тестирование</caption>
        <QuizTableHeader />
        <tbody>
          {grades.map((grade, index) =>
            isMobile ? (
              <QuizTableCard
                grade={grade}
                loadCourseGradeItems={loadCourseGradeItems}
                isLoading={courseGradeItemsLoadingId === grade.courseId}
                key={index}
              />
            ) : (
              <QuizTableRow
                grade={grade}
                loadCourseGradeItems={loadCourseGradeItems}
                isLoading={courseGradeItemsLoadingId === grade.courseId}
                key={index}
              />
            )
          )}
        </tbody>

        <td className={s.QuizTable__after} colSpan={6}>
          {isLoading && <Spinner className={s.QuizTable__spinner} loading size={SpinnerSize.large} />}
        </td>
      </GradeTable>
    </>
  );
};
