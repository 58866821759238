import React from 'react';
import { singleton } from '@proscom/ui-utils';
import { parseAuthFragment } from 'utils/parseAuthFragment';
import { LOCAL_STORAGE_KEY_AUTH } from '../localStorageKeys';
import { AuthContext, IAuthContext, IAuthData } from './AuthContext';
import { EAuthError } from './authErrors';
import { externalAuthURL } from './externalAuthURL';

// парсит данные об авторизации из localStorage
// TODO: верифицировать формат authData.
const parseAuthData = (authData: string) => JSON.parse(authData) as IAuthData;

export class AuthProvider extends React.Component<{ children: React.ReactNode }, IAuthContext> {
  isLoggedIn = () => !!this.state.authData.accessToken;

  setToken = (accessToken: string | null, idToken: string | null = null, expires: number = 0) => {
    this.setState({ authData: { accessToken, idToken, expires } });
    // сохраняет новое значение в localStorage
    this.authData = JSON.stringify({ accessToken, idToken, expires });
    localStorage.setItem(LOCAL_STORAGE_KEY_AUTH, this.authData);
  };

  getToken = async () => {
    const { expires } = this.state.authData;
    // автоматически обновляет истекший токен
    if (expires && expires < Date.now() - 60 * 1000) return this.refreshAccessToken();
    return this.state.authData.accessToken;
  };

  logOut = (error?: EAuthError) => {
    if (error) {
      this.setToken(null);
      this.setState({ error });
    } else {
      const { idToken } = this.state.authData;
      // полноценный выход из ADFS
      window.location.href =
        'https://auth.hse.ru/adfs/oauth2/logout' +
        `?id_token_hint=${idToken}` +
        `&post_logout_redirect_uri=${window.location.origin}/auth`;
    }
  };

  // сырые данные из localStorage
  authData = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH);

  state: IAuthContext = {
    // пытаемся использовать данные из localStorage в качестве initialState
    authData: this.authData ? parseAuthData(this.authData) : { accessToken: null, idToken: null, expires: 0 },
    error: null,
    isLoggedIn: this.isLoggedIn,
    setToken: this.setToken,
    getToken: this.getToken,
    logOut: this.logOut
  };

  componentDidMount() {
    // подписка на изменения в localStorage, чтобы отслеживать изменения из других вкладок –
    // например, выход из системы
    window.addEventListener('storage', this.handleStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageChange);
  }

  handleStorageChange = () => {
    const authData = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH);
    if (authData && authData !== this.authData) {
      const { accessToken, idToken, expires } = parseAuthData(authData);
      this.setToken(accessToken, idToken, expires);
    }
  };

  // метод обновления токена в ADFS через скрытый iframe,
  // не требует наличия бэкенда / предварительного получения refreshToken
  refreshAccessToken = singleton<[], string>(
    () =>
      new Promise((resolve, reject) => {
        const iframe = document.createElement('iframe');

        iframe.style.position = 'absolute';
        iframe.style.visibility = 'hidden';
        iframe.style.top = '-100%';
        iframe.setAttribute('src', `${externalAuthURL}&prompt=none`);

        document.body.appendChild(iframe);

        iframe.onload = () => {
          // гарантируется событием load
          const contentWindow = iframe.contentWindow as Window;
          const { accessToken, idToken, expires, error } = parseAuthFragment(contentWindow.location.hash);

          if (!accessToken || !idToken || !expires) {
            this.logOut(EAuthError.ADFS_TOKEN_REFRESH_FAILED);
            reject(error);
          } else {
            this.setToken(accessToken, idToken, +expires);
            resolve(accessToken);
          }

          document.body.removeChild(iframe);
        };
      })
  );

  render() {
    return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
  }
}
