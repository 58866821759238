import { useCurators } from '../api/useCurators';
import s from './CuratorList.module.scss';

export interface CuratorListProps {
  curators?: ReturnType<typeof useCurators>;
}

export const CuratorList = ({ curators }: CuratorListProps) => {
  if ((curators?.length ?? 0) === 0) {
    return null;
  }

  return (
    <section>
      <h4 className={s.CuratorList__title}>Кураторы</h4>
      <ul>
        {curators!.map((c, i) => (
          <li key={i}>
            {c.fullname} {c.lastname} <a href={`mailto:${c.email}`}>({c.email})</a>
          </li>
        ))}
      </ul>
    </section>
  );
};
