import { IModule } from 'store/moodleAPI/moodleTypes/Module';

export const LESSON_MOD_NAME = 'lesson';
export const QUIZ_MOD_NAME = 'quiz';
export const RESOURCE_MOD_NAME = 'resource';
export const LABEL_MOD_NAME = 'label';

export const LECTURE_NAME = 'лекция';
export const SEMINAR_NAME = 'семинар';

export enum EActivityState {
  CLOSE = 'close',
  CHECK = 'check',
  PLAY = 'play',
  QUIZ = 'quiz'
}

export enum EActivityType {
  LECTURE = 'LECTURE',
  SEMINAR = 'SEMINAR',
  QUIZ = 'QUIZ',
  UNKNOWN = 'UNKNOWN'
}

export enum EFilterActivities {
  ISOLATED_TEXT = 'ISOLATED_TEXT'
}

export interface IActivitiesSection {
  title: string;
  activities: IActivities[];
}

export interface IActivities {
  title: string;
  items: IActivityItem[];
}

export interface IActivityItem extends IModule {
  title: string;
  state: EActivityState;
  href: string;
  type: EActivityType;
  quiz?: IModule;
  resource?: {
    name: string;
    url: string;
    size: number;
  } | null;
  labels?:
    | {
        description: string;
      }[]
    | null;
}
