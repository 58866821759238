import React, { useLayoutEffect, useMemo, useState } from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import { Paragraph, Select } from '@hse-design/react';
import { EQuestType, IQuizAnswer, IQuizQuestionProps } from 'routes/quiz/QuizQuestions/types';
import { QuizQuestionLayout } from 'routes/quiz/QuizQuestions/QuizQuestionLayout/QuizQuestionLayout';
import { useParseDropElements } from 'routes/quiz/QuizQuestions/utils/useParseDropElements';
import { useIsMounted } from 'hooks/useIsMounted';
import s from 'routes/quiz/QuizQuestions/QuestionTypes/SelectQuestion/SelectQuestion.module.scss';

const VALUE_CLASS_PREFIX = 'choice';

export const SelectQuestion: ReactFCC<IQuizQuestionProps> = (props) => {
  const { content, title, index, answers, setAnswers, questionType } = props;

  const [parseError, setParseError] = useState(false);

  const isMounted = useIsMounted();

  const dropElements = useParseDropElements(content);

  const { selectOptions, questions } = useMemo(() => {
    if (questionType === EQuestType.DDW_TO_SHARD) {
      const $answers = Array.from(content?.querySelectorAll('.draghome') ?? []).filter(
        ($answer) => $answer.textContent
      );

      const $dropInputs = Array.from(content?.querySelectorAll('.formulation > [type="hidden"]') ?? []).filter((el) =>
        el.getAttribute('id')
      );

      return {
        questions: dropElements.map((el, idx) => {
          let label = el.trim();
          if (label.slice(-1) !== ':') {
            label += ':';
          }

          return {
            label,
            name: $dropInputs[idx]?.getAttribute('name')
          };
        }),
        selectOptions: $answers.map(($answer, idx) => {
          const valueFromClass = Array.from($answer.classList)
            .filter((clsItem) => clsItem.includes(VALUE_CLASS_PREFIX))[0]
            ?.split(VALUE_CLASS_PREFIX)?.[1];

          return {
            label: $answer.textContent ?? '',
            value: valueFromClass || String(idx + 1)
          };
        })
      };
    } else {
      const $answers = content?.querySelectorAll('.answer tr');
      const $options = Array.from($answers?.[0]?.querySelectorAll('.select option') ?? []);
      const $questions = Array.from($answers ?? []);

      return {
        questions: $questions.map(($answer) => {
          let label = $answer?.querySelector('.text')?.textContent?.trim();
          if (label && label.slice(-1) !== ':') {
            label += ':';
          }

          return {
            label,
            name: $answer?.querySelector('.select')?.getAttribute('name')
          };
        }),
        selectOptions: $options
          .map(($answer) => ({
            label: $answer.textContent ?? '',
            value: $answer.getAttribute('value') ?? ''
          }))
          .filter((option) => option.value && option.value !== '0' && option.label !== 'Выберите...')
      };
    }
  }, [content, dropElements, questionType]);

  let error = parseError || !questions.length || !selectOptions.length;

  useLayoutEffect(() => {
    if (isMounted()) {
      return;
    }

    const initialAnswers: IQuizAnswer[] = questions.map(({ label, name }) => {
      if (!label || !name) {
        setParseError(true);

        return {
          name: '',
          label: '',
          value: ''
        };
      }

      return {
        name,
        label,
        value: ''
      };
    });

    setAnswers(initialAnswers);
  }, [questions, setAnswers, isMounted]);

  return (
    <QuizQuestionLayout error={error} title={title} index={index}>
      <div className={s.SelectQuestion__answers}>
        {answers.map(({ name, label, value }) => (
          <React.Fragment key={name}>
            <Paragraph>{label}</Paragraph>
            <Select
              className={s.SelectQuestion__select}
              value={value}
              options={selectOptions}
              onChangeOption={(newValue) => {
                setAnswers((prev) =>
                  prev.map((answer) => ({
                    ...answer,
                    value: answer.name === name && newValue?.value !== undefined ? newValue.value : answer.value
                  }))
                );
              }}
            />
          </React.Fragment>
        ))}
      </div>
    </QuizQuestionLayout>
  );
};
