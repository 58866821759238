import clsx from 'clsx';
import s from './QuizTableCard.module.scss';
import { ReactFCC } from '../../../../../../utils/ReactFCC';
import React, { useState } from 'react';
import { IconActionArrowKeyboardRight } from '@hse-design/react';
import { formatQuizDate, formatQuizMinMax, formatQuizTotalGrade } from '../../utils/quizTableFormat';
import { QuizTableCardAttempt } from './QuizTableCardAttempt';
import { GradeTableCellType, GradeTableData } from '../../../GradeTable';
import { QuizTableQuizBaseProps } from '../types';

export interface QuizTableCardQuizProps extends QuizTableQuizBaseProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const QuizTableCardQuiz: ReactFCC<QuizTableCardQuizProps> = (props) => {
  const { quiz } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr className={s.QuizTableCard_quizRow}>
        <td colSpan={3}>{quiz.quizName}</td>

        <GradeTableData type={GradeTableCellType.right}>
          <IconActionArrowKeyboardRight
            className={clsx(s.QuizTableCard__chevron, isOpen && s.QuizTableCard__chevron_open)}
            size={'small'}
            onClick={() => setIsOpen((value) => !value)}
          />
        </GradeTableData>
      </tr>

      <tr className={s.QuizTableCard_quizHeadRow}>
        <th>
          Попытки
          <div className={s.QuizTableCard_quizHeadRowValue}>{quiz.attempts.length}</div>
        </th>
        <th>
          Дата
          <div className={s.QuizTableCard_quizHeadRowValue}>{formatQuizDate(quiz.lastAttemptDate)}</div>
        </th>
        <th>
          Оценка
          <div className={s.QuizTableCard_quizHeadRowValue}>{formatQuizTotalGrade(quiz.grade)}</div>
        </th>
        <th>
          Диапазон
          <div className={s.QuizTableCard_quizHeadRowValue}>
            {formatQuizMinMax(quiz.gradeTotalMin, quiz.gradeTotalMax)}
          </div>
        </th>
      </tr>

      {isOpen &&
        quiz.attempts.map((attempt, index) => (
          <QuizTableCardAttempt
            attempt={attempt}
            index={index + 1}
            gradeAttemptMin={quiz.gradeAttemptMin}
            gradeAttemptMax={quiz.gradeAttemptMax}
            key={index}
          />
        ))}
    </>
  );
};
