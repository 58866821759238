import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import {
  ModQuizStartAttemptData,
  ModQuizStartAttemptQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_start_attempt';
import { useCallback, useEffect, useState } from 'react';
import { IModule } from 'store/moodleAPI/moodleTypes/Module';
import { IAttempt } from 'store/moodleAPI/moodleTypes/Attempt';

export const useStartQuiz = (
  quiz?:
    | (IModule & {
        inProgressAttempt: IAttempt | undefined | null;
      })
    | null
) => {
  const [startAttempt, setStartAttempt] = useState(false);
  const [startAttemptData, setStartAttemptData] = useState<IAttempt | null>(null);
  const [error, setError] = useState<string | null>();

  const {
    data,
    isLoading,
    error: moodleError,
    clearResult
  } = useMoodleAPI<ModQuizStartAttemptQuery, ModQuizStartAttemptData>(
    startAttempt &&
      quiz?.instance !== undefined &&
      !quiz.inProgressAttempt && {
        wsfunction: 'mod_quiz_start_attempt',
        quizid: quiz.instance
      }
  );

  useEffect(() => {
    if (data?.attempt.id) {
      setStartAttemptData(data.attempt);
    } else if (data?.warnings?.length) {
      setError(data.warnings.map(({ message }) => message).join(', '));
    }
  }, [data]);

  const startQuiz = useCallback(() => {
    if (quiz?.inProgressAttempt) {
      setStartAttemptData(quiz.inProgressAttempt);
    } else {
      setStartAttempt(true);
    }
  }, [quiz?.inProgressAttempt]);

  const clearData = useCallback(() => {
    setStartAttempt(false);
    setStartAttemptData(null);
    clearResult();
  }, [clearResult]);

  return {
    startQuiz,
    clearData,
    startAttemptData,
    isLoading,
    error: error || moodleError
  };
};
