import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from 'store/auth';
import { useScrollTopOnRouteChange } from 'hooks/useScrollTopOnRouteChange';
import { AsideLayout, GreetingLayout, UserLayout } from './_layouts';
import { LoginPage } from './login';
import { AuthPage } from './auth';
import { HomePage } from './home';
import { DisciplinePage } from './discipline';
import { LessonPage } from './lesson';
import { QuizPage } from './quiz';
import { GradesPage } from './grades';
import { DocumentsPage } from './documents/DocumentsPage';
import {
  ADFS_REDIRECT_ROUTE,
  DISCIPLINE_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  LESSON_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  QUIZ_PAGE_ROUTE,
  GRADES_PAGE_ROUTE,
  DOCUMENTS_PAGE_ROUTE,
  CALENDAR_PAGE_ROUTE
} from './routes';
import { CalendarPage } from './calendar';

export function AppRoutes() {
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();

  useScrollTopOnRouteChange();

  return (
    <Routes>
      <Route path={LOGIN_PAGE_ROUTE} element={<LoginPage />} />
      <Route path={ADFS_REDIRECT_ROUTE} element={<AuthPage />} />

      {isLoggedIn() ? (
        <Route element={<UserLayout />}>
          <Route element={<GreetingLayout />}>
            <Route path={HOME_PAGE_ROUTE} element={<HomePage />} />
            <Route path={DISCIPLINE_PAGE_ROUTE} element={<DisciplinePage />} />
          </Route>

          <Route element={<AsideLayout />}>
            <Route path={QUIZ_PAGE_ROUTE} element={<QuizPage />} />
            {/*<Route path={PROJECTS_PAGE_ROUTE} element={<ProjectsPage />} />*/}
            <Route path={GRADES_PAGE_ROUTE} element={<GradesPage />} />
            <Route path={DOCUMENTS_PAGE_ROUTE} element={<DocumentsPage />} />
            <Route path={CALENDAR_PAGE_ROUTE} element={<CalendarPage />} />
          </Route>

          {/* Использует собственный AsideLayout */}
          <Route path={LESSON_PAGE_ROUTE} element={<LessonPage />} />

          {/* not found route */}
          <Route path={'*'} element={<Navigate to={HOME_PAGE_ROUTE} replace />} />
        </Route>
      ) : (
        // auth guard
        <Route path={'*'} element={<Navigate to={`${LOGIN_PAGE_ROUTE}?next=${pathname}`} replace />} />
      )}
    </Routes>
  );
}
