import { useSiteInfoData } from 'store/siteInfo';
import { useEnrolUserCoursesData } from 'store/moodleAPI/useEnrolUserCoursesData';
import { GradereportUserGetGradeItemsData } from 'store/moodleAPI/moodleTypes/_functions/gradereport_user_get_grade_items';
import {
  GradereportOverviewGetCourseGradesData,
  GradereportOverviewGetCourseGradesQuery
} from 'store/moodleAPI/moodleTypes/_functions/gradereport_overview_get_course_grades';
import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import { useMemo } from 'react';

export type GradeItem = GradereportUserGetGradeItemsData['usergrades'][number]['gradeitems'][number] & {
  courseid: number;
};

export const useGradesData = () => {
  const { userid } = useSiteInfoData();

  // Позже можно будет добавить фильтр [незавершенные]
  const { coursesData, isLoading: coursesIsLoading } = useEnrolUserCoursesData();

  const {
    data: gradesData,
    error: gradesError,
    isLoading: gradesIsLoading
  } = useMoodleAPI<GradereportOverviewGetCourseGradesQuery, GradereportOverviewGetCourseGradesData>({
    userid: userid,
    wsfunction: 'gradereport_overview_get_course_grades'
  });
  const coursesById = useMemo(() => new Map(coursesData?.map((course) => [course.id, course]) ?? []), [coursesData]);

  const grades = useMemo(
    () =>
      gradesData?.grades
        ?.map((grade) => ({ ...grade, course: coursesById.get(grade.courseid) }))
        ?.filter((grade) => grade.course) ?? [],
    [gradesData, coursesById]
  );

  return {
    grades,
    isLoading: coursesIsLoading || gradesIsLoading,
    error: gradesError
  };
};
