/* --------- auth routes --------- */

export const ADFS_REDIRECT_ROUTE = '/auth';
export const LOGIN_PAGE_ROUTE = '/login';

/* --------- protected routes --------- */

export const HOME_PAGE_ROUTE = '/';
export const DISCIPLINE_PAGE_PARAM = 'courseId';
export const DISCIPLINE_PAGE_ROUTE = `/courses/:${DISCIPLINE_PAGE_PARAM}`;

export const LESSON_PAGE_PARAM = 'lessonId';
export const LESSON_PAGE_ROUTE = `/lesson/:${LESSON_PAGE_PARAM}`;

export const QUIZ_PAGE_PARAM = 'quizId';
export const QUIZ_PAGE_ROUTE = `/quiz/:${QUIZ_PAGE_PARAM}`;

export const PROJECTS_PAGE_ROUTE = '/projects';

export const DOCUMENTS_PAGE_ROUTE = '/documents';

export const GRADES_PAGE_ROUTE = '/grades';

export const CALENDAR_PAGE_ROUTE = '/calendar';
