import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import { HeadingSize, Heading, Space, IconActionSearch, Icon } from '@hse-design/react';
import { HeadingProps } from '@hse-design/react/lib/js/components/Heading';
import { IconSize } from '@hse-design/react/lib/js/components/Icon/Icon';

import s from './PageHeader.module.scss';
import { Link, TLink } from '../Link/Link';
import { InputProps } from '@hse-design/react/lib/js/components/Input/Input';
import { ReactFCC } from 'utils/ReactFCC';
import { Input } from '../Input/Input';

type TPageHeader = {
  className?: string;
  headingProps?: HeadingProps;
  isMobile?: boolean;
  linkRight?: TLink;
  searchParams?: InputProps;
  style?: CSSProperties;
  title?: string;
};

export const PageHeader: ReactFCC<TPageHeader> = (props) => {
  const { className, headingProps, isMobile, linkRight, searchParams, style, title } = props;

  return (
    <div className={clsx(s.PageHeader__container, className)} style={style}>
      <div className={clsx(s.PageHeader__contentContainer, isMobile && s.PageHeader__contentContainer_mobile)}>
        <div className={clsx(s.PageHeader__contentLeft)}>
          {title && (
            <Heading
              {...headingProps}
              className={clsx(s.PageHeader__title, headingProps?.className)}
              size={headingProps?.size || HeadingSize.H4}>
              <span>{title}</span>
            </Heading>
          )}

          {title && <Space horizontal size={Space.Size.small_x} />}

          {linkRight && (
            <Link {...linkRight} className={clsx(s.PageHeader__link, linkRight.className)} variant={linkRight.variant}>
              {linkRight.children}
            </Link>
          )}
        </div>

        {searchParams && <Space horizontal={!isMobile} size={Space.Size.small_x} vertical={isMobile} />}

        {searchParams && (
          <div>
            <Input
              {...searchParams}
              className={clsx(s.PageHeader__input, isMobile && s.PageHeader__input_stretch, searchParams.className)}
              classes={{
                ...(searchParams.classes || {}),
                icon: clsx(searchParams.classes?.icon, s.PageHeader__inputIcon)
              }}
              right={searchParams.right || <Icon icon={IconActionSearch} size={IconSize.small} />}
              size={searchParams.size}
            />
          </div>
        )}
      </div>
    </div>
  );
};
