import React, { useState } from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import { Helmet } from 'react-helmet-async';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { CalendarCard } from './CalendarCard/CalendarCard';
import { useMonthCalendarData } from './api/useMonthCalendarData';
import { Error } from '@hse-design/react';
import s from './CalendarPage.module.scss';

const title = 'Календарь';
const today = new Date();

export const CalendarPage: ReactFCC = () => {
  const [date, setDate] = useState(today);

  const { events, isLoading, error } = useMonthCalendarData({
    year: date.getFullYear(),
    month: date.getMonth()
  });

  return (
    <main>
      <Helmet title={title} />
      <PageHeader className={s.CalendarPage__header} title={title} />

      {error ? (
        <Error title="Не удалось загрузить информацию о событиях" description={error} />
      ) : (
        <CalendarCard title={title} events={events} loading={isLoading} selectedDate={date} onDateChange={setDate} />
      )}
    </main>
  );
};
