export const sections = [
  {
    title: 'Стратегии и управление в коммуникациях',
    documents: [
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=430137',
        text: 'Burtenshaw, K., Barfoot, C., & Mahon, N. (2011). The Fundamentals of Creative Advertising (Vol. 2nd ed). Lausanne: AVA Publishing. Retrieved from '
      },
      {
        url: 'https://ebookcentral.proquest.com/lib/hselibrary-ebooks/detail.action?docID=4514345.',
        text: 'Consumer behaviour in a changing world : food, culture and society. 2016. Ed. by Fabio Verneau and Christopher Griffith Emerald Group Publishing Limited '
      },
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=1360406',
        text: 'McDonald, M., & Wilson, H. (2016). Marketing Plans : How to Prepare Them, How to Profit From Them (Vol. Eighth Edition). Hoboken: Wiley. Retrieved from '
      },
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=1201969',
        text: 'Solomon, R. (2016). The Art of Client Service : The Classic Guide, Updated for Today’s Marketers and Advertisers (Vol. Third edition). Hoboken, New Jersey: Wiley. Retrieved from '
      },
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsbas&AN=edsbas.762D980D',
        text: 'Turnbull, S., & Wheeler, C. H. (2015). The advertising creative process:a study of UK agencies. Retrieved from '
      }
    ]
  },
  {
    title: 'Экосистема цифровых коммуникаций',
    documents: [
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=997448',
        text: 'Young, A. (2014). Brand Media Strategy : Integrated Communications Planning in the Digital Era (Vol. Second edition). New York: Palgrave Macmillan. Retrieved from '
      },
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsrep&AN=edsrep.b.spr.sprbok.978.3.319.69697.3',
        text: 'Mateusz Grzesiak. (2018). Personal Brand Creation in the Digital Age. Springer. Retrieved from '
      }
    ]
  },
  {
    title: 'Сбор и анализ количественных данных в рекламе и PR',
    documents: [
      {
        text: 'Harish Garg. (2018). Mastering Exploratory Analysis with Pandas : Build an End-to-end Data Analysis Workflow with Python. Packt Publishing.'
      },
      {
        url: 'https://doi.org/10.5281/zenodo.3773303',
        text: 'Fink, C. (2020). metatube: Python script to download YouTube metadata. '
      },
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=1605925',
        text: 'McKinney, W. (2018). Python for Data Analysis : Data Wrangling with Pandas, NumPy, and IPython (Vol. Second edition). Sebastopol, CA: O’Reilly Media. Retrieved from '
      }
    ]
  },
  {
    title: 'Стратегии антиткризисных коммуникаций и оценка репутационных рисков',
    documents: [
      {
        text: 'Walaski P. (2011). Risks and Crisis Communications: Methods and Messages. John Wiley & Sons Incorporated.'
      },
      {
        text: 'Ndlela M. (2019). Crisis Communication: A Stakeholder Approach. Palgrave Pivot, Cham.'
      }
    ]
  },
  {
    title: 'Качественные методы сбора и анализа данных в теории и практике коммуникаций',
    documents: [
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=716712',
        text: 'Fackler, M., & Fortner, R. S. (2014). The Handbook of Media and Mass Communication Theory. Chichester, West Sussex, UK: Wiley-Blackwell. Retrieved from '
      },
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=344278',
        text: 'Johnson, S. A., & Milani, T. M. (2010). Language Ideologies and Media Discourse : Texts, Practices, Politics. London: Continuum. Retrieved from '
      },
      {
        url: 'http://search.ebscohost.com/login.aspx?direct=true&site=eds-live&db=edsebk&AN=413475',
        text: 'Jones, J. P. (2010). Entertaining Politics : Satiric Television and Political Engagement (Vol. 2nd ed). Lanham: Rowman & Littlefield Publishers. Retrieved from '
      }
    ]
  },
  {
    title: 'Нормативно-правовое регулирование и обеспечение коммуникационной деятельности',
    documents: [
      {
        text: 'ГК РФ. Часть четвертая.'
      },
      {
        text: 'Закон РФ от 27.12.1991 N 2124-1 (ред. от 01.07.2021) "О средствах массовой информации" (с изм. и доп., вступ. в силу с 01.08.2021)'
      },
      {
        text: 'Федеральный закон от 27.07.2006 N 149-ФЗ (ред. от 02.07.2021) "Об информации, информационных технологиях и о защите информации" (с изм. и доп., вступ. в силу с 01.10.2021)'
      },
      {
        text: 'Федеральный закон "О рекламе" от 13.03.2006 N 38-ФЗ'
      },
      {
        text: 'Право интеллектуальной собственности. Учебник / Под ред. Новоселовой Л.А. М, ЮРАЙТ. 2019.'
      },
      {
        text: 'Информационное право: учебник для вузов/ М. А. Федотов [и др.]; под редакцией М. А. Федотова. Москва: Издательство Юрайт, 2020.'
      },
      {
        text: 'Паникеев И.А. Правовое регулирование СМИ. Учебное пособие. М, 2019.'
      }
    ]
  }
];
