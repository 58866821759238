import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import {
  CoreCourseGetCourseModuleData,
  CoreCourseGetCourseModuleQuery
} from 'store/moodleAPI/moodleTypes/_functions/core_course_get_course_module';
import { useCourseActivities } from 'store/moodleAPI/courseActivities/useCourseActivities';
import { useCallback, useMemo } from 'react';
import { CompletionState, IModule } from 'store/moodleAPI/moodleTypes/Module';
import { IActivityCardProps } from 'components/Activities/ActivityCard/ActivityCard';
import { EActivityState, IActivityItem } from 'store/moodleAPI/courseActivities/activityTypes';
import {
  ModQuizGetUserAttemptsData,
  ModQuizGetUserAttemptsQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_user_attempts';
import {
  ModQuizGetQuizAccessInformationData,
  ModQuizGetQuizAccessInformationQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_quiz_access_information';
import {
  ModQuizGetQuizRequiredQtypesData,
  ModQuizGetQuizRequiredQtypesQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_quiz_required_qtypes';
import { useAccessIsEnabled } from 'routes/quiz/QuizCompletionState/utils/useAccessIsEnabled';
import { ModQuizViewQuizData, ModQuizViewQuizQuery } from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_view_quiz';
import {
  ModQuizGetAttemptAccessInformationData,
  ModQuizGetAttemptAccessInformationQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_access_information';
import {
  ModQuizGetAttemptReviewData,
  ModQuizGetAttemptReviewQuery
} from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_review';
import {
  CoreCourseGetCoursesByFieldData,
  CoreCourseGetCoursesByFieldQuery
} from '../../../store/moodleAPI/moodleTypes/_functions/core_course_get_courses_by_field';

export const useQuizData = (quizId?: string) => {
  const { data: moduleData, error: moduleError } = useMoodleAPI<
    CoreCourseGetCourseModuleQuery,
    CoreCourseGetCourseModuleData
  >(
    !!quizId && {
      wsfunction: 'core_course_get_course_module',
      cmid: quizId
    }
  );

  const { instance: instanceId, course: courseId } = moduleData?.cm ?? {};

  // COURSE

  const {
    data,
    isLoading: courseLoading,
    error: courseError
  } = useMoodleAPI<CoreCourseGetCoursesByFieldQuery, CoreCourseGetCoursesByFieldData>(
    !!courseId && {
      wsfunction: 'core_course_get_courses_by_field',
      field: 'id',
      value: courseId
    }
  );

  const course = data?.courses?.[0];

  const { error: moduleViewError } = useMoodleAPI<ModQuizViewQuizQuery, ModQuizViewQuizData>(
    !!instanceId && {
      wsfunction: 'mod_quiz_view_quiz',
      quizid: instanceId
    }
  );

  const {
    activities,
    activitiesData,
    error: activitiesError,
    fetchActivities
  } = useCourseActivities(String(courseId ?? ''));

  const { quiz, lesson, nextActivity } = useMemo(() => {
    let nextActivity: IActivityCardProps | undefined;
    let lesson: IActivityItem | undefined;
    let quiz: IModule | undefined;

    activities?.forEach(({ items }) => {
      if (nextActivity) {
        return;
      }

      items.forEach((item, idx) => {
        if (String(item.quiz?.id) === quizId) {
          quiz = item.quiz;
          lesson = item;
          const nextItem = items[idx + 1];
          if (nextItem && nextItem.state !== EActivityState.CLOSE) {
            nextActivity = nextItem;
          }
          return;
        }
      });
    });

    if (!quiz && activitiesData) {
      activitiesData?.forEach(({ modules }) => {
        if (quiz) {
          return;
        }

        modules.forEach((module) => {
          if (String(module.id) === quizId) {
            quiz = module;
            return;
          }
        });
      });
    }

    return {
      lesson,
      quiz,
      nextActivity
    };
  }, [activities, quizId, activitiesData]);

  const {
    data: accessData,
    error: accessError,
    clearResult: accessClear
  } = useMoodleAPI<ModQuizGetQuizAccessInformationQuery, ModQuizGetQuizAccessInformationData>(
    quiz?.completiondata?.state !== undefined &&
      !!instanceId && {
        wsfunction: 'mod_quiz_get_quiz_access_information',
        quizid: instanceId
      }
  );

  const { accessrules, preventaccessreasons } = accessData ?? {};

  const { accessIsEnabled } = useAccessIsEnabled({
    preventaccessreasons,
    accessrules
  });

  const {
    data: attemptsData,
    isLoading: attemptsLoading,
    error: attemptsError,
    clearResult: attemptsClear
  } = useMoodleAPI<ModQuizGetUserAttemptsQuery, ModQuizGetUserAttemptsData>(
    ((typeof quiz?.completiondata?.state === 'number' && quiz?.completiondata?.state !== CompletionState.Incomplete) ||
      accessIsEnabled) &&
      !!instanceId && {
        wsfunction: 'mod_quiz_get_user_attempts',
        quizid: instanceId,
        status: 'all'
      }
  );

  let grade = attemptsData?.attempts.length
    ? attemptsData.attempts.reduce((prev: number | null, current) => {
        if (typeof prev === 'number' && typeof current?.sumgrades === 'number') {
          return prev + Math.round(current.sumgrades * 100) / 100;
        }

        return null;
      }, 0)
    : null;

  if (grade) {
    grade = grade / (attemptsData?.attempts?.length ?? 1);
  }

  const lastAttempt = attemptsData?.attempts?.[attemptsData.attempts.length - 1];
  const lastAttemptId = lastAttempt?.id;

  const inProgressAttempt = lastAttempt?.state === 'inprogress' ? lastAttempt : null;

  const { data: quizGetAttemptReviewData, isLoading: quizGetAttemptReviewIsLoading } = useMoodleAPI<
    ModQuizGetAttemptReviewQuery,
    ModQuizGetAttemptReviewData
  >(
    typeof lastAttemptId === 'number' && {
      wsfunction: 'mod_quiz_get_attempt_review',
      attemptid: lastAttemptId,
      page: 0
    }
  );

  const {
    data: requiredTypesData,
    isLoading: requiredTypesIsLoading,
    error: requiredTypesError
  } = useMoodleAPI<ModQuizGetQuizRequiredQtypesQuery, ModQuizGetQuizRequiredQtypesData>(
    quiz?.completiondata?.state !== undefined &&
      quiz?.completiondata?.state !== CompletionState.CompletePass &&
      accessIsEnabled &&
      !!instanceId && {
        wsfunction: 'mod_quiz_get_quiz_required_qtypes',
        quizid: instanceId
      }
  );

  const {
    isLoading: attemptAccessInformationIsLoading,
    error: attemptAccessInformationError,
    clearResult: attemptAccessInformationClear
  } = useMoodleAPI<ModQuizGetAttemptAccessInformationQuery, ModQuizGetAttemptAccessInformationData>(
    !!instanceId && {
      wsfunction: 'mod_quiz_get_attempt_access_information',
      quizid: instanceId,
      attemptid: 0
    }
  );

  const error =
    moduleError ||
    courseError ||
    activitiesError ||
    attemptsError ||
    accessError ||
    moduleViewError ||
    requiredTypesError ||
    attemptAccessInformationError;

  const isLoadingModule = !quiz || courseLoading;

  const isLoadingCompletionState =
    (!attemptsData && !accessData) ||
    attemptAccessInformationIsLoading ||
    attemptsLoading ||
    requiredTypesIsLoading ||
    quizGetAttemptReviewIsLoading;

  const clearData = useCallback(() => {
    accessClear();
    attemptsClear();
    attemptAccessInformationClear();
    fetchActivities();
  }, [accessClear, attemptsClear, attemptAccessInformationClear, fetchActivities]);

  return {
    quiz: quiz
      ? {
          ...quiz,
          grade,
          accessData,
          requiredTypesData,
          inProgressAttempt
        }
      : null,
    lastAttemptId,
    attempts: attemptsData?.attempts || [],
    accessAttemptReview: !!quizGetAttemptReviewData,
    nextActivity,
    lesson,
    course,
    isLoadingModule,
    isLoadingCompletionState,
    error,
    clearData
  };
};
