import React from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import s from './QuizCard.module.scss';

export interface IQuizCardPoprs {
  title?: React.ReactNode;
}

export const QuizCard: ReactFCC<IQuizCardPoprs> = (props) => {
  const { title, children } = props;

  return (
    <div className={s.QuizCard}>
      <div className={s.QuizCard__title}>{title}</div>
      <div className={s.QuizCard__content}>{children}</div>
    </div>
  );
};
