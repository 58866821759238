import React from 'react';
import clsx from 'clsx';
import { IconActionArrowKeyboardLeft, IconActionArrowKeyboardRight, LinkVariant } from '@hse-design/react';
import { Anchor, EAnchorSize } from '../../Anchor/Anchor';
import s from './CalendarNav.module.scss';

export enum CalendarNavDirection {
  Prev,
  Next
}

export interface CalendarNavProps {
  className?: string;
  title?: React.ReactNode;
  buttonPrevContent?: React.ReactNode;
  buttonNextContent?: React.ReactNode;
  onNavigate?: (direction: CalendarNavDirection, e: React.MouseEvent) => void;
}

export const CalendarNav = ({
  className,
  title,
  buttonPrevContent,
  buttonNextContent,
  onNavigate
}: CalendarNavProps) => {
  return (
    <div className={clsx(s.CalendarNav, className)}>
      <div className={s.CalendarNav__left}>
        {buttonPrevContent && (
          <Anchor
            className={s.CalendarNav__button}
            size={EAnchorSize.MEDIUM}
            variant={LinkVariant.secondary}
            leftIcon={() => <IconActionArrowKeyboardLeft size="large" />}
            onClick={(e) => onNavigate?.(CalendarNavDirection.Prev, e)}>
            {buttonPrevContent}
          </Anchor>
        )}
      </div>

      <div className={s.CalendarNav__title}>{title}</div>

      <div className={s.CalendarNav__right}>
        <Anchor
          className={s.CalendarNav__button}
          size={EAnchorSize.MEDIUM}
          variant={LinkVariant.secondary}
          rightIcon={() => <IconActionArrowKeyboardRight size="large" />}
          onClick={(e) => onNavigate?.(CalendarNavDirection.Next, e)}>
          {buttonNextContent}
        </Anchor>
      </div>
    </div>
  );
};
