import { format } from 'date-fns';
import { EMDASH } from '@proscom/ui-utils';

export const formatQuizDate = (date?: Date) => {
  return date ? format(date, 'dd.MM.yyyy') : EMDASH;
};

export const formatQuizTotalGrade = (grade: number | null) => {
  return typeof grade === 'number' ? grade.toFixed(2) : EMDASH;
};

export const formatQuizAttemptGrade = (grade: number | null) => {
  return typeof grade === 'number' ? grade.toFixed(2) : EMDASH;
};

export const formatQuizMinMax = (gradeMin: number | null, gradeMax: number | null) => {
  return gradeMin !== null && gradeMax !== null ? `${gradeMin}-${gradeMax}` : EMDASH;
};
