import { addDays, format, isValid, parseISO, startOfWeek } from 'date-fns';
import { ru } from 'date-fns/locale';
import ruLocale from 'date-fns/locale/ru';

export type FormatDateOptionsType = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
};

export const DATETIME_FORMAT_FULL = 'dd MMMM yyyy, HH:mm';

const startOfWeekDate = startOfWeek(new Date(), { locale: ru, weekStartsOn: 1 });

export const daysNames = new Array(7).fill('').map((_, i) => {
  const d = addDays(startOfWeekDate, i);
  return format(d, 'EEEEEE', { locale: ru });
});

/**
 * tryParseIso - проверяет дату на валидность и возвращает ее же, если она валидна, и null, если нет
 *
 * @param string | undefined
 * @return Date | null
 */
export function tryParseIso(string?: string): Date | null {
  if (!string) return null;
  const date = parseISO(string);
  if (isValid(date)) {
    return date;
  }
  return null;
}

/**
 * formatDate - возвращает отформатированную дату (в соотв. с переданными formatString и options) или null, если дата невалидна
 *
 * @param dateIso: Date | string | undefined | null
 * @param formatString: string
 * @param options: FormatDateOptionsType | undefined
 * @return string | null
 */
export function formatDate(dateIso: Date | string, formatString: string, options?: FormatDateOptionsType): string;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString: string,
  options?: FormatDateOptionsType
): string | null;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString: string = DATETIME_FORMAT_FULL,
  options?: FormatDateOptionsType
): string | null {
  const date = typeof dateIso === 'string' ? tryParseIso(dateIso) : dateIso || null;
  return (
    (date &&
      format(date, formatString, {
        locale: ruLocale,
        ...options
      })) ||
    null
  );
}
