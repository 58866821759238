import React from 'react';
import clsx from 'clsx';
import { Modal as HSEModal } from '@hse-design/react';
import { ModalProps as HSEModalProps } from '@hse-design/react/lib/js/components/Modal/Modal';
import s from './Modal.module.scss';

export interface ModalProps extends HSEModalProps {}

export const Modal = ({ className, ...modalProps }: ModalProps) => {
  return <HSEModal className={clsx(s.Modal, className)} overlayClassName={s.ModalOverlay} {...modalProps} />;
};
