import { resolvePath, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

export const useIsRouteActive = () => {
  let location = useLocation();

  return useCallback(
    (path?: string) => {
      let resolvedPath = resolvePath(path || '');

      let locationPathname = location.pathname;
      let toPathname = resolvedPath.pathname;

      return !!(
        path &&
        (locationPathname === toPathname ||
          (locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === '/'))
      );
    },
    [location]
  );
};
