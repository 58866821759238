import clsx from 'clsx';
import React, { useEffect } from 'react';
import { notNull } from 'utils/notNull';
import { moodleDomain } from 'store/config';
import { insertAccessKeyIntoUrls } from 'utils/insertAccessKeyIntoUrls';
import { useSiteInfoData } from 'store/siteInfo';
import s from './MoodleContent.module.scss';

export interface IMoodleContentProps {
  textFormat?: 'small' | 'medium' | 'large';
  // Moodle HTML
  children: string;
  innerRef?: React.Ref<HTMLDivElement>;
  style?: React.CSSProperties;
  className?: string;
}

export function MoodleContent({ textFormat = 'large', children, innerRef, style, className }: IMoodleContentProps) {
  const handleMessage = (e: MessageEvent) => {
    // h5p embedded content is fully loaded
    if (e.data.type === 'HEIGHT') document.getElementById('h5p-content')?.setAttribute('height', e.data.payload);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  let htmlString = children
    // [1.1] заменяет все эти странные пробелы на обычные / делает результат более предсказуемым
    .replace(/ |&nbsp;/g, ' ')
    // [1.2] на сайте edu.hse.ru у body установлен font-size: 0.9375rem, такой же размер установлен на рандомных блоках разметки,
    // из-за чего у нас эти блоки в неожиданных местах имеют меньший размер, если не подменить этот стиль
    .replace(/font-size: 0.9375rem;/g, 'font-size: inherit;');

  // [2] вставляет siteInfoData.userprivateaccesskey
  const siteInfoData = useSiteInfoData();
  const { userprivateaccesskey } = notNull(siteInfoData);

  htmlString = insertAccessKeyIntoUrls(htmlString, userprivateaccesskey);

  const parser = new DOMParser();
  const content = parser.parseFromString(htmlString, 'text/html');

  content.querySelectorAll('video').forEach((video) => {
    // [3] исправляет неправильно вставленные видео с YouTube
    if (video.innerHTML.includes('https://youtu.be/')) {
      const id = video.innerHTML.split('/')[3].split('"')[0];
      if (id) {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.youtube.com/embed/${id}`);
        iframe.setAttribute('width', '400');
        iframe.setAttribute('height', '225');
        // prettier-ignore
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', '');

        video.replaceWith(iframe);
      }
    }

    if (video.innerHTML.includes('youtube.com/watch?v=')) {
      let src = 'https://www.youtube.com/embed/';

      const id = video.innerHTML.split('?v=')[1];
      if (id.indexOf('"')) src += id.substring(0, id.indexOf('"'));
      if (id.indexOf('<')) src += id.substring(0, id.indexOf('<'));

      if (src !== 'https://www.youtube.com/embed/') {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', src);
        iframe.setAttribute('width', '400');
        iframe.setAttribute('height', '225');
        // prettier-ignore
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', '');

        video.replaceWith(iframe);
      }
    }
  });

  content.querySelectorAll('iframe').forEach((iframe) => {
    // [4] оборачивает iframe в два контейнера, чтобы сохранить соотношение сторон
    const container = document.createElement('div');
    const innerContainer = document.createElement('div');

    container.setAttribute('class', s.MoodleContent__iFrameContainer);
    container.style.maxWidth = (iframe.width || '400') + 'px';
    container.appendChild(innerContainer);

    if (iframe.width && !iframe.width.includes('%') && iframe.height && !iframe.height.includes('%')) {
      const width = parseInt(iframe.width);
      const height = parseInt(iframe.height);
      innerContainer.style.paddingBottom = (height / width) * 100 + '%';
    } else {
      innerContainer.style.paddingBottom = '56.25%';
    }

    iframe.replaceWith(container);
    innerContainer.appendChild(iframe);

    const parent = container.parentElement;
    // тег p не может содержать в себе div
    if (parent && parent.tagName === 'P') {
      // передаем логику text-align: center;
      if (parent.style.textAlign === 'center') {
        container.style.marginRight = container.style.marginLeft = 'auto';
      }

      parent.after(container);
      parent.remove();
    }
  });

  // TODO: перейти на использование непосредственно MoodlePage
  // [5] встраивает h5p-контент
  content.querySelectorAll('.h5p-placeholder').forEach((h5p) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `https://${moodleDomain}/h5p/embed.php?url=${h5p.innerHTML}`);
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('width', '100%');
    // подумать над полноценной поддержкой сразу нескольких фреймов
    iframe.setAttribute('id', `h5p-content`);
    h5p.replaceWith(iframe);
  });

  // [6] не дает (совсем) сломать верстку тегами br
  content.querySelectorAll('br').forEach((br) => {
    if (!br.nextSibling || br.nextSibling.nodeName === 'BR') br.remove();
  });

  // [7] оборачивает текст в span, чтобы можно было делать относительные отступы
  content.querySelectorAll('div, p').forEach((parent) => {
    parent.childNodes.forEach((child) => {
      if (child.nodeType === 3) {
        const span = document.createElement('span');
        child.after(span);
        span.appendChild(child);
      }
    });
  });

  // [8] рекурсивно удаляет все пустые элементы
  (function removeEmptyElements() {
    let done = true;

    content.querySelectorAll('div:empty, p:empty, span:empty').forEach((element) => {
      element.remove();
      done = false;
    });

    if (!done) removeEmptyElements();
  })();

  // [9] убирает img.align
  content.querySelectorAll('img').forEach((img) => (img.align = ''));

  const serializer = new XMLSerializer();
  return (
    <div
      ref={innerRef}
      style={style}
      className={clsx(
        s.MoodleContent,
        { [s.MoodleContent_small]: textFormat === 'small' },
        { [s.MoodleContent_medium]: textFormat === 'medium' },
        className
      )}
      dangerouslySetInnerHTML={{
        __html: serializer.serializeToString(content)
      }}
    />
  );
}
