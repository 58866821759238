import React from 'react';
import { SpinnerSize } from '@hse-design/react';
import { ReactFCC } from 'utils/ReactFCC';
import clsx from 'clsx';
import { Spinner } from 'components/Spinner/Spinner';
import { Activity, IActivityProps } from './Activity/Activity';
import { ActivitiesVariant } from './types';
import s from './Activities.module.scss';

export interface IActivitiesProps {
  isLoading?: boolean;
  activities: IActivityProps[] | null;
  variant?: ActivitiesVariant;
}

export const Activities: ReactFCC<IActivitiesProps> = (props) => {
  const { isLoading, activities, variant = ActivitiesVariant.DEFAULT } = props;

  if (isLoading) {
    return <Spinner loading size={SpinnerSize.large} />;
  }

  if (!activities) {
    return null;
  }

  return (
    <div className={clsx(s.Activities, s[`Activities_variant_${variant}`])}>
      {activities.map((activity, idx) => (
        <Activity key={idx} {...activity} variant={variant} />
      ))}
    </div>
  );
};
