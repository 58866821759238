import { useCallback, useLayoutEffect, useState } from 'react';
import { CompletionState } from 'store/moodleAPI/moodleTypes/Module';
import {
  CoreCourseGetContentsData,
  CoreCourseGetContentsQuery
} from 'store/moodleAPI/moodleTypes/_functions/core_course_get_contents';
import { useSiteInfoData } from 'store/siteInfo';
import { LESSON_PAGE_PARAM, LESSON_PAGE_ROUTE, QUIZ_PAGE_PARAM, QUIZ_PAGE_ROUTE } from 'routes/routes';
import {
  EActivityState,
  EActivityType,
  EFilterActivities,
  IActivities,
  IActivitiesSection,
  IActivityItem,
  LABEL_MOD_NAME,
  LECTURE_NAME,
  LESSON_MOD_NAME,
  QUIZ_MOD_NAME,
  RESOURCE_MOD_NAME,
  SEMINAR_NAME
} from './activityTypes';
import { insertAccessKeyIntoUrls } from 'utils/insertAccessKeyIntoUrls';
import { useMoodleAPILazyQuery } from '../useMoodleAPILazyQuery';

export const useCourseActivities = (courseId?: string, filter?: EFilterActivities[]) => {
  const { userprivateaccesskey } = useSiteInfoData();

  const requestMoodleApi = useMoodleAPILazyQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [data, setData] = useState<CoreCourseGetContentsData | null>(null);
  const [activitySections, setActivitySections] = useState<IActivitiesSection[] | null>(null);
  const [activities, setActivities] = useState<IActivities[] | null>(null);

  const fetchActivities = useCallback(async () => {
    if (!courseId) {
      setActivitySections(null);
      setActivities(null);
      return;
    }

    setIsLoading(true);
    setError(null);

    const { data, error } = await requestMoodleApi<CoreCourseGetContentsQuery, CoreCourseGetContentsData>({
      wsfunction: 'core_course_get_contents',
      courseid: courseId
    });

    setIsLoading(false);

    if (!data || error) {
      setData(null);
      setActivitySections(null);
      setActivities(null);
      setError(error);
      return;
    }

    const [lecturesAll, seminarsAll, quizzesAll]: IActivities[] = [
      {
        title: 'Лекции',
        items: []
      },
      {
        title: 'Семинары',
        items: []
      },
      {
        title: 'Тесты',
        items: []
      }
    ];

    const activitySections: IActivitiesSection[] = [];

    data.forEach((section) => {
      let prevItem: IActivityItem | null = null;
      let resource: IActivityItem['resource'] | null = null;
      let labels: NonNullable<IActivityItem['labels']> = [];

      const [lectures, seminars, quizzes]: IActivities[] = [
        {
          title: 'Лекции',
          items: []
        },
        {
          title: 'Семинары',
          items: []
        },
        {
          title: 'Тесты',
          items: []
        }
      ];

      section.modules.forEach((module) => {
        if (![LESSON_MOD_NAME, QUIZ_MOD_NAME, RESOURCE_MOD_NAME, LABEL_MOD_NAME].includes(module.modname)) {
          return;
        }

        if (module.modname === LABEL_MOD_NAME) {
          if (module.description) {
            labels.push({ description: module.description });
          }

          return;
        }

        if (module.modname === RESOURCE_MOD_NAME && module.contents?.[0]?.fileurl) {
          const { filename, fileurl, filesize } = module.contents[0];

          resource = {
            name: filename,
            url: insertAccessKeyIntoUrls(fileurl, userprivateaccesskey),
            size: filesize
          };

          return;
        }

        if (module.modname === QUIZ_MOD_NAME) {
          if (!module.uservisible) {
            return;
          }

          if (!prevItem) {
            if (filter?.includes(EFilterActivities.ISOLATED_TEXT)) {
              return;
            }

            const item: IActivityItem = {
              ...module,
              title: module.name,
              type: EActivityType.QUIZ,
              href: `${QUIZ_PAGE_ROUTE.replace(':' + QUIZ_PAGE_PARAM, String(module.id))}`,
              state: EActivityState.QUIZ
            };

            // Не очень понял, почему тесты раньше дублировались и в лекции, и в семинары, но от греха подальше
            //  создал для них отдельный раздел
            // lectures.items.push(item);
            // seminars.items.push(item);
            quizzes.items.push(item);
            quizzesAll.items.push(item);

            return;
          }

          // А если перед тестом есть другой модуль, то тест автоматически линкуется к нему, и в модуле выводится
          //  кнопка "Перейти к тесту", ведущая на этот тест
          //  Сам тест отдельным модулем в этом случае не отображается

          prevItem.quiz = module;

          if (prevItem.type === EActivityType.LECTURE) {
            lectures.items = [...lectures.items.splice(0, lectures.items.length - 1), prevItem];
          } else if (prevItem.type === EActivityType.SEMINAR) {
            seminars.items = [...seminars.items.splice(0, seminars.items.length - 1), prevItem];
          }

          labels = [];
          return;
        }

        const { id, uservisible, name: title, completiondata } = module;

        // Такая штука не будет работать, если модуль не начинается на слово Лекция или Семинар,
        //  и вообще не будет отображаться, поэтому пока сделал проверку по целой строке
        // const name = title.split('.');
        // const typeName = name[0].toLowerCase();
        const name = title.toLowerCase();
        const type = name.includes(SEMINAR_NAME)
          ? EActivityType.SEMINAR
          : name.includes(LECTURE_NAME)
          ? EActivityType.LECTURE
          : EActivityType.UNKNOWN;

        const item: IActivityItem = {
          ...module,
          title,
          type,
          resource,
          labels: [...(!labels.length && prevItem?.type === type && prevItem.labels ? prevItem.labels : []), ...labels],
          href: `${LESSON_PAGE_ROUTE.replace(':' + LESSON_PAGE_PARAM, String(id))}`,
          state: !uservisible
            ? EActivityState.CLOSE
            : completiondata?.state === CompletionState.Complete
            ? EActivityState.CHECK
            : EActivityState.PLAY
        };

        if (type === EActivityType.LECTURE) {
          lectures.items.push(item);
          lecturesAll.items.push(item);
        } else if (type === EActivityType.SEMINAR) {
          seminars.items.push(item);
          seminarsAll.items.push(item);
        }

        labels = [];
        prevItem = item;
      });

      const sectionsResultArray = [];

      if (lectures.items.length) {
        sectionsResultArray.push(lectures);
      }

      if (seminars.items.length) {
        sectionsResultArray.push(seminars);
      }

      if (quizzes.items.length) {
        sectionsResultArray.push(quizzes);
      }

      if (sectionsResultArray.length) {
        activitySections.push({
          title: section.name,
          activities: sectionsResultArray
        });
      }
    });

    const activitiesResultArray = [];

    if (lecturesAll.items.length) {
      activitiesResultArray.push(lecturesAll);
    }

    if (seminarsAll.items.length) {
      activitiesResultArray.push(seminarsAll);
    }

    if (quizzesAll.items.length) {
      activitiesResultArray.push(quizzesAll);
    }

    setData(data);
    setActivitySections(activitySections);
    setActivities(activitiesResultArray);
  }, [setData, setActivitySections, setIsLoading, setError, courseId, requestMoodleApi, userprivateaccesskey, filter]);

  useLayoutEffect(() => {
    fetchActivities();
  }, [courseId, fetchActivities]);

  return {
    activities,
    activitySections,
    activitiesData: data,
    isLoading,
    error,
    fetchActivities
  };
};
