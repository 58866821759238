import React from 'react';
import { SiteInfoContext } from './SiteInfoContext';
import { useAuth } from '../auth';
import { useMoodleAPI } from '../moodleAPI/useMoodleAPI';
import {
  ICoreWebserviceGetSiteInfoData,
  ICoreWebserviceGetSiteInfoQuery
} from '../moodleAPI/moodleTypes/_functions/core_webservice_get_site_info';

export function SiteInfoProvider({ children }: { children: React.ReactNode }) {
  const { isLoggedIn } = useAuth();
  const queryResult = useMoodleAPI<ICoreWebserviceGetSiteInfoQuery, ICoreWebserviceGetSiteInfoData>(
    // данные автоматически обнуляются при выходе из системы (false) / пере-запрос при смене пользователя
    isLoggedIn() && { wsfunction: 'core_webservice_get_site_info' }
  );

  return <SiteInfoContext.Provider value={queryResult}>{children}</SiteInfoContext.Provider>;
}
