import React from 'react';

import { ReactFCC } from 'utils/ReactFCC';
import { Avatar, TAvatar } from '../Avatar/Avatar';

type TUserAvatar = Omit<TAvatar, 'alt'>;

/**
 * Avatar of "current" app
 *
 * @param {TUserAvatar} props
 * @component {ReactFCC<TUserAvatar>} UserAvatar
 *
 */
export const UserAvatar: ReactFCC<TUserAvatar> = (props) => {
  return <Avatar alt={'Аватар пользователя'} {...props} />;
};
