import clsx from 'clsx';
import s from './QuizAttemptCard.module.scss';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { IAttempt } from '../../../../store/moodleAPI/moodleTypes/Attempt';
import { Divider } from '@hse-design/react';
import { format } from 'date-fns';
import { EMDASH } from '@proscom/ui-utils';

export interface QuizAttemptCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объект попытки тестирования
   */
  attempt: Pick<IAttempt, 'attempt' | 'timefinish' | 'sumgrades'>;
}

export const QuizAttemptCard: ReactFCC<QuizAttemptCardProps> = (props) => {
  const { className, attempt } = props;

  return (
    <div className={clsx(s.QuizAttemptCard, className)}>
      <div className={s.QuizAttemptCard__head}>Попытка {attempt.attempt}</div>

      <Divider className={s.QuizAttemptCard__divider} />

      <span className={s.QuizAttemptCard__title}>Дата</span>
      <span className={s.QuizAttemptCard__value}>
        {attempt.timefinish ? format(new Date(attempt.timefinish * 1000), 'dd.MM.yyyy') : EMDASH}
      </span>

      <span className={s.QuizAttemptCard__title}>Результат</span>
      <span className={s.QuizAttemptCard__value}>{attempt.sumgrades ? attempt.sumgrades.toFixed(2) : EMDASH} </span>
    </div>
  );
};
