import clsx from 'clsx';
import { ReactFCC } from '../../../../../../utils/ReactFCC';
import { IconActionArrowKeyboardRight, SpinnerSize } from '@hse-design/react';
import React, { useState } from 'react';
import s from './QuizTableRow.module.scss';
import { QuizTableRowQuiz } from './QuizTableRowQuiz';
import { QuizTableBaseProps } from '../types';
import { Spinner } from '../../../../../../components/Spinner/Spinner';

export interface QuizTableRowProps extends QuizTableBaseProps {}

export const QuizTableRow: ReactFCC<QuizTableRowProps> = (props) => {
  const { grade, loadCourseGradeItems, isLoading } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr className={s.QuizTableRow_disciplineRow}>
        <td
          className={s.QuizTableRow__expandCell}
          onClick={() => {
            setIsOpen((value) => !value);
            loadCourseGradeItems(grade.courseId);
          }}>
          <IconActionArrowKeyboardRight
            className={clsx(s.QuizTableRow__chevron, isOpen && s.QuizTableRow__chevron_open)}
            size={'small'}
          />
        </td>
        <td colSpan={5}>
          {grade.courseName}{' '}
          <Spinner className={s.QuizTableRow__spinner} size={SpinnerSize.small} loading={isLoading} />
        </td>
      </tr>

      {isOpen && grade.quizzes.map((quiz, index) => <QuizTableRowQuiz quiz={quiz} key={index} />)}
    </>
  );
};
