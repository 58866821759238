import React from 'react';

import { ReactComponent as LogoHseFullRoundFilled } from './multicolor/logo-hse-full-round-filled.svg';
import { ReactComponent as VideoCoverDefault } from './multicolor/video-cover-default.svg';
import { ReactComponent as PatternSquare1 } from './patterns/pattern-square-1.svg';
import { ReactComponent as PatternSquare2 } from './patterns/pattern-square-2.svg';
import { ReactComponent as PatternSquare3 } from './patterns/pattern-square-3.svg';
import { ReactComponent as PatternSquare4 } from './patterns/pattern-square-4.svg';
import { ReactComponent as PatternSquare5 } from './patterns/pattern-square-5.svg';
import { ReactComponent as PatternSquare6 } from './patterns/pattern-square-6.svg';
import { ReactComponent as PatternSquare7 } from './patterns/pattern-square-7.svg';
import { ReactComponent as PatternSquare8 } from './patterns/pattern-square-8.svg';
import { ReactComponent as PatternSquare9 } from './patterns/pattern-square-9.svg';
import { ReactComponent as AvatarDefaultFilled } from './plain/avatar-default-filled.svg';
import { ReactComponent as BurgerOutlined } from './plain/burger-outlined.svg';
import { ReactComponent as CheckOutlined } from './plain/check-outlined.svg';
import { ReactComponent as CrossOutlined } from './plain/cross-outlined.svg';
import { ReactComponent as ExclamationPointFilled } from './plain/exclamation-point-filled.svg';
import { ReactComponent as HseOutlined } from './plain/hse-outlined.svg';
import { ReactComponent as LockFilled } from './plain/lock-filled.svg';
import { ReactComponent as PictureOutlined } from './plain/picture-outlined.svg';
import { ReactComponent as PlayFilled } from './plain/play-filled.svg';
import { ReactComponent as PlusOutlined } from './plain/plus-outlined.svg';
import { ReactComponent as SearchOutlined } from './plain/search-outlined.svg';

export enum EIconsPlain {
  AVATAR_DEFAULT_FILLED = 'AVATAR_DEFAULT_FILLED',
  BURGER_OUTLINED = 'BURGER_OUTLINED',
  CHECK_OUTLINED = 'CHECK_OUTLINED',
  CROSS_OUTLINED = 'CROSS_OUTLINED',
  EXCLAMATION_POINT_FILLED = 'EXCLAMATION_POINT_FILLED',
  HSE_OUTLINED = 'HSE_OUTLINED',
  LOCK_FILLED = 'LOCK_FILLED',
  PICTURE_OUTLINED = 'PICTURE_OUTLINED',
  PLAY_FILLED = 'PLAY_FILLED',
  PLUS_OUTLINED = 'PLUS_OUTLINED',
  SEARCH_OUTLINED = 'SEARCH_OUTLINED'
}

type TIconsPlainMap = {
  [key in EIconsPlain]: JSX.Element;
};

export const ICONS_PLAIN_MAP: TIconsPlainMap = {
  [EIconsPlain.AVATAR_DEFAULT_FILLED]: <AvatarDefaultFilled />,
  [EIconsPlain.BURGER_OUTLINED]: <BurgerOutlined />,
  [EIconsPlain.CHECK_OUTLINED]: <CheckOutlined />,
  [EIconsPlain.CROSS_OUTLINED]: <CrossOutlined />,
  [EIconsPlain.EXCLAMATION_POINT_FILLED]: <ExclamationPointFilled />,
  [EIconsPlain.HSE_OUTLINED]: <HseOutlined />,
  [EIconsPlain.LOCK_FILLED]: <LockFilled />,
  [EIconsPlain.PICTURE_OUTLINED]: <PictureOutlined />,
  [EIconsPlain.PLAY_FILLED]: <PlayFilled />,
  [EIconsPlain.PLUS_OUTLINED]: <PlusOutlined />,
  [EIconsPlain.SEARCH_OUTLINED]: <SearchOutlined />
};

export enum EIconsMulticolor {
  LOGO_HSE_FULL_ROUND_FILLED = 'LOGO_HSE_FULL_ROUND_FILLED',
  VIDEO_COVER_DEFAULT = 'VIDEO_COVER_DEFAULT'
}

type TIconsMulticolorMap = {
  [key in EIconsMulticolor]: JSX.Element;
};

export const ICONS_MULTICOLOR_MAP: TIconsMulticolorMap = {
  [EIconsMulticolor.LOGO_HSE_FULL_ROUND_FILLED]: <LogoHseFullRoundFilled />,
  [EIconsMulticolor.VIDEO_COVER_DEFAULT]: <VideoCoverDefault />
};

export enum EIconsPatterns {
  PATTERN_SQUARE_1 = 'PATTERN_SQUARE_1',
  PATTERN_SQUARE_2 = 'PATTERN_SQUARE_2',
  PATTERN_SQUARE_3 = 'PATTERN_SQUARE_3',
  PATTERN_SQUARE_4 = 'PATTERN_SQUARE_4',
  PATTERN_SQUARE_5 = 'PATTERN_SQUARE_5',
  PATTERN_SQUARE_6 = 'PATTERN_SQUARE_6',
  PATTERN_SQUARE_7 = 'PATTERN_SQUARE_7',
  PATTERN_SQUARE_8 = 'PATTERN_SQUARE_8',
  PATTERN_SQUARE_9 = 'PATTERN_SQUARE_9'
}

type TIconsPatternsMap = {
  [key in EIconsPatterns]: JSX.Element;
};

export const ICONS_PATTERNS_MAP: TIconsPatternsMap = {
  [EIconsPatterns.PATTERN_SQUARE_1]: <PatternSquare1 />,
  [EIconsPatterns.PATTERN_SQUARE_2]: <PatternSquare2 />,
  [EIconsPatterns.PATTERN_SQUARE_3]: <PatternSquare3 />,
  [EIconsPatterns.PATTERN_SQUARE_4]: <PatternSquare4 />,
  [EIconsPatterns.PATTERN_SQUARE_5]: <PatternSquare5 />,
  [EIconsPatterns.PATTERN_SQUARE_6]: <PatternSquare6 />,
  [EIconsPatterns.PATTERN_SQUARE_7]: <PatternSquare7 />,
  [EIconsPatterns.PATTERN_SQUARE_8]: <PatternSquare8 />,
  [EIconsPatterns.PATTERN_SQUARE_9]: <PatternSquare9 />
};
