import React, { useRef } from 'react';
import { ReactFCC } from 'utils/ReactFCC';

import clsx from 'clsx';

import s from './Sidebar.module.scss';

import { Icon, IconActionArrowKeyboardRight, IconActionLogout, IconButton, IconButtonVariant } from '@hse-design/react';
import { SidebarItem } from './SidebarItem/SidebarItem';
import { useClickOutside } from 'hooks/useClickOutside';
import { mediaQuery, useMediaQueryResult } from 'hooks/useMediaQueryResult';
import { useIsRouteActive } from 'hooks/useIsRouteActive';
import { menu } from './menuItems';
import { useAuth } from 'store/auth';

export type TSidebar = {
  className?: string;
  isNavigationMenuActive: boolean;
  toggleNavigationMenu: (value?: boolean) => void;
};

export const heightControls = 40;
export const paddingSidebar = 12;

export const Sidebar: ReactFCC<TSidebar> = (props) => {
  const { className, isNavigationMenuActive, toggleNavigationMenu } = props;

  const isMobile = useMediaQueryResult(mediaQuery.tabletLargeDown);

  const { logOut } = useAuth();

  const sidebarRef = useRef<HTMLDivElement>(null);

  const tabIndex = !isNavigationMenuActive && isMobile ? -1 : undefined;

  const handleCloseSidebarMobile = () => {
    toggleNavigationMenu(false);
  };

  const isActive = useIsRouteActive();

  /**
   * Closes sidebar on click outside on mobile
   */
  useClickOutside({
    callback: handleCloseSidebarMobile,
    isWithKeyEsc: true,
    ref: sidebarRef
  });

  return (
    <div className={s.Sidebar__wrapper}>
      <div className={s.Sidebar__stub} />

      <div
        className={clsx(s.Sidebar__container, className, {
          [s.Sidebar__container_open]: isNavigationMenuActive
        })}
        ref={sidebarRef}>
        {!isMobile && (
          <div
            className={s.Sidebar__paneControlContainer}
            style={{
              height: `${heightControls}px`,
              marginBottom: `${paddingSidebar}px`,
              padding: `${paddingSidebar}px ${paddingSidebar}px 0 ${paddingSidebar}px`
            }}>
            <IconButton
              className={s.Sidebar__paneControlIconButton}
              component="button"
              onClick={() => toggleNavigationMenu()}
              tabIndex={tabIndex}
              variant={IconButtonVariant.ghost}>
              <Icon
                className={clsx(s.Sidebar__paneControlIcon, isNavigationMenuActive && s.Sidebar__paneControlIcon_open)}
                icon={IconActionArrowKeyboardRight}
              />
            </IconButton>
          </div>
        )}

        <div
          className={s.Sidebar__paneMenuWrapper}
          style={{
            padding: isMobile ? `${paddingSidebar}px` : `0 ${paddingSidebar}px ${paddingSidebar}px ${paddingSidebar}px`
          }}
          tabIndex={tabIndex}>
          <div className={s.Sidebar__pane_menu_container}>
            {menu.map((item, index) => (
              <SidebarItem className={s.Sidebar__paneMenuItem} isActive={isActive(item.path)} key={index} {...item} />
            ))}

            <SidebarItem className={s.Sidebar__paneMenuItem} text={'Выход'} icon={IconActionLogout} onClick={logOut} />
          </div>
        </div>
      </div>
    </div>
  );
};
