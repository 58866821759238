import React from 'react';

import clsx from 'clsx';

import s from './DisciplineCards.module.scss';
import { ReactFCC } from 'utils/ReactFCC';
import { Paragraph, SpinnerSize } from '@hse-design/react';
import { DisciplineCard, TDisciplineCard } from './DisciplineCard/DisciplineCard';
import { Spinner } from '../../../components/Spinner/Spinner';

export type TDisciplineCards = {
  className?: string;
  courses: TDisciplineCard[];
  placeholderText: string;
  isLoading: boolean;
};

export const DisciplineCards: ReactFCC<TDisciplineCards> = (props) => {
  const { className, courses, placeholderText, isLoading } = props;

  return (
    <div className={clsx(s.DisciplineCards, className, !!placeholderText && s.DisciplineCards_withPlaceholder)}>
      {courses.map((course, i) => {
        return (
          <DisciplineCard
            className={s.DisciplineCards__card}
            key={`DisciplineCards-DisciplineCard-${course.id}-${i}`}
            {...course}
          />
        );
      })}

      {placeholderText && (
        <Paragraph className={s.DisciplineCards__placeholder} hasMargin={false}>
          {placeholderText}
        </Paragraph>
      )}

      {isLoading && (
        <div className={s.DisciplineCards__loader}>
          <Spinner loading size={SpinnerSize.large} />
        </div>
      )}
    </div>
  );
};
