import { useGradesData } from '../../api/useGradesData';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { GradeTable, GradeTableData, GradeTableCellType } from '../GradeTable';

export type Grade = Exclude<ReturnType<typeof useGradesData>['grades'], undefined>[number];

export interface DisciplineTableProps {
  grades: Grade[] | undefined;
}

export const DisciplineTable = ({ grades }: DisciplineTableProps) => {
  return (
    <GradeTable>
      <caption>Успеваемость по дисциплинам</caption>
      <Header />
      <tbody>
        {grades?.map((grade, i) => (
          <Row key={i} grade={grade}></Row>
        ))}
      </tbody>
    </GradeTable>
  );
};

const Header = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  return (
    <thead>
      <tr>
        <th>Курс</th>
        <th>Оценка</th>
      </tr>
    </thead>
  );
};

const Row = ({ grade }: { grade: Grade }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <>
        <tr>
          <th colSpan={2}>{grade.course?.fullname}</th>
        </tr>
        <tr>
          <GradeTableData type={GradeTableCellType.header}>Оценка</GradeTableData>
          <td>{grade.grade ?? '-'}</td>
        </tr>
      </>
    );
  }

  return (
    <tr>
      <td>{grade.course?.fullname}</td>
      <GradeTableData type={GradeTableCellType.nowrap}>{grade.grade ?? '-'}</GradeTableData>
    </tr>
  );
};
