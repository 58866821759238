import React from 'react';
import s from './UpcomingDisciplines.module.scss';
import { ReactComponent as LockIcon } from './lock.svg';

const upcomingDisciplines = [
  'Экосистема цифровых коммуникаций (3, 4 модули)',
  'Качественные методы сбора и анализа данных в теории и практике коммуникаций (3, 4 модули)',
  'Этика применения данных и цифровых технологий (3, 4 модули. По выбору 1 из 2)',
  'Стратегии антикризисных коммуникаций и оценки репутационных рисков (3, 4 модули. По выбору 1 из 2)'
];

export function UpcomingDisciplines({ className }: { className?: string }) {
  return (
    <article className={className}>
      <h2 className={s.UpcomingDisciplines__heading}>Недавно посещенные</h2>
      {upcomingDisciplines.map((upcomingDiscipline) => (
        <div key={upcomingDiscipline} className={s.UpcomingDisciplines__discipline}>
          <div className={s.UpcomingDisciplines__lockIconContainer}>
            <LockIcon />
          </div>
          <div className={s.UpcomingDisciplines__disciplineTitle}>{upcomingDiscipline}</div>
        </div>
      ))}
    </article>
  );
}
