import { useMemo, useState, useEffect } from 'react';
import {
  breakpointMobileLarge,
  breakpointTabletSmall,
  breakpointTabletLarge,
  breakpointDesktopSmall,
  breakpointDesktopMedium,
  breakpointDesktopLarge
  // TODO: использовать типы из @hse-design/tokens/lib/types.
  // @ts-ignore
} from '@hse-design/tokens/lib/es/breakpoints.module';

export const mediaQuery = {
  mobileLargeDown: `(max-width: ${parseInt(breakpointMobileLarge) - 1}px)`, // 374
  tabletSmallDown: `(max-width: ${parseInt(breakpointTabletSmall) - 1}px)`, // 767
  tabletLargeDown: `(max-width: ${parseInt(breakpointTabletLarge) - 1}px)`, // 1023
  tabletLargeUp: `(min-width: ${breakpointTabletLarge})`, // 1024
  desktopSmallDown: `(max-width: ${parseInt(breakpointDesktopSmall) - 1}px)`, // 1279
  desktopSmallUp: `(min-width: ${breakpointDesktopSmall})`, // 1280
  desktopMediumDown: `(max-width: ${parseInt(breakpointDesktopMedium) - 1}px)`, // 1439
  desktopLarge: `(min-width: ${parseInt(breakpointDesktopLarge)}px)` // 1919
};

export function useMediaQueryResult(mediaQueryString: string) {
  const mediaQueryList = useMemo(() => {
    return window.matchMedia(mediaQueryString);
  }, [mediaQueryString]);

  const [queryResult, setQueryResult] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handleMediaQueryListChange = (e: MediaQueryListEvent) => setQueryResult(e.matches);

    mediaQueryList.addListener(handleMediaQueryListChange);
    return () => mediaQueryList.removeListener(handleMediaQueryListChange);
  }, [mediaQueryList]);

  return queryResult;
}
