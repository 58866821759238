import clsx from 'clsx';
import s from './QuizAttemptRow.module.scss';
import { ReactFCC } from '../../../../utils/ReactFCC';

export interface QuizAttemptHeadRowProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const QuizAttemptHeadRow: ReactFCC<QuizAttemptHeadRowProps> = (props) => {
  const { className } = props;

  return (
    <div className={clsx(s.QuizAttemptRow, s.QuizAttemptRow_head, className)}>
      <span className={clsx(s.QuizAttemptRow__item, s.QuizAttemptRow__item_head)}>Попытка</span>
      <span className={clsx(s.QuizAttemptRow__item, s.QuizAttemptRow__item_head)}>Дата</span>
      <span className={clsx(s.QuizAttemptRow__item, s.QuizAttemptRow__item_head)}>Результат</span>
    </div>
  );
};
