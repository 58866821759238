import clsx from 'clsx';
import s from './GradeTable.module.scss';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { IntrinsicPropsWithoutRef } from '@hse-design/react';

export interface GradeTableProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const GradeTable: ReactFCC<GradeTableProps> = (props) => {
  const { children, className } = props;

  return <table className={clsx(s.GradeTable, className)}>{children}</table>;
};

export enum GradeTableCellType {
  right = 'right',
  nowrap = 'nowrap',
  header = 'header',
  bold = 'bold'
}

export interface GradeTableCellProps extends IntrinsicPropsWithoutRef<'td'> {
  className?: string;
  type?: GradeTableCellType;
}

export const GradeTableData: ReactFCC<GradeTableCellProps> = ({ children, className, type, ...props }) => (
  <td className={clsx(className, type && s[`GradeTable__text_${type}`])} {...props}>
    {children}
  </td>
);
