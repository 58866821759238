import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { BreadcrumbType } from './breadcrumbs-helpers';
import s from './Breadcrumbs.module.scss';

export interface BreadcrumbsProps {
  className?: string;
  items: BreadcrumbType[];
}

export const Breadcrumbs = React.memo(({ className, items }: BreadcrumbsProps) => {
  const bcItems = items.slice();

  bcItems.unshift({
    path: '/',
    label: 'Главная'
  });

  return (
    <div className={clsx(s.Breadcrumbs, className)}>
      {bcItems.map((item, iItem, array) => {
        const isHomePage = item.path === '/';
        return (
          <Fragment key={iItem}>
            {iItem > 0 && <span className={s.Breadcrumbs__divider}>/</span>}
            {isHomePage || iItem < array.length - 1 ? (
              <Link className={s.Breadcrumbs__link} to={item.path} title={item.title || item.label}>
                {item.label}
              </Link>
            ) : (
              <span title={item.title || item.label}>{item.label}</span>
            )}
          </Fragment>
        );
      })}
    </div>
  );
});
