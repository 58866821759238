import { CompletionState } from 'store/moodleAPI/moodleTypes/Module';
import { ModQuizGetQuizRequiredQtypesData } from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_quiz_required_qtypes';
import { useAccessIsEnabled } from 'routes/quiz/QuizCompletionState/utils/useAccessIsEnabled';
import { EQuestType } from 'routes/quiz/QuizQuestions/types';

export interface IAccessOptionsProps {
  error?: string | null;
  activerulenames: string[];
  accessrules: string[];
  preventaccessreasons: string[];
  completionState?: CompletionState;
  requiredTypesData?: ModQuizGetQuizRequiredQtypesData | null;
}

const RULE_QUIZACCESS_ONESESSION = 'quizaccess_onesession';
const AVAILABLE_QUIZ_TYPES = [
  EQuestType.MULTI_CHOICE_SET,
  EQuestType.MULTI_CHOICE,
  EQuestType.DDW_TO_SHARD,
  EQuestType.MATCH,
  EQuestType.SHORT_ANSWER,
  EQuestType.TRUE_FALSE
];

export const useAccessOptions = (props: IAccessOptionsProps) => {
  const { error, activerulenames, completionState, requiredTypesData, accessrules, preventaccessreasons } = props;

  let accessDisabledOneSession =
    error ??
    (activerulenames.includes(RULE_QUIZACCESS_ONESESSION)
      ? `Данный тест не может быть пройден в приложении, потому что он имеет правила доступа, не поддерживаемые приложением: ${RULE_QUIZACCESS_ONESESSION}`
      : null);

  if (completionState !== CompletionState.Incomplete) {
    accessDisabledOneSession = null;
  }

  const accessDisabledRequiredTypesArray: string[] = [];

  requiredTypesData?.questiontypes.forEach((type) => {
    if (!AVAILABLE_QUIZ_TYPES.includes(type as EQuestType)) {
      accessDisabledRequiredTypesArray.push(type);
    }
  });

  const accessDisabledRequiredTypes = accessDisabledRequiredTypesArray.length
    ? `Данный тест не может быть пройден в приложении, потому что он имеет типы заданий, не поддерживаемые приложением: ${accessDisabledRequiredTypesArray.join(
        ', '
      )}`
    : null;

  const { accessIsEnabled, quizCloseDateReason } = useAccessIsEnabled({
    preventaccessreasons,
    accessrules
  });

  return {
    accessIsEnabled,
    accessDisabledByOptions: accessDisabledOneSession ?? accessDisabledRequiredTypes,
    quizCloseDateReason
  };
};
