import { Spinner, SpinnerSize, Error, Li, Paragraph, Heading, HeadingSize, Ol, Link } from '@hse-design/react';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { Helmet } from 'react-helmet-async';

import { useDocumentsData } from './api/useDocumentsData';
import s from './DocumentsPage.module.scss';

export const DocumentsPage = () => {
  const { sections, isLoading, error } = useDocumentsData();

  const title = 'Материалы';

  return (
    <main>
      <Helmet title={title} />
      <PageHeader className={s.DocumentsPage__header} title={title} />

      {(() => {
        if (error) {
          return <Error title="Не удалось загрузить материалы" description={error} />;
        }

        if (isLoading) {
          return <Spinner className={s.DocumentsPage__spinner} loading size={SpinnerSize.large} />;
        }

        return (
          <div className={s.ProjectsPage_content}>
            {sections.map((section) => (
              <Paragraph>
                <Heading className={s.DocumentsPage__section_title} size={HeadingSize.H4}>
                  {section.title}
                </Heading>
                <Ol>
                  {section.documents.map((document) => (
                    <Li className={s.DocumentsPage__document}>
                      {document.text}
                      {document.url && (
                        <Link className={s.DocumentsPage__sourcelink} href={document.url}>
                          {document.url}
                        </Link>
                      )}
                    </Li>
                  ))}
                </Ol>
              </Paragraph>
            ))}
          </div>
        );
      })()}
    </main>
  );
};
