import React from 'react';
import clsx from 'clsx';
import s from './Header.module.scss';
import { ReactFCC } from 'utils/ReactFCC';
import {
  Divider,
  DividerColor,
  DividerVariant,
  Header as HeaderHse,
  HeaderHamburger,
  IconButtonSize,
  IconButtonVariant,
  IconGlobalMessageFilled,
  LogoSmartLms,
  Space
} from '@hse-design/react';
import { HOME_PAGE_ROUTE } from 'routes/routes';
import { Link } from 'react-router-dom';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import { EAvatarBorderRadius } from 'components/Avatar/Avatar';
import { useSiteInfoData } from 'store/siteInfo';
import { IconButton } from '../../../../components/IconButton/IconButton';

export type THeader = {
  className?: string;
  isNavigationMenuActive: boolean;
  toggleNavigationMenu: (value?: boolean) => void;
  toggleMessages: () => void;
};

export const Header: ReactFCC<THeader> = (props) => {
  const { className, isNavigationMenuActive, toggleNavigationMenu, toggleMessages } = props;
  const siteInfoData = useSiteInfoData();

  return (
    <HeaderHse
      className={clsx(s.Header__container, className)}
      logo={
        <Link to={HOME_PAGE_ROUTE}>
          <LogoSmartLms className={s.Header__logo} style={{ height: '48px' }} />
        </Link>
      }
      prepend={
        <div className={'tablet-small-down-show'} style={{ alignItems: 'center', display: 'flex' }}>
          <HeaderHamburger isOpen={isNavigationMenuActive} onClick={() => toggleNavigationMenu()} />
          <Space className="tablet-small-only-show" horizontal size={Space.Size.small_3x} />
        </div>
      }
      right={
        <div className={s.Header__buttons}>
          <IconButton
            variant={IconButtonVariant.ghost}
            icon={IconGlobalMessageFilled}
            size={IconButtonSize.medium}
            onClick={toggleMessages}
          />
          <Divider className={s.Header__divider} variant={DividerVariant.vertical} color={DividerColor.dark} />
          <UserAvatar borderRadius={EAvatarBorderRadius.M} size={24} src={siteInfoData.userpictureurl} />
        </div>
      }
    />
  );
};
