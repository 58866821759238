import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Error, SpinnerSize } from '@hse-design/react';
import { useCourseData } from 'routes/discipline/api/useCourseData';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { Spinner } from 'components/Spinner/Spinner';
import { ActivitiesSection } from 'components/Activities';
import { useCourseActivities } from 'store/moodleAPI/courseActivities/useCourseActivities';
import { useCurators } from './api/useCurators';
import { CuratorList } from './CuratorList/CuratorList';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { getDisciplinePageBreadcrumbs } from './disciplinePage-breadcrumbs';
import s from './DisciplinePage.module.scss';
import { EActivityState } from '../../store/moodleAPI/courseActivities/activityTypes';

export function DisciplinePage() {
  const { courseId } = useParams<{
    courseId?: string;
  }>();

  const { activitySections, isLoading: activitiesIsLoading, error: activitiesError } = useCourseActivities(courseId);

  const { course, description, isLoading: courseLoading, error: courseError } = useCourseData(courseId);

  const breadcrumbs = useMemo(() => {
    if (!course) return [];
    return getDisciplinePageBreadcrumbs({ course: { id: course.id, name: course.displayname } });
  }, [course]);

  const curators = useCurators(courseId);

  const error = courseError || activitiesError;

  if (error) {
    return <Error title="Не удалось загрузить информацию о курсе" description={error} />;
  }

  const title = course?.displayname;

  return (
    <main className={clsx(s.DisciplinePage__container, courseLoading && s.DisciplinePage__container_centered)}>
      {!courseLoading ? (
        <>
          <Helmet title={title} />

          <div className={s.DisciplinePage__headerContainer}>
            <PageHeader className={s.DisciplinePage__header} title={title} />

            {description && <MoodleContent className={s.DisciplinePage__description}>{description}</MoodleContent>}

            <Breadcrumbs className={s.DisciplinePage__bc} items={breadcrumbs} />
          </div>

          {activitySections?.map((section, index) => {
            const modules = section.activities.flatMap((i) => i.items);

            // Делаем секцию по умолчанию открытой, если есть активные элементы
            const someActive = modules.some(
              (activity) => activity.state !== EActivityState.CLOSE && activity.state !== EActivityState.CHECK
            );

            return (
              <ActivitiesSection
                section={section}
                isLoading={activitiesIsLoading}
                isOpenDefault={someActive}
                key={index}
              />
            );
          })}

          <CuratorList curators={curators} />
        </>
      ) : (
        <Spinner loading size={SpinnerSize.XL} />
      )}
    </main>
  );
}
