import React from 'react';
import { Navigate } from 'react-router-dom';
import { parseAuthFragment } from 'utils/parseAuthFragment';
import { EAuthError } from 'store/auth';
import { AuthContext, IAuthContext } from 'store/auth/AuthContext';
import { LOCAL_STORAGE_KEY_NEXT } from 'store/localStorageKeys';
import { LOGIN_PAGE_ROUTE } from '../routes';

// техническая страница, используемая ADFS в качестве целевой
// классовый компонент используется для установки токена -ДО- первого рендера
export class AuthPage extends React.Component {
  // AuthContext
  static contextType = AuthContext;
  // declare context: IAuthContext;
  context!: IAuthContext;

  constructor(props: {}, context: IAuthContext) {
    super(props, context);

    if (window.location.hash) {
      const { accessToken, idToken, expires } = parseAuthFragment(window.location.hash);
      if (!accessToken || !idToken || !expires) {
        // случай ошибки
        context.logOut(EAuthError.ADFS_AUTH_FAILED);
      } else {
        context.setToken(accessToken, idToken, expires);
      }
    } else {
      // пустой hash – случай полного выхода из ADFS (либо ручного перехода)
      context.setToken(null);
    }
  }

  render() {
    const next = localStorage.getItem(LOCAL_STORAGE_KEY_NEXT) || '/';
    return (
      // передает LoginPage ответственность за правильный дальнейший redirect
      <Navigate to={`${LOGIN_PAGE_ROUTE}?next=${next}`} replace />
    );
  }
}
