import React, { useMemo, useState } from 'react';
import { Select, SelectSize } from '@hse-design/react';
import { isSameDay } from 'date-fns';
import { CalendarBaseProps } from 'components/calendar/calendar.types';
import { MonthCalendar } from 'components/calendar/MonthCalendar/MonthCalendar';
import { DayCalendar } from 'components/calendar/DayCalendar/DayCalendar';
import s from './CalendarCard.module.scss';

enum CalendarView {
  Month = 'month',
  Day = 'day'
}

const calendarViewSelectOptions = [
  {
    value: CalendarView.Month,
    label: 'Месяц'
  },
  {
    value: CalendarView.Day,
    label: 'День'
  }
];

export interface CalendarCardProps extends CalendarBaseProps {
  title?: string;
  loading: boolean;
}

export const CalendarCard = ({ title, loading, events, selectedDate, ...calendarProps }: CalendarCardProps) => {
  const [view, setView] = useState(CalendarView.Month);

  const handleDayCellClick = (date: Date) => {
    calendarProps.onDateChange?.(date);
    setView(CalendarView.Day);
  };

  const dayEvents = useMemo(() => {
    if (!selectedDate || !events?.length) return [];
    return events.filter((event) => isSameDay(event.date_start, selectedDate));
  }, [events, selectedDate]);

  return (
    <div className={s.CalendarCard}>
      <div className={s.CalendarCard__head}>
        <Select
          className={s.CalendarCard__viewSelect}
          value={view}
          options={calendarViewSelectOptions}
          onChangeOption={(option) => setView(option?.value as CalendarView)}
          size={SelectSize.small}
          clearable={false}
        />
      </div>

      {view === CalendarView.Month && (
        <MonthCalendar
          events={events}
          selectedDate={selectedDate}
          {...calendarProps}
          loading={loading}
          onDayCellClick={handleDayCellClick}
        />
      )}
      {view === CalendarView.Day && (
        <DayCalendar events={dayEvents} selectedDate={selectedDate} {...calendarProps} loading={loading} />
      )}
    </div>
  );
};
