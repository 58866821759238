import React from 'react';
import { Link } from 'react-router-dom';
import { ReactFCC } from 'utils/ReactFCC';
import { Button, LinkVariant, Paragraph, SpinnerPosition, SpinnerSize } from '@hse-design/react';
import { QuizCard } from 'routes/quiz/QuizCard/QuizCard';
import { EActivityType, IActivityItem } from 'store/moodleAPI/courseActivities/activityTypes';
import { CompletionState } from 'store/moodleAPI/moodleTypes/Module';
import { IAttempt } from 'store/moodleAPI/moodleTypes/Attempt';
import { Spinner } from 'components/Spinner/Spinner';
import { Anchor, EAnchorSize } from 'components/Anchor/Anchor';
import { ModQuizGetQuizAccessInformationData } from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_quiz_access_information';
import { ModQuizGetQuizRequiredQtypesData } from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_quiz_required_qtypes';
import { useAccessOptions } from 'routes/quiz/QuizCompletionState/utils/useAccessOptions';
import s from './QuizCompletionState.module.scss';

export interface IQuizCompletionStateProps {
  nextActivityHref?: string | null;
  accessAttemptReview?: boolean;
  lesson?: IActivityItem;
  isLoading?: boolean;
  lastAttemptId?: number;
  error?: string | null;
  buttonSpinner?: boolean;
  onStartQuiz: () => void;
  quiz?: {
    id: number;
    grade?: number | null;
    accessData: ModQuizGetQuizAccessInformationData | null;
    url?: string;
    completiondata?: {
      state: CompletionState;
    };
    requiredTypesData?: ModQuizGetQuizRequiredQtypesData | null;
    inProgressAttempt?: IAttempt | null;
  };
}

export const QuizCompletionState: ReactFCC<IQuizCompletionStateProps> = (props) => {
  const {
    nextActivityHref,
    lesson,
    isLoading,
    buttonSpinner,
    onStartQuiz,
    error,
    quiz,
    lastAttemptId,
    accessAttemptReview
  } = props;

  const completionState = quiz?.completiondata?.state;

  const { accessData, url: quizUrl, grade, requiredTypesData, inProgressAttempt } = quiz ?? {};

  const {
    accessrules,
    preventaccessreasons,
    activerulenames
  }: Pick<ModQuizGetQuizAccessInformationData, 'preventaccessreasons' | 'activerulenames' | 'accessrules'> =
    accessData ?? {
      preventaccessreasons: [],
      activerulenames: [],
      accessrules: []
    };

  const { accessDisabledByOptions, quizCloseDateReason, accessIsEnabled } = useAccessOptions({
    error,
    requiredTypesData,
    completionState,
    activerulenames,
    accessrules,
    preventaccessreasons
  });

  let lessonTitle = '';

  if (lesson?.type === EActivityType.LECTURE) {
    lessonTitle = 'к лекции';
  } else if (lesson?.type === EActivityType.SEMINAR) {
    lessonTitle = 'к семинару';
  }

  const buttonLink = (!accessIsEnabled || error) &&
    !accessDisabledByOptions &&
    (nextActivityHref || lesson) && {
      to: nextActivityHref ?? lesson?.href ?? '',
      text: nextActivityHref ? 'Вперед' : `Вернуться ${lessonTitle}`
    };

  if (isLoading) {
    return <Spinner loading size={SpinnerSize.large} />;
  }

  if (error) {
    return (
      <QuizCard>
        <div className={s.QuizCompletionState__content}>
          <Paragraph className={s.QuizCompletionState__text}>{error}</Paragraph>
        </div>
        {buttonLink ? (
          <Button component={Link} to={buttonLink.to} className={s.QuizCompletionState__button}>
            {buttonLink.text}
          </Button>
        ) : null}
      </QuizCard>
    );
  }

  const attemptReview =
    typeof grade === 'number' || (accessAttemptReview && lastAttemptId && quiz?.id) ? (
      <div className={s.QuizCompletionState__review}>
        {typeof grade === 'number' && <span>Оценка: {Math.round(grade * 100) / 100}</span>}

        {accessAttemptReview && lastAttemptId && quiz?.id && (
          <Anchor
            className={s.QuizCompletionState__buttonReview}
            href={`https://edu.hse.ru/mod/quiz/review.php?attempt=${lastAttemptId}&cmid=${quiz.id}`}
            target={'_blank'}
            variant={LinkVariant.primary}
            size={EAnchorSize.MEDIUM}>
            Просмотр
          </Anchor>
        )}
      </div>
    ) : null;

  return (
    <QuizCard>
      <div className={s.QuizCompletionState__content}>
        {completionState === CompletionState.CompletePass && (
          <>
            <Paragraph className={s.QuizCompletionState__text}>
              Поздравляем, тест пройден.
              <br />
              {nextActivityHref && 'Вы можете переходить к следующему блоку'}
            </Paragraph>

            {attemptReview && <div className={s.QuizCompletionState__text}>{attemptReview}</div>}
          </>
        )}

        {completionState !== CompletionState.CompletePass && (
          <>
            {(completionState === CompletionState.CompleteFail || quizCloseDateReason) && (
              <>
                <Paragraph className={s.QuizCompletionState__text}>
                  К сожалению, тест не пройден.
                  <br />
                  {accessIsEnabled && 'Необходимо пройти его заново'}
                </Paragraph>
                {attemptReview && <div className={s.QuizCompletionState__text}>{attemptReview}</div>}
              </>
            )}

            {!accessIsEnabled && quizCloseDateReason ? (
              <Paragraph className={s.QuizCompletionState__text}>{quizCloseDateReason}</Paragraph>
            ) : (
              preventaccessreasons.map((reason, idx) => (
                <Paragraph key={idx} className={s.QuizCompletionState__text}>
                  {reason}
                </Paragraph>
              ))
            )}

            {accessIsEnabled &&
              [...accessrules, accessDisabledByOptions].map((rule, idx) => {
                return (
                  rule && (
                    <Paragraph key={idx} className={s.QuizCompletionState__text}>
                      {rule}
                    </Paragraph>
                  )
                );
              })}
          </>
        )}
      </div>

      {accessDisabledByOptions && quizUrl && (
        <Button component={'a'} href={quizUrl} target={'_blank'} className={s.QuizCompletionState__button}>
          Открыть в браузере
        </Button>
      )}

      {!accessDisabledByOptions && accessIsEnabled ? (
        <Button
          className={s.QuizCompletionState__button}
          disabled={buttonSpinner}
          spinner={buttonSpinner}
          spinnerPosition={SpinnerPosition.right}
          onClick={onStartQuiz}>
          {inProgressAttempt
            ? 'Продолжить последнюю попытку'
            : completionState === CompletionState.Incomplete
            ? 'Начать тестирование'
            : 'Пройти заново'}
        </Button>
      ) : buttonLink ? (
        <Button component={Link} to={buttonLink.to} className={s.QuizCompletionState__button}>
          {buttonLink.text}
        </Button>
      ) : null}
    </QuizCard>
  );
};
