import React from 'react';

import clsx from 'clsx';

import { Space } from '@hse-design/react';

import s from './Footer.module.scss';
import { ReactFCC } from 'utils/ReactFCC';
import { mediaQuery, useMediaQueryResult } from '../../../../hooks/useMediaQueryResult';

type TFooterColumnItem = {
  info: string;
  href?: string;
};

type TFooterColumn = {
  items: TFooterColumnItem[];
  title: string;
};

const footerContent: TFooterColumn[] = [
  {
    items: [
      {
        info: 'Служба поддержки для студентов',
        href: 'https://pmo.hse.ru/servicedesk/customer/portal/89'
      },
      {
        info: 'Служба поддержки для сотрудников',
        href: 'https://pmo.hse.ru/servicedesk/customer/portal/81/create/773'
      }
    ],
    title: 'Техническая поддержка'
  }
];

type TFooter = {
  className?: string;
};

export const Footer: ReactFCC<TFooter> = (props) => {
  const { className } = props;

  const isMobile = useMediaQueryResult(mediaQuery.tabletLargeDown);

  return (
    <div className={clsx(s.Footer__container, isMobile && s.Footer__container_mobile, className)}>
      {footerContent.map((col, i) => (
        <div className={s.Footer__colContainer} key={`Footer-col-Fragment-${i}`}>
          <div className={s.Footer__col}>
            <div className={s.Footer__colTitle}>{col.title}</div>

            {col.items.length && (
              <div className={s.Footer__colItems}>
                {col.items.map((item, index) =>
                  item.href ? (
                    <a
                      className={s.Footer__colItem}
                      href={item.href}
                      target={'_blank'}
                      key={index}
                      tabIndex={0}
                      rel="noreferrer">
                      {item.info}
                    </a>
                  ) : (
                    <div className={s.Footer__colItem} key={index}>
                      {item.info}
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          {i + 1 !== footerContent.length && (
            <Space
              horizontal={!isMobile}
              size={isMobile ? Space.Size.small_x : Space.Size.medium_x}
              vertical={isMobile}
            />
          )}
        </div>
      ))}
    </div>
  );
};
