import { createContext } from 'react';
import { EAuthError } from './authErrors';

export interface IAuthData {
  accessToken: string | null;
  idToken: string | null;
  expires: number;
}

export interface IAuthContext {
  authData: IAuthData;
  error: EAuthError | null;
  isLoggedIn: () => boolean;
  setToken: (accessToken: string | null, idToken?: string | null, expires?: number) => void;
  getToken: () => Promise<string | null>;
  logOut: (error?: EAuthError) => void;
}

export const AuthContext = createContext<IAuthContext | null>(null);
