import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { CalendarEventType } from '../calendar.types';
import { isSameDay } from 'date-fns';
import s from './MonthCalendar.module.scss';

export interface MonthCalendarEventProps extends CalendarEventType {
  className?: string;
  onClick?: MouseEventHandler;
}

export const MonthCalendarEvent = ({ className, name, date_start, date_end, onClick }: MonthCalendarEventProps) => {
  const isMultiDayLong = !!date_end && !isSameDay(new Date(date_start), new Date(date_end));

  return (
    <div className={clsx(s.MonthCalendarEvent, className)} title={name} onClick={onClick}>
      <div
        className={clsx(s.MonthCalendarEvent__indicator, {
          [s.MonthCalendarEvent__indicator_multiDay]: isMultiDayLong
        })}
      />
      <div className={s.MonthCalendarEvent__name}>{name}</div>
    </div>
  );
};
