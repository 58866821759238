import clsx from 'clsx';
import s from './QuizAttemptRow.module.scss';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { IAttempt } from '../../../../store/moodleAPI/moodleTypes/Attempt';
import { EMDASH } from '@proscom/ui-utils';
import { format } from 'date-fns';

export interface QuizAttemptRowProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Объект попытки тестирования
   */
  attempt: Pick<IAttempt, 'attempt' | 'timefinish' | 'sumgrades'>;
}

export const QuizAttemptRow: ReactFCC<QuizAttemptRowProps> = (props) => {
  const { className, attempt } = props;

  return (
    <div className={clsx(s.QuizAttemptRow, className)}>
      <span className={s.QuizAttemptRow__item}>{attempt.attempt}</span>
      <span className={s.QuizAttemptRow__item}>
        {attempt.timefinish ? format(new Date(attempt.timefinish * 1000), 'dd.MM.yyyy') : EMDASH}
      </span>
      <span className={s.QuizAttemptRow__item}>{attempt.sumgrades ? attempt.sumgrades.toFixed(2) : EMDASH}</span>
    </div>
  );
};
