// парсит фрагмент-ответ от ADFS (#access_token=...)
export function parseAuthFragment(authFragment: string) {
  const authResponse = new URLSearchParams(authFragment.substring(1));

  const accessToken = authResponse.get('access_token');
  const idToken = authResponse.get('id_token');
  const expiresIn = authResponse.get('expires_in');
  const expires = expiresIn ? Date.now() + +expiresIn * 1000 : null;
  const error = authResponse.get('error');

  return { accessToken, idToken, expires, error };
}
