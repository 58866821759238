import React from 'react';

import clsx from 'clsx';
import { ReactFCC } from 'utils/ReactFCC';
import s from './DecorVertical.module.scss';

type TDecorVertical = {
  className?: string;
};

export const DecorVertical: ReactFCC<TDecorVertical> = (props) => {
  const { className } = props;

  return <div className={clsx(s.DecorVertical__container, className)}></div>;
};
