import { replicateArr } from 'utils/replicateArr';
import { TDecorMain, TRowPattern } from 'components/DecorMain/DecorMain';
import { EIconsPatterns } from 'components/IconsFactory/Icons';

const patternsRow1: TRowPattern['patterns'] = [
  EIconsPatterns.PATTERN_SQUARE_8,
  EIconsPatterns.PATTERN_SQUARE_8,
  EIconsPatterns.PATTERN_SQUARE_4,
  EIconsPatterns.PATTERN_SQUARE_2,
  EIconsPatterns.PATTERN_SQUARE_7,
  EIconsPatterns.PATTERN_SQUARE_6,
  EIconsPatterns.PATTERN_SQUARE_1,
  EIconsPatterns.PATTERN_SQUARE_8,
  EIconsPatterns.PATTERN_SQUARE_2,
  EIconsPatterns.PATTERN_SQUARE_3,
  EIconsPatterns.PATTERN_SQUARE_3
];

const patternsRow2: TRowPattern['patterns'] = [
  EIconsPatterns.PATTERN_SQUARE_4,
  EIconsPatterns.PATTERN_SQUARE_2,
  EIconsPatterns.PATTERN_SQUARE_7,
  EIconsPatterns.PATTERN_SQUARE_5,
  EIconsPatterns.PATTERN_SQUARE_1,
  EIconsPatterns.PATTERN_SQUARE_8,
  EIconsPatterns.PATTERN_SQUARE_2,
  EIconsPatterns.PATTERN_SQUARE_3,
  EIconsPatterns.PATTERN_SQUARE_8,
  EIconsPatterns.PATTERN_SQUARE_8,
  EIconsPatterns.PATTERN_SQUARE_8
];

const patternsRow1Reverse: TRowPattern['patterns'] = [...patternsRow1].reverse().map((item, i) => {
  if (i === 5) {
    return EIconsPatterns.PATTERN_SQUARE_5;
  }

  return item;
});
const patternsRow2Reverse: TRowPattern['patterns'] = [...patternsRow2].reverse().map((item, i) => {
  if (i === 7) {
    return EIconsPatterns.PATTERN_SQUARE_9;
  }

  return item;
});

const patternsArrTopSmall: TRowPattern['patterns'] = [
  EIconsPatterns.PATTERN_SQUARE_4,
  EIconsPatterns.PATTERN_SQUARE_2,
  EIconsPatterns.PATTERN_SQUARE_7,
  EIconsPatterns.PATTERN_SQUARE_5,
  EIconsPatterns.PATTERN_SQUARE_1
];

const patternsArrBottomSmall: TRowPattern['patterns'] = [...patternsArrTopSmall].reverse();

/**
 * Templates are multiplied for safety if the screen is too wide
 */
export const patternsTop: TDecorMain['rowsPatterns'] = [
  {
    patterns: replicateArr(patternsRow1, 4),
    row: 0
  },
  {
    patterns: replicateArr(patternsRow2, 4),
    row: 1
  }
];

/**
 * Templates are multiplied for safety if the screen is too wide
 */
export const patternsTopSmall: TDecorMain['rowsPatterns'] = [
  {
    patterns: replicateArr(patternsArrTopSmall, 4),
    row: 0
  }
];

export const patternsBottomSmall: TDecorMain['rowsPatterns'] = [
  {
    patterns: replicateArr(patternsArrBottomSmall, 4),
    row: 0
  }
];

/**
 * Templates are multiplied for safety if the screen is too wide
 */
export const patternsBottom: TDecorMain['rowsPatterns'] = [
  {
    patterns: replicateArr(patternsRow2Reverse, 4),
    row: 0
  },
  {
    patterns: replicateArr(patternsRow1Reverse, 4),
    row: 1
  }
];
