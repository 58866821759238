import React from 'react';
import { ReactFCC } from 'utils/ReactFCC';
import clsx from 'clsx';
import { Outlet } from 'react-router-dom';
import { mediaQuery, useMediaQueryResult } from 'hooks/useMediaQueryResult';
import { DecorVertical } from 'components/DecorVertical/DecorVertical';
import s from './AsideLayout.module.scss';

export interface IAsideLayoutProps {
  beforeContainerChildren?: React.ReactNode;
  afterContainerChildren?: React.ReactNode;
  asideChildren?: React.ReactNode;
  asideContentClassName?: string;
}

export const AsideLayout: ReactFCC<IAsideLayoutProps> = (props) => {
  const { beforeContainerChildren, afterContainerChildren, asideChildren, asideContentClassName, children } = props;

  const isDesktop = useMediaQueryResult(mediaQuery.desktopSmallUp);

  return (
    <div className={s.AsideLayout}>
      {beforeContainerChildren}

      <div className={s.AsideLayout__outletContainer}>{children || <Outlet />}</div>
      {afterContainerChildren}

      {isDesktop && (
        <aside className={s.AsideLayout__sidebar}>
          <DecorVertical className={s.AsideLayout__sidebarDecor} />
          {asideChildren && <div className={clsx(s.AsideLayout__content, asideContentClassName)}>{asideChildren}</div>}
        </aside>
      )}
    </div>
  );
};
