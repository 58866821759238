import React, { useMemo } from 'react';
import clsx from 'clsx';
import { DecorMain } from 'components/DecorMain/DecorMain';
import { useMediaQueryResult } from 'hooks/useMediaQueryResult';
import { ReactFCC } from 'utils/ReactFCC';
import { patternsBottom, patternsBottomSmall, patternsTop, patternsTopSmall } from './patterns';

import s from './LoginDecor.module.scss';

type TLoginDecor = {
  className?: string;
  isTop?: boolean;
};

export const LoginDecor: ReactFCC<TLoginDecor> = (props) => {
  const { className, isTop } = props;

  const isSmallHeight = useMediaQueryResult(`(max-height: 768px)`);

  const rows: number = useMemo(() => {
    if (isSmallHeight) {
      return 1;
    }

    return 2;
  }, [isSmallHeight]);

  const rowPatterns = useMemo(() => {
    if (isSmallHeight) {
      if (isTop) {
        return patternsTopSmall;
      }

      return patternsBottomSmall;
    }

    if (isTop) {
      return patternsTop;
    }

    return patternsBottom;
  }, [isSmallHeight, isTop]);

  return (
    <DecorMain
      className={clsx(s.LoginDecor__decor, className)}
      classNames={{ classNameCell: clsx(!isTop && s.LoginDecor__cell_reverse) }}
      rows={rows}
      rowsPatterns={rowPatterns}
    />
  );
};
