import React, { ElementType } from 'react';
import { IconButton as IconButtonHse, ButtonDefaultComponentType, IconButtonProps } from '@hse-design/react';
import clsx from 'clsx';

export enum IconButtonSurface {
  light = 'light',
  dark = 'dark'
}

export type TIconButton<ComponentType extends ElementType> = IconButtonProps<ComponentType> & {
  surface?: IconButtonSurface;
};

export function IconButton<ComponentType extends ElementType = ButtonDefaultComponentType>(
  props: TIconButton<ComponentType>
) {
  const { surface = IconButtonSurface.light, className, ...iconButtonHseProps } = props;
  return <IconButtonHse className={clsx(`hse-IconButton_surface_${surface}`, className)} {...iconButtonHseProps} />;
}
