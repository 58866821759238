export const userList = [
  'edu_test1@hse.ru',
  'gosha.fiend@yandex.ru',
  'ev.bun@hse.ru',
  'aminakova@hse.ru',
  'mariya.grigoreva@hse.ru',
  'gseregin@hse.ru',
  'ag.smirnov@hse.ru',
  'k.kasyanova@hse.ru',
  'opeskova@hse.ru',
  'rnesterenko@hse.ru',
  'gasoyan@hse.ru',

  'evkornilov@edu.hse.ru',
  'kaksenofontov@edu.hse.ru',
  'ivpidluzhnaya@edu.hse.ru',
  'lagerget@edu.hse.ru',
  'almaltseva@edu.hse.ru',
  'ayudenisova@edu.hse.ru',
  'aaabyazova@edu.hse.ru',
  'asandriianova@edu.hse.ru',
  'gbasanova@edu.hse.ru',
  'nibaklanov@edu.hse.ru',
  'dgbezrukov@edu.hse.ru',
  'nabelousov@edu.hse.ru',
  'dsvereikina@edu.hse.ru',
  'vavizgalova@edu.hse.ru',
  'analvlasov@edu.hse.ru',
  'gmvorobev@edu.hse.ru',
  'lrgasanova@edu.hse.ru',
  'msgornostaev@edu.hse.ru',
  'mvgromchenko@edu.hse.ru',
  'avdemochka@edu.hse.ru',
  'dadzyuba@edu.hse.ru',
  'nvdmitriev@edu.hse.ru',
  'apevgrafova@edu.hse.ru',
  'iazavialova@edu.hse.ru',
  'vvzastupnevich@edu.hse.ru',
  'igzakharova@edu.hse.ru',
  'rvivannikov@edu.hse.ru',
  'tvimkin@edu.hse.ru',
  'aniukazakov@edu.hse.ru',
  'vkkalinichenko@edu.hse.ru',
  'dmklenin@edu.hse.ru',
  'makolodezneva@edu.hse.ru',
  'dakostyshin@edu.hse.ru',
  'mmlaptev@edu.hse.ru',
  'mvlepsaia@edu.hse.ru',
  'aglisavina@edu.hse.ru',
  'epmakashina@edu.hse.ru',
  'tsmaleev@edu.hse.ru',
  'tnmamedov@edu.hse.ru',
  'mvmatskepladze@edu.hse.ru',
  'aomilykh@edu.hse.ru',
  'epnemchinova@edu.hse.ru',
  'nvnikulina@edu.hse.ru',
  'dsondar@edu.hse.ru',
  'nvpampukha@edu.hse.ru',
  'evevpetrov@edu.hse.ru',
  'rvponomar@edu.hse.ru',
  'eiredko@edu.hse.ru',
  'mamiromanova@edu.hse.ru',
  'masaprykin@edu.hse.ru',
  'irsaribekova@edu.hse.ru',
  'rasafonov@edu.hse.ru',
  'mdsolovev@edu.hse.ru',
  'irtalachev@edu.hse.ru',
  'avtarakanov@edu.hse.ru',
  'ivtoporkova@edu.hse.ru',
  'datrifonov@edu.hse.ru',
  'motrykin@edu.hse.ru',
  'vivichernikova@edu.hse.ru',
  'mmshagrov@edu.hse.ru',

  'vsabdurakhmanov@edu.hse.ru',
  'anruazizov@edu.hse.ru',
  'analandreeva@edu.hse.ru',
  'rraiupov@edu.hse.ru',
  'esbalashova@edu.hse.ru',
  'ambasak@edu.hse.ru',
  'srbatyrshina@edu.hse.ru',
  'snbelova@edu.hse.ru',
  'vabokov@edu.hse.ru',
  'aebykov@edu.hse.ru',
  'imgrinina@edu.hse.ru',
  'ivmidavydov@edu.hse.ru',
  'kedzhamgaev@edu.hse.ru',
  'midiumin@edu.hse.ru',
  'gazamakhshariev@edu.hse.ru',
  'mszelenova@edu.hse.ru',
  'dkzinchin@edu.hse.ru',
  'rikuzmin@edu.hse.ru',
  'aokurbatova@edu.hse.ru',
  'dglev@edu.hse.ru',
  'ammamedova@edu.hse.ru',
  'esnavetkina@edu.hse.ru',
  'nanaletova@edu.hse.ru',
  'lanogaeva@edu.hse.ru',
  'andmorlova@edu.hse.ru',
  'avpatrikeev@edu.hse.ru',
  'vesepetrova@edu.hse.ru',
  'mipirogova@edu.hse.ru',
  'raprianikov@edu.hse.ru',
  'adpcheliakov@edu.hse.ru',
  'nnrazumovskaia@edu.hse.ru',
  'pasobakinskaya@edu.hse.ru',
  'avsuftina@edu.hse.ru',
  'aataliarenko@edu.hse.ru',
  'amfitisov@edu.hse.ru',
  'ivshimanskii@edu.hse.ru',
  'lkustovinova@hse.ru',

  'rragaeva@edu.hse.ru',
  'daanzhenko@edu.hse.ru',
  'veanzhirova@edu.hse.ru',
  'avanikina@edu.hse.ru',
  'kdantonenko@edu.hse.ru',
  'ddantonova@edu.hse.ru',
  'vsartemkin@edu.hse.ru',
  'evastakhova@edu.hse.ru',
  'vrakhmadulin@edu.hse.ru',
  'kabaybakov@edu.hse.ru',
  'tabaranchikova@edu.hse.ru',
  'adberkovich@edu.hse.ru',
  'mobroyan@edu.hse.ru',
  'umburlakova@edu.hse.ru',
  'vavilkova@edu.hse.ru',
  'vmvinnik@edu.hse.ru',
  'eavlasova_4@edu.hse.ru',
  'eavronskaia@edu.hse.ru',
  'pmgavrilova@edu.hse.ru',
  'dsgaikalova@edu.hse.ru',
  'gigeorgian@edu.hse.ru',
  'dvgibenkova@edu.hse.ru',
  'misegolovina@edu.hse.ru',
  'iagoliatov_1@edu.hse.ru',
  'ivgorbunova@edu.hse.ru',
  'kogorkov@edu.hse.ru',
  'avgracheva_1@edu.hse.ru',
  'eagrinfeld@edu.hse.ru',
  'mbgrishina@edu.hse.ru',
  'emdavletkhanova_1@edu.hse.ru',
  'erdanilina@edu.hse.ru',
  'eedzhafarova@edu.hse.ru',
  'gadmitriev@edu.hse.ru',
  'aedmitrieva@edu.hse.ru',
  'addragun@edu.hse.ru',
  'kadremova@edu.hse.ru',
  'aadutova_1@edu.hse.ru',
  'madushicheva@edu.hse.ru',
  'aezhadiaeva@edu.hse.ru',
  'tmzhumabaev@edu.hse.ru',
  'mgzemskova@edu.hse.ru',
  'bbzlobin@edu.hse.ru',
  'vealivanova@edu.hse.ru',
  'avivchenko@edu.hse.ru',
  'vamaigoshina@edu.hse.ru',
  'dmiliy@edu.hse.ru',
  'aailina_9@edu.hse.ru',
  'eviukazakova@edu.hse.ru',
  'amkapralov@edu.hse.ru',
  'nakapustin@edu.hse.ru',
  'mokarabash@edu.hse.ru',
  'dmkarimov@edu.hse.ru',
  'lkkarpova@edu.hse.ru',
  'vvkasatkin@edu.hse.ru',
  'vrkakhirova@edu.hse.ru',
  'vdkvashin@edu.hse.ru',
  'oakirillov@edu.hse.ru',
  'kdkiselev@edu.hse.ru',
  'nvkitova@edu.hse.ru',
  'maankniazeva@edu.hse.ru',
  'sskovalenko@edu.hse.ru',
  'aakokovikhin@edu.hse.ru',
  'vvkolnogorov@edu.hse.ru',
  'anasalekkorshunova@edu.hse.ru',
  'nvkostenko_1@edu.hse.ru',
  'evkosykh@edu.hse.ru',
  'atkosykh@edu.hse.ru',
  'amkrasilnikova@edu.hse.ru',
  'iokrasilnikova@edu.hse.ru',
  'elalkrylova@edu.hse.ru',
  'makuznetsova_10@edu.hse.ru',
  'aikulagina@edu.hse.ru',
  'alankulagina@edu.hse.ru',
  'ekallazareva@edu.hse.ru',
  'avlarionova@edu.hse.ru',
  'alealblebedeva@edu.hse.ru',
  'aalovkina@edu.hse.ru',
  'idlogvinova@edu.hse.ru',
  'adluzgina@edu.hse.ru',
  'lklutskan@edu.hse.ru',
  'poanlysenko@edu.hse.ru',
  'aimazaraki@edu.hse.ru',
  'alolmalysheva@edu.hse.ru',
  'ismartynova@edu.hse.ru',
  'vamatytsin@edu.hse.ru',
  'vamakhanov@edu.hse.ru',
  'vemelnikova@edu.hse.ru',
  'asmizar@edu.hse.ru',
  'pmmikhailik@edu.hse.ru',
  'gimishin@edu.hse.ru',
  'lsmogilevskaia@edu.hse.ru',
  'ansemoiseenko@edu.hse.ru',
  'azmutsoev@edu.hse.ru',
  'egannesterov@edu.hse.ru',
  'oanefedova@edu.hse.ru',
  'ksvlnikitina@edu.hse.ru',
  'alosipov@edu.hse.ru',
  'ippavlovskii@edu.hse.ru',
  'almipanova@edu.hse.ru',
  'iuanparfenov@edu.hse.ru',
  'erkhlebnikova@edu.hse.ru',
  'kapokidov@edu.hse.ru',
  'msraevnev@edu.hse.ru',
  'mmreshchikov@edu.hse.ru',
  'iuigromanova@edu.hse.ru',
  'iaromanycheva@edu.hse.ru',
  'anvlrudenko@edu.hse.ru',
  'amrybina@edu.hse.ru',
  'annaaleksavina@edu.hse.ru',
  'eisamieva@edu.hse.ru',
  'vesviridov@edu.hse.ru',
  'naselezneva@edu.hse.ru',
  'miseliverstova@edu.hse.ru',
  'insergeeva@edu.hse.ru',
  'emsidinko@edu.hse.ru',
  'svsmoliakov@edu.hse.ru',
  'sdsobolev@edu.hse.ru',
  'vvstolobkov@edu.hse.ru',
  'distoian@edu.hse.ru',
  'elvastreltsova@edu.hse.ru',
  'aosubbotina@edu.hse.ru',
  'isurvillo@edu.hse.ru',
  'alalsukharev@edu.hse.ru',
  'ootereshina@edu.hse.ru',
  'sktolstikov@edu.hse.ru',
  'aatregubenko@edu.hse.ru',
  'agtriasunov@edu.hse.ru',
  'avturetskaia@edu.hse.ru',
  'avturkina_1@edu.hse.ru',
  'aauglinskikh@edu.hse.ru',
  'sovifedorenko@edu.hse.ru',
  'edfeschenko@edu.hse.ru',
  'oafilatova@edu.hse.ru',
  'ekhanakhian@edu.hse.ru',
  'askhlebnikova@edu.hse.ru',
  'evalkhokhlova@edu.hse.ru',
  'aakhokhryakova@edu.hse.ru',
  'ischebotareva@edu.hse.ru',
  'iichernomorchenko@edu.hse.ru',
  'avchirgun@edu.hse.ru',
  'aralchistiakova@edu.hse.ru',
  'mnshilan@edu.hse.ru',
  'mvshipilova@edu.hse.ru',
  'lashpenkova@edu.hse.ru',
  'doshuklina@edu.hse.ru',
  'svshchebenkova@edu.hse.ru',
  'ivyanushis@edu.hse.ru',
  'ediakhontova@edu.hse.ru'
];
