import React, { MouseEventHandler, useMemo } from 'react';
import clsx from 'clsx';
import { MonthCalendarDateType } from '../calendar.types';
import { MonthCalendarEvent } from './MonthCalendarEvent';
import s from './MonthCalendar.module.scss';

export interface MonthCalendarDateProps extends MonthCalendarDateType {
  className?: string;
  isMobile?: boolean;
  onClick?: MouseEventHandler;
  onEventClick?: (id: number) => void;
}

export const MonthCalendarDate = ({
  className,
  date,
  selected,
  weekend,
  otherMonth,
  events,
  isMobile,
  onClick,
  onEventClick
}: MonthCalendarDateProps) => {
  const dayDate = date.getDate();

  const handleEventClick = (id: number, e: React.MouseEvent) => {
    e.stopPropagation();
    onEventClick?.(id);
  };

  const eventsAmount = events?.length || 0;

  const eventsToShow = useMemo(() => {
    if (!events?.length) return [];
    return events.slice(0, events.length > 5 ? 4 : 5);
  }, [events]);

  return (
    <div
      className={clsx(s.MonthCalendarDate, className, {
        [s.MonthCalendarDate_selected]: selected,
        [s.MonthCalendarDate_weekend]: weekend,
        [s.MonthCalendarDate_otherMonth]: otherMonth
      })}
      onClick={onClick}>
      <div className={s.MonthCalendarDate__dayNum}>
        <span>{dayDate}</span>
        {isMobile && eventsAmount > 0 && <div className={s.MonthCalendarDate__eventsIndicator} />}
      </div>

      {!isMobile && (
        <div className={s.MonthCalendarDate__events}>
          {eventsToShow.map((item) => (
            <MonthCalendarEvent
              key={item.id}
              className={s.MonthCalendarDate__event}
              {...item}
              onClick={(e) => handleEventClick(item.id, e)}
            />
          ))}
          {eventsAmount > 5 && (
            <div className={s.MonthCalendarDate__showMore}>Еще {eventsAmount - eventsToShow.length}</div>
          )}
        </div>
      )}
    </div>
  );
};
