import { ReactFCC } from '../../../../../utils/ReactFCC';
import React from 'react';
import { useIsMobile } from '../../../../../hooks/useIsMobile';

export const QuizTableHeader: ReactFCC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  return (
    <thead>
      <tr>
        <th></th>
        <th>Элемент оценивания</th>
        <th>Попытки</th>
        <th>Дата</th>
        <th>Оценка</th>
        <th>Диапазон</th>
      </tr>
    </thead>
  );
};
