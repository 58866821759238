import React from 'react';

import clsx from 'clsx';

import { Tabs } from '@hse-design/react';

import s from './DisciplineTabs.module.scss';
import { ReactFCC } from 'utils/ReactFCC';
import { TTabItem } from './tabTypes';
import { find } from 'lodash-es';

type TDisciplineTabs = {
  activeTab?: TTabItem;
  className?: string;
  isMobile?: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<TTabItem>>;
  tabs: TTabItem[];
};

export const DisciplineTabs: ReactFCC<TDisciplineTabs> = (props) => {
  const { activeTab, className, isMobile, setActiveTab, tabs } = props;

  return (
    <div
      className={clsx(s.DisciplineTabs__container, isMobile && s.mobile, className)}
      // style={{ padding: isMobile ? `${contentPaddingMobileHorizontal}px` : 0 }}
    >
      <Tabs
        activeItem={activeTab}
        classes={{
          item: s.DisciplineTabs__tabsItem
          // itemActive: s.DisciplineTabs__tabs_item_active
        }}
        items={tabs || []}
        onItemClick={(item) => setActiveTab(find(tabs, { name: item.name }) as TTabItem)}
      />
    </div>
  );
};
