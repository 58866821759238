import clsx from 'clsx';
import s from './QuizTableRow.module.scss';
import { ReactFCC } from '../../../../../../utils/ReactFCC';
import { formatQuizAttemptGrade, formatQuizDate, formatQuizMinMax } from '../../utils/quizTableFormat';
import { QuizTableAttemptBaseProps } from '../types';

export interface QuizTableRowAttemptProps extends QuizTableAttemptBaseProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const QuizTableRowAttempt: ReactFCC<QuizTableRowAttemptProps> = (props) => {
  const { className, attempt, index, gradeAttemptMin, gradeAttemptMax } = props;

  return (
    <tr className={clsx(s.QuizTableRow_attemptRow, className)}>
      <td></td>
      <td>Тест</td>
      <td>{index}</td>
      <td>{formatQuizDate(attempt.date)}</td>
      <td>{formatQuizAttemptGrade(attempt.grade)}</td>
      <td>{formatQuizMinMax(gradeAttemptMin, gradeAttemptMax)}</td>
    </tr>
  );
};
