import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import isEqual from 'lodash-es/isEqual';
import { usePrevious } from 'hooks/usePrevious';
import { useAxiosClient } from '../axiosClient';

export interface IMoodleQueryResult<D> {
  isLoading: boolean;
  error: string | null;
  data: D | null;
}

const initialQueryResult = {
  isLoading: false,
  error: null,
  data: null
};

// запрос не выполняется, пока не передан аргумент query,
// это позволяет устраивать цепь из запросов:
// успешное выполнение (data) предыдущих запросов формирует query для последующих
export function useMoodleAPI<Q, D>(query?: Q | null | false) {
  const axiosClient = useAxiosClient();
  const prevQuery = usePrevious(query);

  const [queryResult, setQueryResult] = useState<IMoodleQueryResult<D>>(initialQueryResult);

  const abortController = useRef<AbortController>();

  useLayoutEffect(() => {
    if (!query && (queryResult.isLoading || queryResult.data || queryResult.error)) {
      // останавливает текущий запрос
      if (queryResult.isLoading && abortController.current) {
        abortController.current.abort();
      }
      setQueryResult((queryResult) => ({ ...queryResult, isLoading: false, error: null, data: null }));
    }
  }, [query, queryResult]);

  useLayoutEffect(() => {
    const newQuery = query && prevQuery && !isEqual(query, prevQuery);

    if ((query && !queryResult.isLoading && !queryResult.data && !queryResult.error) || newQuery) {
      // останавливает текущий запрос
      if (queryResult.isLoading && abortController.current) {
        abortController.current.abort();
      }

      setQueryResult((queryResult) =>
        newQuery ? { ...queryResult, isLoading: true, error: null, data: null } : { ...queryResult, isLoading: true }
      );

      abortController.current = new AbortController();

      axiosClient
        .request<D>({ data: query, signal: abortController.current.signal })
        .then(({ data }) => {
          setQueryResult((queryResult) => ({ ...queryResult, isLoading: false, data }));
        })
        .catch((error) => {
          if (error.name !== 'CanceledError') {
            setQueryResult((queryResult) => ({ ...queryResult, isLoading: false, error }));
            console.error(error);
          }
        });
    }
  }, [query, prevQuery, queryResult, axiosClient]);

  const clearResult = useCallback(() => {
    setQueryResult(initialQueryResult);
  }, []);

  return {
    ...queryResult,
    clearResult
  };
}
